import React, { Fragment, useState, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col } from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import axios from "axios";
import { Filter, DefaultColumnFilter } from "Components/Common/filters";
import { Link } from "react-router-dom";

interface TableContainerProps {
  columns?: any;
  data: any;
  isGlobalFilter?: any;
  isAddOptions?: any;
  isAddUserList?: any;
  isAddInvoiceList?: any;
  handleOrderClicks?: any;
  handleUserClick?: any;
  handleCustomerClick?: any;
  isAddCustList?: any;
  customPageSize: any;
  className?: any;
  handleInvoiceClick?: any;
  customPageSizeOptions?: any;
  isJobListGlobalFilter?: any;
  isGlobalSearch?: any;
  isProductsFilter?: any;
  isCustomerFilter?: any;
  isOrderFilter?: any;
  isContactsFilter?: any;
  isCompaniesFilter?: any;
  isLeadsFilter?: any;
  isCryptoOrdersFilter?: any;
  isInvoiceListFilter?: any;
  isTicketsListFilter?: any;
  isNFTRankingFilter?: any;
  isTaskListFilter?: any;
  tableClass?: any;
  theadClass?: any;
  trClass?: any;
  thClass?: any;
  divClass?: any;
  SearchPlaceholder?: any;
  isPagination?: any;
  filterOptionData?: any;
  filterOption?: any;
  isShowingPageLength?: boolean;
  paginationDiv?: string;
  pagination?: string;
  setCurrentStatus?: any;
  currentStatus?: string;
  tableFor?: string;
  setWebsiteFeedback?: any;
}
require("flatpickr/dist/flatpickr.css");

const WebsiteFeedbackTable: any = ({
  columns,
  data,
  isAddOptions,
  handleOrderClicks,
  customPageSize,
  tableClass,
  setCurrentStatus,
  currentStatus,
  theadClass,
  filterOption,
  isPagination,
  isShowingPageLength,
  paginationDiv,
  pagination,
  filterOptionData,
  tableFor,
  setWebsiteFeedback,
}: TableContainerProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    state: { pageIndex, pageSize },
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageOptions,
    gotoPage,
  }: any = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [offset, setOffset] = useState<number>(0);

  const getWebsiteFeedbackHistory = async (newOffset) => {
    let filters = {};
    if (dateRange.from != "" && dateRange.to != "") {
      Object.assign(filters, {
        date: { from: dateRange.from, to: dateRange.to },
      });
    }
    const res = await axios.get(
      `${
        process.env.REACT_APP_APIKEY
      }/api/get/all/feedback/website/${newOffset}/${JSON.stringify(filters)}`
    );

    if (res.success) {
      setLoading(false);
      if (res.data.length) {
        console.log("condition 1");

        if (newOffset === 0) {
          setWebsiteFeedback(res.data[0]?._id);
        }

        setWebsiteFeedback((prev: any) => {
          if (newOffset === 0) {
            return res.data;
          } else {
            return [...prev, ...res.data];
          }
        });
      } else if (res.data.length && offset === 0) {
        console.log("condition 2");
        setWebsiteFeedback([]);
      } else if (!res.data.length && offset !== 0) {
        console.log("condition 3");
      } else if (!res.data.length) {
        console.log("condition 4");
        setWebsiteFeedback([]);
      }

      console.log(res.data);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  useEffect(() => {
    setOffset(0);
    getWebsiteFeedbackHistory(0);
  }, [dateRange]);

  useEffect(() => {
    setLoading(true);
    console.log(offset, "offset");

    getWebsiteFeedbackHistory(offset);
  }, [offset]);

  const pageNumbers: any = [];
  for (let i = 1; i <= Math.ceil(data?.length / customPageSize); i++) {
    pageNumbers.push(i);
  }

  const onChangeInSelect = (event: any) => {
    setCurrentStatus(event.target.value);
  };

  return (
    <Fragment>
      <Row className="mb-3">
        <Col lg="5">
          <div className="align-self-center">
            <label> Search By Date Range</label>
            <Flatpickr
              className="form-control"
              value={dateRange.fullDate || ""}
              placeholder="Please select a range to fetch feedbacks"
              options={{
                mode: "range",
                dateFormat: "d-m-Y",
                defaultDate: "today",
              }}
              onClose={(date, dateStr) => {
                setDateRange({
                  from: date[0] ? moment(date[0]).format("YYYY-MM-DD") : "",
                  to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : "",
                  fullDate: dateStr,
                });
              }}
              id="default-picker"
            />
          </div>
        </Col>
        <Col lg="3"></Col>
        {filterOption && (
          <Col lg="4">
            <div className="align-self-center">
              <label> Search By Feedback Experience</label>
              <select
                className="form-select"
                value={currentStatus}
                onChange={onChangeInSelect}
              >
                <option value="All">All</option>
                {filterOptionData?.length &&
                  filterOptionData?.map((status: string, i: number) => (
                    <option
                      value={status}
                      key={i}
                      style={{ cursor: "pointer" }}
                    >
                      {status}
                    </option>
                  ))}
              </select>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive">
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup: any) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    key={column.id}
                    className={`${column.isSortable ? "sorting_asc" : ""}`}
                  >
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {/* {generateSortingIndicator(column)} */}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell: any) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      {isPagination && (
        <div className="d-flex justify-content-center align-items-center">
          {isShowingPageLength && (
            <div className="col-sm">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page.length}</span> of{" "}
                <span className="fw-semibold">{data.length}</span> entries
              </div>
            </div>
          )}
          <div className={paginationDiv}>
            <ul className={pagination}>
              <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={previousPage}>
                  <i className="mdi mdi-chevron-left"></i>
                </Link>
              </li>
              {pageOptions.map((item: any, key: number) => (
                <React.Fragment key={key}>
                  <li
                    className={
                      pageIndex === item ? "page-item active" : "page-item"
                    }
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => gotoPage(item)}
                    >
                      {item + 1}
                    </Link>
                  </li>
                </React.Fragment>
              ))}
              <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                <Link to="#" className="page-link" onClick={nextPage}>
                  <i className="mdi mdi-chevron-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default WebsiteFeedbackTable;
