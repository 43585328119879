import React from "react";
import { Container, Row } from "reactstrap";
import ChartSection from "./ChartSection";
import StatisticsApplications from "./StatisticsApplications";

const DashboardJob = () => {
  document.title = "Support Dashboard | PrimeXBroker";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ChartSection />
          <Row>
            <StatisticsApplications />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardJob;
