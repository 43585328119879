import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../Components/Common/TableContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Editor as MyEditor } from "draft-js";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Editor } from "react-draft-wysiwyg";
//import components
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import DeleteModal from "../../Components/Common/DeleteModal";

import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "slices/thunk";

// import {
//   OrderId,
//   BillingName,
//   Date,
//   Total,
//   PaymentStatus,
//   PaymentMethod
// }
//   from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
// import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Popover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
} from "reactstrap";
import moment from "moment";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";

function Article() {
  //meta title
  document.title = "Education | PrimeX";

  const dispatch = useDispatch<any>();
  // const selectProperties = createSelector(
  //   (state: any) => state.ecommerce,
  //   (ecommerce) => ({
  //     orders: ecommerce.orders,
  //     loading: ecommerce.loading
  //   })
  // );
  const [current, setCurrent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [titleِAr, setTitleAr] = useState<string>("");
  const [videoId, setVideoId] = useState<string>("");

  const [popovertop, setpopovertop] = useState<boolean>(false);
  const [catId, setCatId] = useState<string>("");
  const [createCat, setCreateCat] = useState<string>("");
  const [createCatAr, setCreateCatAr] = useState<string>("");
  const [articles, setArticles] = useState<any[]>([]);
  const [categoriesData, setCategoriesData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [editorState2, setEditorState2] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [modal, setModal] = useState<boolean>(false);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [isEditCat, setIsEditCat] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);
  const [itemToDelete, setItemToDelete] = useState<any>(null);
  const [deleteArt, setDeleteArt] = useState(null) as any;
  const [artId, setArtId] = useState(null) as any;
  const user = JSON.parse(localStorage.getItem("authUser"));

  const fetchAllArticles = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/lesson/1/20/All Lessons`,
      { headers }
    );
    if (res.success) {
      setArticles(res.data);
    }
  };

  const fetchAllArticlesBycat = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    console.log(current, "search by category called");
    if (current == "") {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/fetch/all/lesson`,
        { headers }
      );
      if (res.success) {
        console.log("res.success", res.data);
        setArticles(res.data);
      }
      return;
    }
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/lesson/bycategory/${current}`,
      { headers }
    );
    if (res.success) {
      console.log("res.success", res.data);
      setArticles(res.data);
    }
  };

  const onEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
  };

  const onEditorStateChange2 = (newEditorState: EditorState) => {
    setEditorState2(newEditorState);
  };

  const setTheformCat = (val) => {
    setCreateCat(val);
  };

  // validation
  const add = async () => {
    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const contentState2 = editorState2.getCurrentContent();
    const contentRawAr = convertToRaw(contentState2);
    const data = {
      titleEn: title,
      titleAr: titleِAr,
      videoId: videoId,
      category: createCat,
    };
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };

    if (isEdit && artId) {
      const res = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/edit/lesson/${artId}`,
        data,
        { headers }
      );
      if (res.success) {
        // setArticles(art => [...art, res.data])

        const index = articles.findIndex((art) => art._id === artId);
        console.log(index, "index");

        if (index !== -1) {
          const updatedArticlesData = [...articles];
          updatedArticlesData[index] = res.data;
          setArticles(updatedArticlesData);
          setModal(false);
          setTitle("");
          setTitleAr("");
          setVideoId("");
          setEditorState(EditorState.createEmpty());
          setEditorState2(EditorState.createEmpty());
          setCreateCat("");
        }
      }
    } else {
      console.log(data, "going to add Lesson");
      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/create/lesson`,
        data,
        { headers }
      );
      if (res.success) {
        setArticles((art) => [...art, res.data]);
        setModal(false);
        setTitle("");
        setTitleAr("");
        setVideoId("");
        setEditorState(EditorState.createEmpty());
        setEditorState2(EditorState.createEmpty());
        setCreateCat("");
      }
    }
  };

  const addCategory = async () => {
    console.log("add cat");

    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    if (createCat != " ") {
      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/create/lesson/categories`,
        { titleEn: createCat, titleAr: createCatAr },
        { headers }
      );
      console.log("inside", res);

      if (res.success) {
        setCreateCatAr(" ");
        setCreateCat(" ");
        setCategoriesData((cat) => [...cat, res.data]);
        setModal2(!modal2);
      }
    }
  };

  console.log(modal, "modal");

  const [editDetails, setEditDetails] = useState<any>("");
  const toggleViewModal = useCallback(
    (data: any) => {
      setModal1(!modal1);
      setEditDetails(data);
    },
    [modal1]
  );

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleOrderClick = useCallback(
    (arg: any) => {
      const order = arg;
      setTitle(order.titleEn);
      setTitleAr(order.titleAr);
      setVideoId(order.videoId);
      setCreateCat(order.category);
      if (order.content) {
        const contentState = convertFromRaw(JSON.parse(order.content));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
      if (order.contentAr) {
        const contentState = convertFromRaw(JSON.parse(order.contentAr));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState2(editorState);
      }
      setArtId(order._id);
      // console.log(order, "order");

      // const headers = {
      //   Authentication: `Bearer ${user?.token}`,
      // };

      // const res = await axios.post(`${process.env.REACT_APP_APIKEY}/api/create/article/category`, { title: createCat, titleAr: createCatAr }, { headers })

      // setEditorState2(order.contentAr)

      setIsEdit(true);

      toggle();
    },
    [toggle]
  );

  console.log(createCat, "createCat");

  //delete order
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const onClickDelete = (order: any) => {
    setDeleteArt(order);
    setDeleteModal(true);
  };

  const handleDeleteOrder = async () => {
    if (deleteArt) {
      console.log(deleteArt, "del id");
      const headers = {
        Authentication: `Bearer ${user?.token}`,
      };
      const res = await axios.delete(
        `${process.env.REACT_APP_APIKEY}/api/delete/lesson/${deleteArt}`,
        { headers }
      );
      console.log(res);
      if (res.success) {
        let data = articles.filter((cat) => cat._id !== deleteArt);
        setArticles(data);
        setDeleteModal(false);
      } else {
      }
    }
  };
  const handleOrderClicks = () => {
    setIsEdit(false);
    setOrder("");
    setTitle("");
    setTitleAr("");
    setCreateCat("");
    setEditorState(EditorState.createEmpty());
    setEditorState2(EditorState.createEmpty());
    toggle();
  };

  const getCategories = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/lesson/categories`,
      { headers }
    );
    console.log("display cat", res.data);
    if (res.success) {
      setCategoriesData(res.data);
    }
  };

  useEffect(() => {
    fetchAllArticles();
    getCategories();
  }, []);

  useEffect(() => {
    if (current !== " ") {
      fetchAllArticlesBycat();
    }
  }, [current]);

  const handleEdit = async (data: any) => {
    setIsEditCat(true);
    setCreateCat(data.titleEn);
    setCreateCatAr(data.titleAr);
    setCatId(data._id);
    setModal2(true);
  };

  const editCategory = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    if (createCat != " " && createCatAr != " " && catId != " ") {
      const res = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/edit/lesson/categories/${catId}`,
        { titleEn: createCat, titleAr: createCatAr },
        { headers }
      );
      if (res.success) {
        const index = categoriesData.findIndex(
          (category) => category._id === catId
        );
        console.log(index, "index");

        if (index !== -1) {
          // Create a new array with the updated category
          const updatedCategoriesData = [...categoriesData];
          updatedCategoriesData[index] = res.data;
          setCreateCatAr(" ");
          setCreateCat(" ");
          setCategoriesData(updatedCategoriesData);
          console.log(res.data, "After Update");
          setModal2(!modal2);
        }
      }
    }
  };

  const deleteCat = async (id: any) => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    if (id) {
      const res = await axios.delete(
        `${process.env.REACT_APP_APIKEY}/api/delete/lesson/categories/${itemToDelete._id}`,
        { headers }
      );
      if (res.success) {
        setModal2(false);
        let data = categoriesData.filter((cat) => cat._id !== itemToDelete._id);
        setCategoriesData(data);
        setpopovertop(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      // {
      //   Headelr: () => {
      //     return (
      //       <FormGroup check className="font-size-16">
      //         <Label check>
      //           <Input type="checkbox" id="checkAll" />
      //         </Label>
      //       </FormGroup>
      //     )
      //   },
      //   id: "#",
      //   accessor: () => (
      //     <FormGroup check className="font-size-16">
      //       <Label check>
      //         <Input type="checkbox" id="checkAll" />
      //       </Label>
      //     </FormGroup>
      //   ),
      //   width: '150px',
      //   style: {
      //     textAlign: "center",
      //     width: "10%",
      //     background: "#0000",
      //   },
      //   filterable: false,
      //   disableSortBy: true,
      //   Filter: false,
      // },
      {
        Header: "Title",
        accessor: "titleEn",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <Link to="#" className="text-body fw-bold">
              {cellProps.row.original.titleEn}
            </Link>
          );
        },
      },

      {
        Header: "Video Links",
        accessor: "view",
        Filter: false,
        Cell: (cellProps) => {
          return (
            <Link
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              target="_blank"
              rel="noopener noreferrer"
              to={`https://www.youtube.com/watch?v=${cellProps.row.original.videoId}`}
              // onClick={() => {
              //   const orderData = cellProps.row.original;
              //   toggleViewModal(orderData)
              // }}
            >
              View Video
            </Link>
          );
        },
      },
      {
        Header: "Video Id",
        accessor: "videoId",
        Filter: false,
        Cell: (cellProps) => {
          return (
            <Link
              to="#"
              className="text-body fw-bold"
            >{`${cellProps.row.original.videoId}`}</Link>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Filter: false,
        Cell: (cellProps: any) => {
          return (
            <div className="d-flex gap-3 justify-content-start align-items-center">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original._id;
                  onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              {/* {
                articles && <span>
                  {cellProps.row.original.isPublished ? <button className="btn btn-primary" onClick={() => publishArticle(cellProps.row.original)} > unpublish </button> : <button onClick={() => publishArticle(cellProps.row.original)} className="btn btn-success" > publish </button>}
                </span>

              } */}
            </div>
          );
        },
      },
    ],
    [handleOrderClick, toggleViewModal]
  );

  const setCurrentCategory = (val) => {
    setCurrent(val);
  };

  return (
    <React.Fragment>
      {/* <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} editDetails={editDetails} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Support" breadcrumbItem="Lessons List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody style={{ display: "flex" }}>
                    <div style={{ width: "70%" }}>
                      <TableContainer
                        columns={columns}
                        data={articles}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        setCurrentCat={setCurrentCategory}
                        currentCategory={current}
                        isPagination={true}
                        filterable={false}
                        filterOption={true}
                        filterOptionData={categoriesData}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                        tableFor="Add New Lesson"
                      />
                    </div>
                    <div
                      className="mt-5"
                      style={{ marginLeft: 10, width: "30%" }}
                    >
                      <Card>
                        <CardBody>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="text-muted">Categories</p>
                              <i
                                onClick={() => {
                                  setModal2(!modal2);
                                  setCreateCat("");
                                  setCreateCatAr("");
                                  setIsEditCat(false);
                                }}
                                className="fas fa-plus icon-hover"
                                style={{ fontSize: 18 }}
                              ></i>
                            </div>

                            <Modal
                              isOpen={modal2}
                              autoFocus={true}
                              centered={true}
                              toggle={() => {
                                setModal2(!modal2);
                              }}
                            >
                              <div className="modal-content">
                                <ModalHeader
                                  toggle={() => {
                                    setModal2(!modal2);
                                  }}
                                >
                                  {isEditCat ? "Edit Category" : "New Category"}{" "}
                                </ModalHeader>
                                <ModalBody>
                                  <form>
                                    <div className="mb-3">
                                      <Input
                                        type="email"
                                        placeholder="Category Name"
                                        onChange={(e) =>
                                          setCreateCat(e.target.value)
                                        }
                                        value={createCat}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Input
                                        type="email"
                                        dir="rtl"
                                        placeholder="اسم التصنيف"
                                        className="rtl"
                                        onChange={(e) =>
                                          setCreateCatAr(e.target.value)
                                        }
                                        value={createCatAr}
                                      />
                                    </div>
                                  </form>
                                </ModalBody>
                                <ModalFooter>
                                  <Button
                                    type="button"
                                    color="secondary"
                                    onClick={() => {
                                      setModal2(!modal2);
                                      setIsEditCat(false);
                                      setIsEditCat(false);
                                      setCreateCat("");
                                      setCreateCatAr("");
                                      setCatId("");
                                    }}
                                  >
                                    Close
                                  </Button>
                                  <Button
                                    type="button"
                                    color="primary"
                                    onClick={
                                      isEditCat ? editCategory : addCategory
                                    }
                                  >
                                    {" "}
                                    Save <i className="fas fa-save ms-1"></i>{" "}
                                  </Button>
                                </ModalFooter>
                              </div>
                            </Modal>

                            <ul className="list-unstyled fw-medium">
                              {/* <hr style={{color: "red"}} /> */}
                              {(categoriesData || []).map((item, index) => (
                                <li key={index}>
                                  <Link
                                    to="#"
                                    className="text-muted py-2 d-block "
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <i
                                          className={`mdi mdi-chevron-right me-1`}
                                        ></i>{" "}
                                        <span>{item.titleEn}</span>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "end",
                                        }}
                                      >
                                        <i
                                          className="fas fa-edit icon-hover-edit mx-3"
                                          onClick={() => handleEdit(item)}
                                        ></i>
                                        <i
                                          className="fas fa-trash-alt icon-hover-del"
                                          id="Popovertopdel"
                                          onClick={() => setItemToDelete(item)}
                                        ></i>
                                      </div>
                                      <Popover
                                        placement="bottom"
                                        isOpen={popovertop}
                                        target={
                                          categoriesData.length
                                            ? "Popovertopdel"
                                            : null
                                        }
                                        toggle={() => {
                                          setpopovertop(!popovertop);
                                        }}
                                      >
                                        <PopoverHeader>
                                          Are you sure?
                                        </PopoverHeader>
                                        <PopoverBody>
                                          <p>
                                            This will delete the category and
                                            all the Articles assciated to{" "}
                                            {itemToDelete?.titleEn} and It'll be
                                            permanent and connot be reverted.
                                          </p>

                                          <button
                                            onClick={() => {
                                              setpopovertop(!popovertop);
                                            }}
                                            className="btn btn-secondary"
                                          >
                                            cancel
                                          </button>
                                          <button
                                            onClick={deleteCat}
                                            className="btn btn-danger mx-2"
                                          >
                                            Delete
                                          </button>
                                        </PopoverBody>
                                      </Popover>
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </ul>

                            {/* <Modal isOpen={modal2} autoFocus={true} centered={true} toggle={() => { setmodal2(!modal2); }} >
                            <div className="modal-content">
                              <ModalHeader toggle={() => { setmodal2(!modal2); }}>Edit Category </ModalHeader>
                              <ModalBody>
                                <form>
                                  <div className="mb-3">
                                    <Input type="email"
                                      placeholder="Category Name"
                                      onChange={(e) => setCatNameEnEdit(e.target.value)}
                                      value={catNameEnEdit} />
                                  </div>
                                  <div className="mb-3"  >
                                    <Input type="email"
                                      dir="rtl"
                                      placeholder="اسم التصنيف"
                                      className="rtl"
                                      onChange={(e) => setCatNameArEdit(e.target.value)}
                                      value={catNameArEdit} />
                                  </div>
                                </form>
                              </ModalBody>
                              <ModalFooter>
                                <Button type="button" color="secondary" onClick={() => { setmodal(!modal); }}>Close</Button>
                                <Button type="button" color="primary" onClick={setEdit}> Save <i className="fas fa-save ms-1"></i> </Button>
                              </ModalFooter>
                            </div>
                          </Modal> */}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Order" : "Add Article"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  add();
                  return false;
                }}
              >
                <Row>
                  <Col xs={12}>
                    <div className="mb-3">
                      <Label>Title</Label>
                      <Input
                        name="orderId"
                        type="text"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                      {/* {validation.touched.orderId && validation.errors.orderId ? (
                        <FormFeedback type="invalid">{validation.errors.orderId}</FormFeedback>
                      ) : null} */}
                    </div>

                    <div
                      className="mb-3"
                      style={{ textAlign: "right", fontWeight: "bolder" }}
                    >
                      <Label>عنوان</Label>
                      <Input
                        dir="rtl"
                        name="orderId"
                        type="text"
                        onChange={(e) => setTitleAr(e.target.value)}
                        value={titleِAr}
                      />
                      {/* {validation.touched.orderId && validation.errors.orderId ? (
                        <FormFeedback type="invalid">{validation.errors.orderId}</FormFeedback>
                      ) : null} */}
                    </div>
                    <div className="mb-3">
                      <Label>Video ID</Label>
                      <Input
                        dir="rtl"
                        name="VideoId"
                        type="text"
                        onChange={(e) => setVideoId(e.target.value)}
                        value={videoId}
                      />
                      {/* {validation.touched.orderId && validation.errors.orderId ? (
                        <FormFeedback type="invalid">{validation.errors.orderId}</FormFeedback>
                      ) : null} */}
                    </div>

                    <div className="mb-3">
                      <Label>Category</Label>
                      <Input
                        name="paymentStatus"
                        type="select"
                        className="form-select"
                        onChange={(e) => setCreateCat(e.target.value)}
                        // onBlur={validation.handleBlur}
                        value={createCat}
                      >
                        <option value="">-- Select --</option>
                        {categoriesData?.length &&
                          categoriesData?.map((cat, i) => (
                            <option key={i} value={cat._id}>
                              {cat.titleEn}
                            </option>
                          ))}
                      </Input>
                      {/* {validation.touched.paymentStatus && validation.errors.paymentStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.paymentStatus}</FormFeedback>
                      ) : null} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button
                        type="submit"
                        color="success"
                        className="save-user"
                      >
                        {!!isEdit ? "Update" : "Add"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
export default Article;
