import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor as MyEditor } from "draft-js";

const DetailsSection = ({ jobDetail }) => {
  const getDescriptionEditorState = (rawDraftContentState) => {
    if (!rawDraftContentState) return EditorState.createEmpty();
    const contentState = convertFromRaw(JSON.parse(rawDraftContentState));
    return EditorState.createWithContent(contentState);
  };
  const editorState = getDescriptionEditorState(jobDetail.description);

  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <div className="d-flex">
              <div className="flex-grow-1 ms-3">
                <h5 className="fw-semibold">{jobDetail.title}</h5>
                <ul className="list-unstyled hstack gap-2 mb-0">
                  <li>
                    <i className="bx bx-map"></i>{" "}
                    <span className="text-muted">{jobDetail.location}</span>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
          <CardBody>
            <h5 className="fw-semibold mb-3">Description</h5>
            <MyEditor editorState={editorState} readOnly={true} />
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default DetailsSection;
