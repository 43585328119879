import React, { useState, useEffect, useMemo } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import Moment from "react-moment";
import axios from "axios";
import WebsiteFeedbackTable from "./WebsiteFeedbackTable";
import Spinners from "Components/Common/Spinner";

const WebsiteFeedback = () => {
  const [websiteFeedback, setWebsiteFeedback] = useState<any>([]);
  const [originalWebsiteFeedback, setOriginalWebsiteFeedback] = useState<any>(
    []
  );
  const [websiteFeedbackStatus, setWebsiteFeedbackStatus] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchAllWebsiteFeedbacks = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/feedback/website`
      );
      setWebsiteFeedback(res.data);
      setOriginalWebsiteFeedback(res.data);
      console.log("all website feedbacks", res);
    } catch (error) {
      console.log("Error Found", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllWebsiteFeedbacks();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Feedback",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <>
              <p className="text-muted mb-1 text-lowercase">{`${cellProps?.feedback}`}</p>
            </>
          );
        },
      },
      {
        Header: "Experience",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <>
              <p className="text-muted mb-1 text-lowercase">{`${cellProps?.experience}`}</p>
            </>
          );
        },
      },
      {
        Header: "CreatedDate",
        filterable: true,
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <>
              <Moment
                date={cellProps?.createdAt}
                format="Do MMMM YYYY"
                style={{
                  textAlign: "center",
                }}
                className="text-muted mb-1"
              />
            </>
          );
        },
      },
    ],
    []
  );

  const websiteFeedbackFilter = ["happy", "normal", "bad", "verybad"];

  useEffect(() => {
    if (websiteFeedbackStatus === "happy") {
      console.log("experience1");
      const filter = originalWebsiteFeedback.filter((wf) => {
        if (wf.feedback === "happy") {
          return wf;
        }
      });
      setWebsiteFeedback(filter);
    } else if (websiteFeedbackStatus === "normal") {
      console.log("experience2");
      const filter = originalWebsiteFeedback.filter((wf) => {
        if (wf.feedback === "normal") {
          return wf;
        }
      });
      setWebsiteFeedback(filter);
    } else if (websiteFeedbackStatus === "bad") {
      console.log("experience3");
      const filter = originalWebsiteFeedback.filter((wf) => {
        if (wf.feedback === "bad") {
          return wf;
        }
      });
      setWebsiteFeedback(filter);
    } else if (websiteFeedbackStatus === "verybad") {
      console.log("experience4");
      const filter = originalWebsiteFeedback.filter((wf) => {
        if (wf.feedback === "verybad") {
          return wf;
        }
      });
      setWebsiteFeedback(filter);
    } else if (websiteFeedbackStatus === "All") {
      setWebsiteFeedback(originalWebsiteFeedback);
    }
  }, [websiteFeedbackStatus]);

  const changeStatus = (data) => {
    setWebsiteFeedbackStatus(data);
  };

  return (
    <div className="page-content">
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs
          title="Website Feedbacks"
          breadcrumbItem="Feedbacks List"
        />
        <Row>
          <Col lg="12">
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50vh",
                }}
              >
                {" "}
                <Spinners />
              </div>
            ) : (
              <Card>
                <CardBody>
                  <WebsiteFeedbackTable
                    columns={columns}
                    data={websiteFeedback}
                    customPageSizeOption={true}
                    isGlobalFilter={true}
                    isPagination={true}
                    filterOption={true}
                    isShowingPageLength={true}
                    currentStatus={websiteFeedbackStatus}
                    setCurrentStatus={changeStatus}
                    customPageSize={30}
                    isAddOptions={true}
                    filterOptionData={websiteFeedbackFilter}
                    tableClass="table align-middle table-nowrap table-hover"
                    theadClass="table-light"
                    paginationDiv="col-sm-12 col-md-7"
                    tableFor={"Website Feedbacks"}
                    pagination="pagination pagination-rounded justify-content-end mt-4"
                    setWebsiteFeedback={setWebsiteFeedback}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WebsiteFeedback;
