import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { PhotoProvider, PhotoView } from "react-photo-view";
import moment from "moment";
import { debounce } from "lodash";
import StarRatings from "react-star-ratings";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import classnames from "classnames";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// emoji
import EmojiPicker from "emoji-picker-react";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import avatar1 from "../../assets/images/users/avatar-1.jpg";

import {
  getChats as onGetChats,
  getGroups as onGetGroups,
  getContacts as onGetContacts,
  getMessages as onGetMessages,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage,
} from "../../slices/chats/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import axios from "axios";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { setFlagsFromString } from "v8";
require("flatpickr/dist/flatpickr.css");

const Tickets = () => {
  const [search, setSearch] = useState<any>("");
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });

  const UserData = JSON.parse(localStorage.getItem("authUser"));

  console.log(UserData, "UserData");

  //meta title
  document.title = "Chat | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch<any>();

  const [isLoading, setLoading] = useState(false); //use loading instead of false

  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars

  const [chats, setChats] = useState<any>([]);
  const [messagesData, setMessagesData] = useState<any>(null);
  const [menu1, setMenu1] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string | number>("1");
  const [Chat_Box_Username, setChat_Box_Username] =
    useState<string>("Steven Franklin");
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] =
    useState<string>("online");
  const [curMessage, setcurMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isdisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const simpleBarRef = useRef();
  const [messageBoxText, setMessageBoxText] = useState<any>("");
  const [images, setImages] = useState<any>(null);

  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetGroups());
    dispatch(onGetContacts());
    dispatch(onGetMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  //Use For Chat Box
  const userChatOpen = (chats: any) => {
    setCurrentRoomId(chats._id);
    setMessagesData(chats);
  };

  // scroll simple bar
  const scroollRef = useRef<any>(null);
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight;
    }
  }, [messagesData]);

  // copy msg
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const copyMsg = (ele: any) => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML;
    navigator.clipboard.writeText(copyText);
    setCopyMsgAlert(true);
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false);
      }, 1000);
    }
  };

  const getChatHistory = async (newOffset) => {
    let filters = {};

    if (dateRange.from != "" && dateRange.to != "") {
      Object.assign(filters, {
        date: { from: dateRange.from, to: dateRange.to },
      });
    }
    if (search != "") {
      Object.assign(filters, { search: search });
    }

    const res = await axios.get(
      `${
        process.env.REACT_APP_APIKEY
      }/api/resolved/tickets/${offset}/${JSON.stringify(filters)}`
    );

    if (res.success) {
      setLoading(false);
      if (res.data.length) {
        if (newOffset === 0) {
          setCurrentRoomId(res.data[0]?._id);
          setMessagesData((prev) => res.data[0]);
        }

        setChats((prev: any) => {
          if (newOffset === 0) {
            return res.data;
          } else {
            return [...prev, ...res.data];
          }
        });
      } else if (res.data.length && offset === 0) {
        setChats([]);
        setMessagesData(null);
        setCurrentRoomId(1);
      } else if (!res.data.length && offset !== 0) {
      } else if (!res.data.length) {
        setChats([]);
        setMessagesData([]);
        setCurrentRoomId(1);
      }

      console.log(res.data);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  useEffect(() => {
    setOffset(0);
    getChatHistory(0);
  }, [dateRange, search]);

  useEffect(() => {
    setLoading(true);
    console.log(offset, "offset");

    getChatHistory(offset);
  }, [offset]);

  useEffect(() => {
    // Accessing the SimpleBar instance or its DOM node
    if (simpleBarRef.current) {
      const simpleBarInstance: any = simpleBarRef.current;
      const scrollElement = simpleBarInstance.getScrollElement(); // Get the scrollable element

      // Attach the scroll event listener
      scrollElement.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [simpleBarRef.current]);

  const debouncedHandleScroll = debounce(
    ({ scrollTop, clientHeight, scrollHeight }) => {
      console.log("handleScroll");

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        // Increased threshold
        setOffset((prevOffset) => prevOffset + 15);
      }
    },
    100
  );

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    debouncedHandleScroll({ scrollTop, clientHeight, scrollHeight });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat Support" breadcrumbItem="My Chats" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4 ">
                  <div>
                    <div className=" border-bottom mb-2">
                      <div className="align-self-center">
                        <label> Search By Date Range</label>
                        <Flatpickr
                          className="form-control"
                          value={dateRange.fullDate || ""}
                          placeholder="Please Select a Range To Fetch Chats"
                          options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            defaultDate: "today",
                          }}
                          onChange={(date, dateStr) => {
                            setDateRange({
                              from: date[0]
                                ? moment(date[0]).format("YYYY-MM-DD")
                                : "",
                              to: date[1]
                                ? moment(date[1]).format("YYYY-MM-DD")
                                : "",
                              fullDate: dateStr,
                            });
                          }}
                          onClose={(date, dateStr) => {
                            setDateRange({
                              from: date[0]
                                ? moment(date[0]).format("YYYY-MM-DD")
                                : "",
                              to: date[1]
                                ? moment(date[1]).format("YYYY-MM-DD")
                                : "",
                              fullDate: dateStr,
                            });
                          }}
                          id="default-picker"
                        />
                      </div>
                      <div>
                        <label htmlFor="example-search-input" className="mt-2">
                          Search
                        </label>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            className="form-control"
                            type="search"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search By Name and Email"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified className="mt-3">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">My Chats</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <ul
                              className="list-unstyled chat-list position-relative"
                              id="recent-list"
                            >
                              {isLoading && offset === 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "50vh",
                                  }}
                                >
                                  {" "}
                                  <Spinners />
                                </div>
                              ) : (
                                <>
                                  <SimpleBar
                                    ref={simpleBarRef}
                                    style={{ height: "410px" }}
                                  >
                                    <div style={{ height: "410px" }}>
                                      {chats &&
                                        chats.map((chat: any, i) => (
                                          <li
                                            key={i}
                                            className={
                                              currentRoomId === chat._id
                                                ? "active"
                                                : ""
                                            }
                                          >
                                            <Link
                                              to="#"
                                              onClick={() => userChatOpen(chat)}
                                            >
                                              <div className="d-flex">
                                                <div className="align-self-center me-3">
                                                  <i
                                                    className={
                                                      chat.status === "online"
                                                        ? "mdi mdi-circle text-success font-size-10"
                                                        : chat.status ===
                                                          "intermediate"
                                                        ? "mdi mdi-circle text-warning font-size-10"
                                                        : "mdi mdi-circle font-size-10"
                                                    }
                                                  />
                                                </div>

                                                <div
                                                  className="flex-grow-1 overflow-hidden"
                                                  style={{ width: "75%" }}
                                                >
                                                  <h5 className="text-truncate font-size-14 mb-1">
                                                    {chat?.name}
                                                  </h5>
                                                  <p className="text-truncate mb-0">
                                                    {chat?.messages[
                                                      chat?.messages?.length - 1
                                                    ]?.file
                                                      ? "file"
                                                      : chat.messages[
                                                          chat?.messages
                                                            ?.length - 1
                                                        ]?.body}
                                                  </p>
                                                </div>
                                                <div
                                                  className="font-size-10"
                                                  style={{
                                                    textAlign: "end",
                                                    width: "25%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                      "space-around",
                                                    alignItems: "end",
                                                  }}
                                                >
                                                  <span>
                                                    <i className="bx bx-calendar align-middle me-1"></i>
                                                    {moment(
                                                      chat.createdOn
                                                    ).format("DD-MM-YY")}
                                                  </span>
                                                  <span>
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    {moment(
                                                      chat.createdOn
                                                    ).format("hh:mmA")}
                                                  </span>
                                                </div>
                                              </div>
                                            </Link>
                                          </li>
                                        ))}
                                    </div>
                                  </SimpleBar>
                                </>
                              )}
                            </ul>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    {messagesData && (
                      <div className="p-4 border-bottom ">
                        <Row>
                          <Col md={4} xs={9}>
                            <h5 className="font-size-15 mb-1">
                              {messagesData.name}
                            </h5>
                            <h5 className="font-size-15 mb-1">
                              {messagesData.email}
                            </h5>
                          </Col>
                          <Col md={8} xs={3}>
                            <ul className="list-inline user-chat-nav text-end mb-0">
                              {/* <li className="list-inline-item">
                                  <Dropdown
                                    isOpen={other_Menu}
                                    toggle={toggleOther}
                                  >
                                    <DropdownToggle className="btn nav-btn" tag="a">
                                      <i className="bx bx-dots-horizontal-rounded" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem onClick={()=>console.log()}>End Chat</DropdownItem>
                                      
                                    </DropdownMenu>
                                  </Dropdown>
                                </li> */}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    )}

                    <div>
                      <div className="chat-conversation p-3">
                        {isLoading ? (
                          offset === 0 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "50vh",
                              }}
                            >
                              {" "}
                              <Spinners />
                            </div>
                          ) : (
                            <div>Loading...</div>
                          )
                        ) : (
                          <SimpleBar
                            ref={scroollRef}
                            style={{ height: "470px" }}
                          >
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "50vh",
                                }}
                              >
                                {" "}
                                <Spinners />
                              </div>
                            ) : (
                              <ul
                                className="list-unstyled"
                                id="users-conversation"
                              >
                                <React.Fragment>
                                  {messagesData?.messages?.map(
                                    (userMsg, index: number) =>
                                      userMsg.file === true ? (
                                        <div key={index}>
                                          <PhotoProvider>
                                            <PhotoView src={userMsg.file_path}>
                                              <img
                                                className={
                                                  userMsg.support
                                                    ? "conversation-list"
                                                    : "conversation-list right"
                                                }
                                                style={
                                                  index == 0
                                                    ? {
                                                        width: "300px",
                                                        float: "right",
                                                      }
                                                    : { width: "300px" }
                                                }
                                                src={userMsg.file_path}
                                                alt=""
                                              />
                                            </PhotoView>
                                          </PhotoProvider>
                                        </div>
                                      ) : (
                                        <li
                                          key={index}
                                          className={
                                            index == 0 ? "right" : "left"
                                          }
                                        >
                                          <div className="conversation-list">
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                href="#!"
                                                tag="a"
                                                className="dropdown-toggle"
                                              >
                                                <i className="bx bx-dots-vertical-rounded" />
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={(e) =>
                                                    copyMsg(e.target)
                                                  }
                                                  href="#"
                                                >
                                                  Copy
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <div className="ctext-wrap">
                                              <div className="conversation-name">
                                                {userMsg.support
                                                  ? "You"
                                                  : messagesData.customer_name}
                                              </div>
                                              <p>{userMsg.body}</p>
                                              {
                                                <p className="chat-time mb-0">
                                                  <i className="bx bx-time-five align-middle me-1"></i>
                                                  {moment(
                                                    userMsg.createdOn
                                                  ).format("hh:mmA")}
                                                </p>
                                              }
                                            </div>
                                          </div>
                                        </li>
                                      )
                                  )}

                                  {!messagesData && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <p> No Chats Available To Display </p>
                                    </div>
                                  )}

                                  {messagesData?.feedback && (
                                    <form
                                      className="start-chat-form position-relative container-fluid d-flex flex-column  align-items-center  my-5  rounded p-3"
                                      style={{ maxWidth: "85%" }}
                                    >
                                      {/* <div
                                        className=" rounded-circle d-flex align-items-center justify-content-center  "
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                          backgroundColor: "#FCCD0A",
                                        }}
                                      >
                                        <FcAssistant style={{ fontSize: "25px" }} />
                                      </div> */}

                                      <p
                                        className="p-0 my-1 text-center"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        You Got Rating
                                      </p>

                                      <StarRatings
                                        rating={messagesData?.stars}
                                        starRatedColor="#f1b44c"
                                        starEmptyColor="#74788d"
                                        numberOfStars={5}
                                        name="rating"
                                        starDimension="20px"
                                        starSpacing="2px"
                                      />
                                      <p
                                        className="text-left mt-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Was the case resolved during the chat?
                                      </p>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            marginTop: -15,
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="resolved"
                                            id="resolved"
                                            disabled
                                            checked={
                                              messagesData?.resolved
                                                ? true
                                                : false
                                            }
                                            style={{ width: "30px" }}
                                          />

                                          <label
                                            htmlFor="resolved"
                                            className="mt-2"
                                            style={{ color: "rgb(63,63,63)" }}
                                          >
                                            {" "}
                                            Yes{" "}
                                          </label>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            marginTop: -15,
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="resolved"
                                            id="resolved"
                                            disabled
                                            checked={
                                              !messagesData?.resolved
                                                ? true
                                                : false
                                            }
                                            style={{ width: "30px" }}
                                          />
                                          <label
                                            htmlFor="resolved"
                                            className="mt-2"
                                            style={{ color: "rgb(63,63,63)" }}
                                          >
                                            {" "}
                                            No{" "}
                                          </label>
                                        </div>

                                        <div
                                          style={{
                                            width: "100%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <label
                                            className="p-0 my-1 text-center"
                                            style={{
                                              fontWeight: "bold",
                                              display: "block",
                                            }}
                                          >
                                            Comment
                                          </label>
                                          <p>{messagesData.comment}</p>
                                        </div>
                                      </div>
                                    </form>
                                  )}

                                </React.Fragment>
                              </ul>
                              
                            )}
                            
                            {
                               messagesData && 
                                      <div className="chat-day-title">
                                        <span
                                          className="title"
                                          // style={{ color: "#232323" }}
                                        >
                                          {messagesData?.resolvedBy} resolved the ticket
                                        </span>
                                      </div>
                                    
                              }
                          </SimpleBar>
                        )}
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{ width: "150px", height: "auto" }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}

                      {copyMsgAlert && (
                        <UncontrolledAlert color="warning" role="alert">
                          Message copied
                        </UncontrolledAlert>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tickets;
