import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import MyContext from "MyContext/MyContext";
import Breadcrumbs from "../../Components/Common/Breadcrumb";

//import components
import { JobWidgetCharts } from "./JobCharts";
import axios from "axios";

const ChartSection = () => {
  const { queue, socket, activeCustomers } = useContext(MyContext);
  const [stats, setStats] = useState<any>({});

  useEffect(() => {
    if (socket) {
      socket.emit("total_queue");
      socket.emit("active_customers");
    }
  }, [socket]);

  const getStats = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/dashboard/stats`
    );
    if (res.success) {
      setStats(res.data);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  const chartsData = [
    {
      title: "Completed Chats today",
      count: stats.totalChats ? stats.totalChats : 0,
    },
    {
      title: "Missed Chats of today ",
      count: stats.missedChats ? stats.missedChats : 0,
    },
    {
      title: "Active Chats Now",
      count: stats.activeChats ? stats.activeChats : 0,
    },
    {
      title: "Customers in Queue",
      count: queue,
    },
    {
      title: "Online Agents",
      count: stats.onlineAgents ? stats.onlineAgents : 0,
    },
    {
      title: "Total Agents",
      count: stats.allAgents ? stats.allAgents : 0,
    },
    {
      title: "Active Customers now",
      count: activeCustomers - stats.onlineAgents ? stats.onlineAgents : 0,
    },
    {
      title: "Pending Tickets",
      count: stats?.unresolvedTickets
    },
    {
      title: "Resolved Chats",
      count: stats?.resolvedChats ? stats?.resolvedChats : 0,
    },
  ];

  return (
    <React.Fragment>
      <Breadcrumbs title="Reports" breadcrumbItem="Dashboard" />
      <Row>
        {(chartsData || []).map((item: any, key: number) => (
          <Col lg={3} key={key}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">{item.title}</p>
                    <h4 className="mb-0">{item.count}</h4>
                  </div>

                  {/* <div className="flex-shrink-0 align-self-center">
                                        <JobWidgetCharts dataColors={item.color} series={item.seriesData} dir="ltr" />
                                    </div> */}
                </div>
              </CardBody>
              {/* {item.istrendingArrow ? <CardBody className="border-top py-3">
                                <p className="mb-0"> <span className={"badge badge-soft-" + item.bagdeColor + " me-2"}>
                                    <i className="bx bx-trending-down align-bottom me-1"></i> {item.perstangeValue}%</span>
                                    Decrease last month</p>
                            </CardBody>
                                :
                                <CardBody className="border-top py-3">
                                    <p className="mb-0"> <span className={"badge badge-soft-" + item.bagdeColor + " me-2"}>
                                        <i className="bx bx-trending-up align-bottom me-1"></i> {item.perstangeValue}%</span>
                                        Increase last month</p>
                                </CardBody>
                            } */}
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default ChartSection;
