import React, { useEffect } from "react"
import { Spinner } from "reactstrap";

const Spinners = ({ setLoading }: any) => {

    // useEffect(() => {
    //     setTimeout(() => {
    //         setLoading(false)
    //     }, 1000)
    // }, [setLoading]);
    
    return (
        <React.Fragment>
            <Spinner color="primary"  />
        </React.Fragment>
    )
}

export default Spinners;