import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, CardTitle } from "reactstrap";
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import MyContext from "MyContext/MyContext";
import moment from "moment";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { getChartData as onGetChartData } from "../../slices/dashboards/thunk";
import Flatpickr from "react-flatpickr";
import { Space, Table, Tag } from 'antd';
import Spinners from "Components/Common/Spinner";

require("flatpickr/dist/flatpickr.css");

const TeamReports = () => {
  const { socket } = useContext(MyContext);
  const [stats, setStats] = useState<any>();
  const [selectedTags, setSelectedTags] = useState([]);
  const [support, setSupport] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [subscribemodal, setSubscribemodal] = useState<boolean>(false);
  const [periodData, setPeriodData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function formatTime(seconds) {
    if (seconds < 60) {
      return `${seconds.toFixed(1)} second${seconds > 1 ? 's' : ''}`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes.toFixed(1)} minute${minutes > 1 ? 's' : ''}`;
    } else {
      const hours = Math.floor(seconds / 3600);
      return `${hours.toFixed(1)} hour${hours > 1 ? 's' : ''}`;
    }
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Total Chats',
      dataIndex: 'totalChats',
      key: 'totalChats',
      render: (text) => <a>{text}</a>,

    },
    {
      title: 'Likes',
      dataIndex: 'likes',
      key: 'likes',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Dislikes',
      key: 'dislikes',
      dataIndex: 'dislikes',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Rated',
      dataIndex: 'totalReviews',
      key: 'totalReviews',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Average Response Time',
      dataIndex: 'avgResponseTime',
      key: 'avgResponseTime',
      render: (text) => <a>{formatTime(text)}</a>,
    },
    {
      title: 'Average Chat Time',
      dataIndex: 'avgChatTime',
      key: 'avgChatTime',
      render: (text) => <a>{formatTime(text)}</a>,
    },


  ];
  var data = [

  ];


  function convertToCSV(arr) {
    // Create CSV headers
    const csvRows = [
      'Name,Total Chats,Likes,Dislikes,Rated,Average Response Time,Average Chat Time',
    ];

    // Map over the array of objects and create a row for each
    arr.forEach(item => {
      csvRows.push(`${item.name},${item.totalChats},${item.likes},${item.dislikes},${item.totalReviews},${item.avgResponseTime.toFixed(1)},${item.avgChatTime.toFixed(1)}`);
    });

    // Join each row with a newline character to create the CSV string
    return csvRows.join('\n');
  }

  useEffect(() => {
    setTimeout(() => {
      setSubscribemodal(true);
    }, 2000);
  }, [])

  const selectProperties = createSelector(
    (state: any) => state.dashboard,
    (dashboard) => ({
      chartsData: dashboard.dashboardChartData,
    })
  );

  const { chartsData } = useSelector(selectProperties);

  useEffect(() => {
    setPeriodData(chartsData);
  }, [chartsData]);

  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(onGetChartData("yearly"));
  }, [dispatch]);

  console.log(search, "search hello");
  // First two row
  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });

  useEffect(() => {
    if (selectedTags.length) {
    }
  }, [selectedTags]);


  useEffect(() => {
    if (socket) {
      socket.emit("total_queue");
      socket.emit("active_customers");
    }
  }, [socket]);

  const getStats = async () => {
    console.log("hitting")
    setLoading(true)

    let filter = null
    if (dateRange.from && dateRange.to != "" && !filter) {
      filter = {
        date: { from: dateRange.from, to: dateRange.to }
      }
    }
    if (selectedTags.length) {
      let ids = []
      selectedTags.map((data) => {
        ids.push(data.value)
      })
      if (!filter) {
        filter = {
          agents: ids
        }
      }
      else {
        filter.agents = ids
      }

    }


    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/team/report/${JSON.stringify(filter)}`
    );
    if (res.success) {
      data = res.data
      const updated = res.data.map( ( report )=> {report.totalReviews = report.likes + report.dislikes
      return report} )
      console.log(updated,"updated" );
      
      setStats(updated);
      setLoading(false)
      console.log(res.data, "res.data");
    }
    else {
      setLoading(false)
    }
  };
  console.log(data, "data");


  useEffect(() => {
    getStats();
  }, [selectedTags, dateRange]);



  const fetchAllSupport = async () => {
    console.log("support data ", "fetchAllSupport");
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/all/support`
    );
    if (res.success) {
      const data = [];
      res.data.map((sup) => {
        data.push({
          value: `${sup?._id}`,
          label: `${sup?.name?.firstName} ${sup?.name?.lastName}`,
        });
      });
      console.log("support data ", data);

      setSupport(data);
    }
  };

  function downloadCSV(e: any) {
    e.preventDefault()
    const csvString = convertToCSV(stats);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Kpi.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    fetchAllSupport()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Reports" breadcrumbItem="Team Reports" />
          <div className=" border-bottom mb-3 d-flex justify-content-between">
            <div className="align-self-center me-3" style={{ width: "40%" }}>
              <label> Search By Date Range</label>
              <Flatpickr
                className="form-control"
                value={dateRange.fullDate || ""}
                placeholder="Please Select a Range To Fetch Chats"
                options={{
                  mode: "range",
                  dateFormat: "d-m-Y",
                  defaultDate: "today",
                }}
                onChange={(date, dateStr) => {
                  setDateRange({
                    from: date[0] ? moment(date[0]).format("YYYY-MM-DD") : "",
                    to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : "",
                    fullDate: dateStr,
                  });
                }}
                onClose={(date, dateStr) => {
                  setDateRange({
                    from: date[0] ? moment(date[0]).format("YYYY-MM-DD") : "",
                    to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : "",
                    fullDate: dateStr,
                  });
                }}
                id="default-picker"
              />
            </div>
          </div>
          {
            !loading && <div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <input type="button" onClick={(e)=>downloadCSV(e)} value={"Export Data"} className="btn btn-success" />
              </div>

              <Table columns={columns} dataSource={stats} pagination={false} className="mb-5" />
            </div>
          }
          {
            loading &&  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }} ><Spinners /></div> 
          }

          {/* <Row>
            {(chartData || []).map((item: any, key: number) => (
              <Col lg={3} key={key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">{item.title}</p>
                        <h4 className="mb-0">{item.count}</h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TeamReports;
