import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import axios from "axios";

import classnames from "classnames";
import img1 from "../../../assets/images/small/img-2.jpg";
import img2 from "../../../assets/images/small/img-6.jpg";
import img3 from "../../../assets/images/small/img-1.jpg";
import { Link } from "react-router-dom";
import Spinners from "Components/Common/Spinner";
import { Editor, EditorState, convertFromRaw, ContentState } from "draft-js";
import Moment from "react-moment";
import withRouter from "Components/Common/withRouter";

const BlogGrid = (props: any) => {
  interface Blog {
    // Define the properties of a blog object here
    _id: string;
    title: string;
    image: string;
    publishDate: Date;
    content: string;
    isPublished: boolean;
    category: any;
    language: string;
    createdOn: Date;
    // ... other properties ...
  }

  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const [pagination, setPagination] = useState() as any[];
  const [pages, setPages] = useState([]) as any[];
  const [current, setCurrent] = useState<number>(1);
  const [requestPage, setrequestPage] = useState<number>(1);
  const user = JSON.parse(localStorage.getItem("authUser"));

  const fetchPublishBlogs = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/publish/blogs/${requestPage}/10`,
      { headers }
    );
    console.log(res.data);

    if (res.success) {
      // const newData = res.data.map(b => JSON.parse(b.content)
      // )
      // console.log(newData);
      let arr = [];
      for (let i = 1; i <= res.pagination?.totalPages; i++) {
        arr.push(i);
      }
      setPages(arr);
      setCurrent(res.pagination?.currentPage);
      setPagination(res.pagination);
      setBlogs(res.data);
      setLoading(false);
    }
  };

  const fetchDraftBlogs = async () => {
    console.log(user?.token, "token");

    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/draft/blogs/${requestPage}/10`,
      { headers }
    );
    console.log(res.data);

    if (res.success) {
      // const newData = res.data.map(b => JSON.parse(b.content)
      // )
      // console.log(newData);

      setBlogs(res.data);
      setLoading(false);
    }
    // else if ( !res.authenticated ) {
    //   console.log(res.authenticated, "res.authenticated");
    //   props.router.navigate('/login');
    // }
  };

  console.log(user, "user");

  const parseData = (data) => {
    // const truncatedContent = data.substring(0, 30);
    // const contentState = ContentState.createFromText(truncatedContent);;
    // const editorState = EditorState.createWithContent(contentState);
    // return editorState
    try {
      const parsedData = JSON.parse(data);
      if (parsedData && parsedData.blocks) {
        const blocks = parsedData.blocks;
        const textContent = blocks.map((block) => block.text).join(" ");

        // Create a ContentState with the text content
        // const words = textContent.split(' ');
        const selectedWords = textContent.slice(0, 30);
        const contentState = ContentState.createFromText(selectedWords);

        // Create an EditorState with the ContentState
        return EditorState.createWithContent(contentState);
      }
    } catch (error) {
      console.log("Error parsing JSON:", error);
    }

    // Return an empty EditorState in case of errors
    return EditorState.createEmpty();
  };
  console.log(requestPage);

  // const fetchdetails = async () => {
  //   const res = await axios.get(`${process.env.REACT_APP_APIKEY}/api/fetch/one/blog/${id}`)
  //   if (res.success) {
  //     setDetail(res.data)
  //     const contentState = convertFromRaw(JSON.parse(res.data.content));
  //     const editorState = EditorState.createWithContent(contentState);
  //     setContent(editorState)
  //     setLoading(false)

  //   }
  // }

  // useEffect(() => {
  //   fetchdetails()
  // }, [])

  useEffect(() => {
    setLoading(true);
    fetchPublishBlogs();
  }, []);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "1") {
      fetchPublishBlogs();
    } else if (activeTab === "2") {
      fetchDraftBlogs();
    }
  }, [activeTab]);

  console.log(blogs);

  // if (loading) {
  //   return (
  //     <div style={{ height: "100%", width: "100%", backgroundColor: "red" }} >
  //       <Spinners setLoading={setLoading} />
  //     </div>
  //   )
  // }

  // const filterBlogs = ( cat: any ) => {
  //   const filter = blogs.filter( ( b ) => (
  //     b?.category?._id !== cat._id
  //   ) )

  //   setBlogs(filter)
  // }

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <ul
            className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
            role="tablist"
          >
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  setActiveTab("1");
                }}
              >
                Published
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  setActiveTab("2");
                }}
              >
                Drafts
              </NavLink>
            </NavItem>
          </ul>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                  className="container"
                >
                  <Spinners />
                </div>
              ) : (
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <div className="row align-items-center  ">
                          <div className="col-12">
                            <div className="justify-content-center d-flex mt-2">
                              <h5 className="mb-0">Published Blogs</h5>
                            </div>
                          </div>
                          {/* view options */}

                          {/* <Col xs={8}>
                          <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink
                                  className="disabled"
                                  to="#"
                                  tabIndex={-1}
                                >
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link className="nav-link" to="/blog-list">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link
                                  to="/blog-grid "
                                  className="nav-link active"
                                >
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </Col> */}
                        </div>

                        <hr className="mb-4" />

                        <Row xs="2">
                          {blogs &&
                            blogs.map((blog, i) => (
                              <Col sn={6} key={i}>
                                <Card className="p-1 border shadow-none">
                                  <div
                                    className="p-3"
                                    dir={blog.language === "ar" ? "rtl" : "ltr"}
                                  >
                                    <h5>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-dark"
                                      >
                                        {blog.title}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {" "}
                                      <Moment
                                        date={blog.publishDate}
                                        format="Do MMMM YYYY"
                                      />{" "}
                                    </p>
                                  </div>

                                  <div className="position-relative">
                                    <img
                                      // src={img1}
                                      src={`${blog?.image}`}
                                      alt={blog.title}
                                      className="img-thumbnail"
                                    />
                                  </div>
                                  {/* {
                                  console.log(process.env.REACT_APP_APIKEY/blog.image)
                                } */}
                                  <div
                                    className="p-3"
                                    dir={blog.language === "ar" ? "rtl" : "ltr"}
                                  >
                                    <ul className="list-inline pe-0">
                                      {/* <li className="list-inline-item me-3">
                                      <Link to="#" className="text-muted">
                                        <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                        Project
                                      </Link>
                                    </li> */}
                                      <li className={`list-inline-item`}>
                                        <Link to="#" className="text-muted">
                                          <i className="bx bx-comment-dots text-muted me-1"></i>{" "}
                                          12 Comments
                                        </Link>
                                      </li>
                                    </ul>
                                    <Editor
                                      editorState={parseData(blog.content)}
                                      readOnly={true}
                                    />
                                    {/* <p>
                                    {parseData(blog.content)}
                                  </p> */}

                                    <div>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-primary"
                                      >
                                        Read more{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))}

                          {/* <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Drawing a sketch
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">24 Mar, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img2}
                                alt=""
                                className="img-thumbnail"
                              />

                              <div className="blog-play-icon">
                                <Link
                                  to="#"
                                  className="avatar-sm d-block mx-auto"
                                >
                                  <span className="avatar-title rounded-circle font-size-18">
                                    <i className="mdi mdi-play"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Development
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>

                              <p>
                                At vero eos et accusamus et iusto odio
                                dignissimos ducimus quos
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col> */}
                        </Row>

                        {/* <Row>
                        <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Riding bike on road
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">10 Apr, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img3}
                                alt=""
                                className="img-thumbnail"
                              />
                            </div>

                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Travel
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>
                              <p>
                                Itaque earum rerum hic tenetur a sapiente
                                delectus ut aut
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col>

                        <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Project discussion with team
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">24 Mar, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img1}
                                alt=""
                                className="img-thumbnail"
                              />
                            </div>

                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Development
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>

                              <p>
                                Sed ut perspiciatis unde omnis iste eaque natus
                                error sit
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row> */}

                        <hr className="my-4" />

                        <div className="text-center">
                          {/* <ul className="pagination justify-content-center pagination-rounded">
                            <li className="page-item disabled">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-left"></i>
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                1
                              </Link>
                            </li>
                            <li className="page-item active">
                              <Link to="#" className="page-link">
                                2
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                3
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                ...
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                10
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-right"></i>
                              </Link>
                            </li>
                          </ul> */}
                          <div className="d-flex justify-content-center">
                            <Pagination aria-label="Page navigation example">
                              <PaginationItem
                                disabled={current > 1 ? false : true}
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setrequestPage((req) => req - 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-left" />
                                </PaginationLink>
                              </PaginationItem>

                              <PaginationItem active>
                                <PaginationLink>{current}</PaginationLink>
                              </PaginationItem>

                              <PaginationItem
                                disabled={
                                  current < pagination?.totalPages
                                    ? false
                                    : true
                                }
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setrequestPage((req) => req + 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-right" />
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>

            <TabPane tabId="2">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                  className="container"
                >
                  {" "}
                  <Spinners />{" "}
                </div>
              ) : (
                <div>
                  <Row className="justify-content-center">
                    <Col xl={8}>
                      <div>
                        <div className="row align-items-center  ">
                          <div className="col-12">
                            <div className="justify-content-center d-flex mt-2">
                              <h5 className="mb-0">Drafts</h5>
                            </div>
                          </div>
                          {/* view options */}

                          {/* <Col xs={8}>
                          <div className="float-end">
                            <ul className="nav nav-pills">
                              <NavItem>
                                <NavLink
                                  className="disabled"
                                  to="#"
                                  tabIndex={-1}
                                >
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <Link className="nav-link" to="/blog-list">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link
                                  to="/blog-grid "
                                  className="nav-link active"
                                >
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </ul>
                          </div>
                        </Col> */}
                        </div>

                        <hr className="mb-4" />

                        <Row xs="2">
                          {blogs &&
                            blogs.map((blog, i) => (
                              <Col sn={6} key={i}>
                                <Card className="p-1 border shadow-none">
                                  <div className="p-3">
                                    <h5>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-dark"
                                      >
                                        {blog.title}
                                      </Link>
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {" "}
                                      <Moment
                                        date={blog.publishDate}
                                        format="Do MMMM YYYY"
                                      />{" "}
                                    </p>
                                  </div>

                                  <div className="position-relative">
                                    <img
                                      // src={img1}
                                      src={`${blog?.image}`}
                                      alt={blog.title}
                                      className="img-thumbnail"
                                    />
                                  </div>
                                  {/* {
                                  console.log(process.env.REACT_APP_APIKEY/blog.image)
                                } */}
                                  <div className="p-3">
                                    <ul className="list-inline">
                                      {/* <li className="list-inline-item me-3">
                                      <Link to="#" className="text-muted">
                                        <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                        Project
                                      </Link>
                                    </li> */}
                                      <li className="list-inline-item me-3">
                                        <Link to="#" className="text-muted">
                                          <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                          12 Comments
                                        </Link>
                                      </li>
                                    </ul>
                                    <Editor
                                      editorState={parseData(blog.content)}
                                      readOnly={true}
                                    />
                                    {/* <p>
                                    {parseData(blog.content)}
                                  </p> */}

                                    <div>
                                      <Link
                                        to={`/blog-details/${blog._id}`}
                                        className="text-primary"
                                      >
                                        Read more{" "}
                                        <i className="mdi mdi-arrow-right"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                            ))}

                          {/* <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Drawing a sketch
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">24 Mar, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img2}
                                alt=""
                                className="img-thumbnail"
                              />

                              <div className="blog-play-icon">
                                <Link
                                  to="#"
                                  className="avatar-sm d-block mx-auto"
                                >
                                  <span className="avatar-title rounded-circle font-size-18">
                                    <i className="mdi mdi-play"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Development
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>

                              <p>
                                At vero eos et accusamus et iusto odio
                                dignissimos ducimus quos
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col> */}
                        </Row>

                        {/* <Row>
                        <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Riding bike on road
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">10 Apr, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img3}
                                alt=""
                                className="img-thumbnail"
                              />
                            </div>

                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Travel
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>
                              <p>
                                Itaque earum rerum hic tenetur a sapiente
                                delectus ut aut
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col>

                        <Col sm={6}>
                          <Card className="p-1 border shadow-none">
                            <div className="p-3">
                              <h5>
                                <Link to="blog-details" className="text-dark">
                                  Project discussion with team
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">24 Mar, 2020</p>
                            </div>

                            <div className="position-relative">
                              <img
                                src={img1}
                                alt=""
                                className="img-thumbnail"
                              />
                            </div>

                            <div className="p-3">
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                    Development
                                  </Link>
                                </li>
                                <li className="list-inline-item me-3">
                                  <Link to="#" className="text-muted">
                                    <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                                    08 Comments
                                  </Link>
                                </li>
                              </ul>

                              <p>
                                Sed ut perspiciatis unde omnis iste eaque natus
                                error sit
                              </p>

                              <div>
                                <Link to="#" className="text-primary">
                                  Read more{" "}
                                  <i className="mdi mdi-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row> */}

                        <hr className="my-4" />

                        <div className="text-center">
                          {/* <ul className="pagination justify-content-center pagination-rounded">
                            <li className="page-item disabled">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-left"></i>
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                1
                              </Link>
                            </li>
                            <li className="page-item active">
                              <Link to="#" className="page-link">
                                2
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                3
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                ...
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                10
                              </Link>
                            </li>
                            <li className="page-item">
                              <Link to="#" className="page-link">
                                <i className="mdi mdi-chevron-right"></i>
                              </Link>
                            </li>
                          </ul> */}
                          <div className="d-flex justify-content-center">
                            <Pagination aria-label="Page navigation example">
                              <PaginationItem
                                disabled={current > 1 ? false : true}
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setrequestPage((req) => req - 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-left" />
                                </PaginationLink>
                              </PaginationItem>

                              <PaginationItem active>
                                <PaginationLink>{current}</PaginationLink>
                              </PaginationItem>

                              <PaginationItem
                                disabled={
                                  current < pagination?.totalPages
                                    ? false
                                    : true
                                }
                              >
                                <PaginationLink
                                  onClick={() =>
                                    setrequestPage((req) => req + 1)
                                  }
                                >
                                  <i className="mdi mdi-chevron-right" />
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </React.Fragment>
  );
};
export default withRouter(BlogGrid);
