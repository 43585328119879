import React, { useState } from "react";
import MyContext from "./MyContext";
import PropTypes from "prop-types";

function MyProvider({ children }) {
  const [socket, setSocket] = useState<any>(null);
  const [notifySound, setNotifySound] = useState<any>(true);
  const [acceptChat, setAcceptChat] = useState<any>(false);
  const [CurrentConvo, setCurrentConvo] = useState<any>(null);
  const [conversations, setConversations] = useState<any[]>([]);
  const [removeId, setRemoveId] = useState<string>(null);
  const [queue, setQueue] = useState<number>(0);
  const [activeCustomers, setActiveCustomers] = useState<number>(0);
  console.log(conversations, "myconversations in provider");
  const [list, setList] = useState<any>([]);
  const [lang, setLang] = useState<any>("en");
  const [peoplesQueue, setPeoplesQueue] = useState<any>([]);
  const [accessChat, setAccessChat] = useState<any>(null);
  const [shift, setShift] = useState<any>({});

  return (
    <MyContext.Provider
      value={{
        socket,
        setSocket,
        conversations,
        setConversations,
        acceptChat,
        setAcceptChat,
        notifySound,
        setNotifySound,
        CurrentConvo,
        setCurrentConvo,
        removeId,
        setRemoveId,
        queue,
        setQueue,
        activeCustomers,
        setActiveCustomers,
        list,
        setList,
        lang,
        setLang,
        peoplesQueue,
        setPeoplesQueue,
        accessChat,
        setAccessChat,
        shift, setShift
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

MyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MyProvider;
