import { Dispatch, SetStateAction, createContext } from "react";

const MyContext = createContext<any>({
  socket: null,
  setSocket: () => { },
  conversations: [],
  setConversations: () => { },
  acceptChat: false,
  setAcceptChat: () => { },
  notifySound: true,
  setNotifySound: () => { },
  CurrentConvo: null,
  setCurrentConvo: () => { },
  removeId: "",
  setRemoveId: () => { },
  queue: 0,
  setQueue: () => { },
  activeCustomers: 0,
  setActiveCustomers: () => { },
  peoplesQueue: [],
  setPeoplesQueue: () => { },
  accessChat: null,
  setAccessChat: () => { },
  shift: {},
  setShift: () => { }
});

export default MyContext;
