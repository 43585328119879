import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { PhotoProvider, PhotoView } from "react-photo-view";
import moment from "moment";
import { debounce } from "lodash";
import MyContext from "MyContext/MyContext";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import StarRatings from "react-star-ratings";
import Moment from "react-moment";
// import { WithContext as ReactTags } from 'react-tag-input';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
} from "reactstrap";
import { Table } from "antd";

import classnames from "classnames";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// emoji
import EmojiPicker from "emoji-picker-react";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import avatar1 from "../../assets/images/users/avatar-1.jpg";

import {
  getChats as onGetChats,
  getGroups as onGetGroups,
  getContacts as onGetContacts,
  getMessages as onGetMessages,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage,
} from "../../slices/chats/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import axios from "axios";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Chat from "./Chat";
import { cl } from "@fullcalendar/core/internal-common";

require("flatpickr/dist/flatpickr.css");
const ChatHistory = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [support, setSupport] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [modal_scroll, setmodal_scroll] = useState(false);
  const { socket, CurrentConvo } = useContext(MyContext);
  const [availableSupport, setAvailableSupport] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  console.log(search, "search hello");

  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });

  useEffect(() => {
    if (selectedTags.length) {
    }
  }, [selectedTags]);

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions");

    setSelectedTags(selectedOptions);
  };
  const UserData = JSON.parse(localStorage.getItem("authUser"));

  console.log(UserData, "UserData");

  //meta title
  document.title = "Chat | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const selectProperties = createSelector(
    (state: any) => state.chats,
    (chats) => ({
      chats: chats.chats,
      groups: chats.groups,
      contacts: chats.contacts,
      messages: chats.messages,
      loading: chats.loading,
    })
  );
  const [isLoading, setLoading] = useState(false); //use loading instead of false

  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars

  const [chats, setChats] = useState<any>([]);
  const [messagesData, setMessagesData] = useState<any>([]);
  const [menu1, setMenu1] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string | number>("1");
  const [Chat_Box_Username, setChat_Box_Username] =
    useState<string>("Steven Franklin");
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] =
    useState<string>("online");
  const [curMessage, setcurMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isdisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const simpleBarRef = useRef();

  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetGroups());
    dispatch(onGetContacts());
    dispatch(onGetMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const transfer = (sup: any) => {
    const data = {
      conversationId: messagesData._id,
      newUserId: sup.data._id,
    };

    setmodal_scroll(false);

    console.log("transfer_chats", data);

    if (socket) {
      socket.emit("transfer_chats", data);
    }
  };

  function tog_scroll() {
    if (socket) {
      socket.emit("available_agents");
    }

    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  //Use For Chat Box
  const userChatOpen = (chats: any) => {
    setCurrentRoomId(chats?._id);
    setMessagesData(chats);
  };

  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const time: any = `${hours}: ${minutes}`;
  const addMessage = () => {
    if (curMessage !== "" || selectedImage !== null) {
      const newMessage = {
        id: Math.floor(Math.random() * 100),
        to_id: 2,
        msg: curMessage,
        isSameTime: true,
        images: selectedImage,
        time: time,
      };
      dispatch(onAddMessage(newMessage));
      setcurMessage("");
      setDisable(false);
      setEmoji(false);
      setSelectedImage(null);
    }
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  // scroll simple bar
  const scroollRef = useRef<any>(null);
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight;
    }
  }, [messagesData]);

  // copy msg
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const copyMsg = (ele: any) => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML;
    navigator.clipboard.writeText(copyText);
    setCopyMsgAlert(true);
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false);
      }, 1000);
    }
  };

  const getChatHistory = async (newOffset) => {
    let filters = {};

    if (selectedTags.length) {
      Object.assign(filters, { support_userId: selectedTags });
    }

    if (dateRange.from != "" && dateRange.to != "") {
      Object.assign(filters, {
        date: { from: dateRange.from, to: dateRange.to },
      });
    }
    if (search != "") {
      Object.assign(filters, { search: search });
    }

    const res = await axios.get(
      `${
        process.env.REACT_APP_APIKEY
      }/api/fetch/all/conversations/${newOffset}/${JSON.stringify(filters)}`
    );

    if (res.success) {
      setLoading(false);
      if (res.data.length) {
        console.log("condition 1");

        if (newOffset === 0) {
          setCurrentRoomId(res.data[0]?._id);
          setMessagesData((prev) => res.data[0]);
        }

        setChats((prev: any) => {
          if (newOffset === 0) {
            return res.data;
          } else {
            return [...prev, ...res.data];
          }
        });
      } else if (res.data.length && offset === 0) {
        console.log("condition 2");
        setChats([]);
        setMessagesData([]);
        setCurrentRoomId(1);
      } else if (!res.data.length && offset !== 0) {
        console.log("condition 3");
      } else if (!res.data.length) {
        console.log("condition 4");
        setChats([]);
        setMessagesData([]);
        setCurrentRoomId(1);
      }

      console.log(res.data);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|?]+)/gi;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }

  useEffect(() => {
    setOffset(0);
    getChatHistory(0);
  }, [selectedTags, dateRange, search]);

  useEffect(() => {
    setLoading(true);
    console.log(offset, "offset");

    getChatHistory(offset);
  }, [offset]);

  useEffect(() => {
    // Accessing the SimpleBar instance or its DOM node
    if (simpleBarRef.current) {
      const simpleBarInstance: any = simpleBarRef.current;
      const scrollElement = simpleBarInstance.getScrollElement(); // Get the scrollable element

      // Attach the scroll event listener
      scrollElement.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [simpleBarRef.current]);

  const debouncedHandleScroll = debounce(
    ({ scrollTop, clientHeight, scrollHeight }) => {
      console.log("handleScroll");

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        // Increased threshold
        setOffset((prevOffset) => prevOffset + 15);
      }
    },
    100
  );

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    debouncedHandleScroll({ scrollTop, clientHeight, scrollHeight });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set the width to 100%
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%", // Set the width to 100%
      backgroundColor: "white", // Set the background color to white
    }),
  };

  const fetchAllSupport = async () => {
    console.log("support data ", "fetchAllSupport");
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/all/support`
    );
    if (res.success) {
      const data = [];
      res.data.map((sup) => {
        data.push({
          value: `${sup?._id}`,
          label: `${sup?.name?.firstName} ${sup?.name?.lastName}`,
        });
      });
      console.log("support data ", data);
      setSupport(data);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("agents_available", (data: any) => {
        console.log("all agents", data);
        if (data) {
          setAvailableSupport(data?.agents);
        }
      });

      socket.off("available_agents");
    }
  }, [socket]);

  useEffect(() => {
    fetchAllSupport();
  }, []);

  console.log("messagesData", messagesData);

  const columns = [
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>
            Support Name
          </h6>
        </div>
      ),
      dataIndex: "SupportName",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>Like</h6>
        </div>
      ),
      dataIndex: "Like",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>
            Dislike
          </h6>
        </div>
      ),
      dataIndex: "Dislike",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>
            Resolved
          </h6>
        </div>
      ),
      dataIndex: "ResolvedUnresolved",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>
            Comments
          </h6>
        </div>
      ),
      dataIndex: "Comments",
      width: 100,
    },
    {
      title: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ textAlign: "center", color: "rgb(63,63,63)" }}>
            CreatedAt
          </h6>
        </div>
      ),
      dataIndex: "CreatedAt",
      width: 100,
    },
  ];

  const data = messagesData?.feedbacks?.map((feedback, index) => ({
    key: index.toString(),
    SupportName: (
      <p style={{ textAlign: "center" }}>{messagesData.support_name}</p>
    ),
    Like: (
      <p style={{ textAlign: "center" }}>
        {feedback.liked === true ? "yes" : "no"}
      </p>
    ),
    Dislike: (
      <p style={{ textAlign: "center" }}>
        {feedback.liked === false ? "yes" : "no"}
      </p>
    ),
    ResolvedUnresolved: (
      <p style={{ textAlign: "center" }}>{feedback.resolved ? "yes" : "no"}</p>
    ),
    Comments: (
      <p style={{ textAlign: "center", textTransform: "lowercase" }}>
        {feedback.comment}
      </p>
    ),
    CreatedAt: (
      <Moment
        date={feedback?.createdAt}
        format="Do MMMM YYYY"
        style={{
          textAlign: "center",
        }}
      />
    ),
  }));

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  console.log(data, "data is here");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat Support" breadcrumbItem="Chats History" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4 ">
                  <div>
                    <div className=" border-bottom mb-2">
                      <div className="d-flex mb-2">
                        <div
                          className="align-self-center"
                          style={{ width: "100%" }}
                        >
                          <label> Search By Agents</label>
                          <Select
                            isMulti
                            options={support}
                            value={selectedTags}
                            onChange={handleChange}
                            placeholder="Select Agents to Fetch Their Chats"
                            styles={customStyles} // Apply custom styles
                          />
                        </div>
                      </div>
                      <div className="align-self-center">
                        <label> Search By Date Range</label>
                        <Flatpickr
                          className="form-control"
                          value={dateRange.fullDate || ""}
                          placeholder="Please Select a Range To Fetch Chats"
                          options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            defaultDate: "today",
                          }}
                          // onChange={(date, dateStr) => {
                          //   setDateRange({
                          //     from: date[0]
                          //       ? moment(date[0]).format("YYYY-MM-DD")
                          //       : "",
                          //     to: date[1]
                          //       ? moment(date[1]).format("YYYY-MM-DD")
                          //       : "",
                          //     fullDate: dateStr,
                          //   });
                          // }}
                          onClose={(date, dateStr) => {
                            setDateRange({
                              from: date[0]
                                ? moment(date[0]).format("YYYY-MM-DD")
                                : "",
                              to: date[1]
                                ? moment(date[1]).format("YYYY-MM-DD")
                                : "",
                              fullDate: dateStr,
                            });
                          }}
                          id="default-picker"
                        />
                      </div>
                      <div>
                        <label htmlFor="example-search-input" className="mt-2">
                          Search
                        </label>
                        <div>
                          <input
                            style={{ width: "100%" }}
                            className="form-control"
                            type="search"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search By Name and Email"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified className="mt-3">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">
                              Chats History
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <ul
                              className="list-unstyled chat-list position-relative"
                              id="recent-list"
                            >
                              {isLoading && offset === 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "50vh",
                                  }}
                                >
                                  {" "}
                                  <Spinners />
                                </div>
                              ) : (
                                <>
                                  <SimpleBar
                                    ref={simpleBarRef}
                                    style={{ height: "315px" }}
                                  >
                                    <div style={{ height: "315px" }}>
                                      {chats.map((chat: any, i) => (
                                        <li
                                          key={i}
                                          className={
                                            currentRoomId === chat?._id
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <Link
                                            to="#"
                                            onClick={() => userChatOpen(chat)}
                                          >
                                            <div className="d-flex">
                                              <div className="align-self-center me-3">
                                                <i
                                                  className={
                                                    chat.status === "online"
                                                      ? "mdi mdi-circle text-success font-size-10"
                                                      : chat.status ===
                                                        "intermediate"
                                                      ? "mdi mdi-circle text-warning font-size-10"
                                                      : "mdi mdi-circle font-size-10"
                                                  }
                                                />
                                              </div>

                                              <div
                                                className="flex-grow-1 overflow-hidden"
                                                style={{ width: "65%" }}
                                              >
                                                <h5 className="text-truncate font-size-14 mb-1">
                                                  {chat?.customer_name}
                                                </h5>
                                                <p className="text-truncate mb-0">
                                                  {chat?.messages[
                                                    chat?.messages?.length - 1
                                                  ]?.file
                                                    ? "file"
                                                    : chat.messages[
                                                        chat?.messages?.length -
                                                          2
                                                      ]?.body
                                                    ? chat.messages[
                                                        chat?.messages?.length -
                                                          2
                                                      ]?.body
                                                    : chat.messages[
                                                        chat?.messages?.length -
                                                          1
                                                      ]?.body}
                                                </p>
                                              </div>
                                              <div
                                                className="font-size-10"
                                                style={{
                                                  textAlign: "end",
                                                  width: "35%",
                                                }}
                                              >
                                                {moment(
                                                  chat.createdOn
                                                ).fromNow()}
                                              </div>
                                            </div>
                                          </Link>
                                        </li>
                                      ))}
                                    </div>
                                  </SimpleBar>
                                </>
                              )}
                            </ul>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md={5} xs={10}>
                          <h5 className="font-size-15 mb-1">
                            {messagesData.customer_name}
                          </h5>
                          <h5 className="font-size-15 mb-1">
                            {messagesData.customer_email}
                          </h5>
                        </Col>
                        <Col md={7} xs={2} className="text-end">
                          <button className="btn btn-danger w-25">Blogs</button>
                        </Col>
                        {messagesData.active && (
                          <Col md={7} xs={2}>
                            <ul className="list-inline user-chat-nav text-end mb-0">
                              <li className="list-inline-item">
                                <Dropdown
                                  isOpen={other_Menu}
                                  toggle={toggleOther}
                                >
                                  <DropdownToggle
                                    className="btn nav-btn"
                                    tag="a"
                                  >
                                    <i className="bx bx-dots-horizontal-rounded" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      onClick={() => {
                                        tog_scroll();
                                      }}
                                    >
                                      Transfer Chat
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                            <Modal
                              isOpen={modal_scroll}
                              toggle={() => {
                                tog_scroll();
                              }}
                              scrollable={true}
                            >
                              <ModalHeader
                                toggle={() => {
                                  tog_scroll();
                                }}
                              >
                                <div className="modal-title mt-0 h5">
                                  Transfer Chat
                                </div>
                              </ModalHeader>
                              <div className="modal-body">
                                {availableSupport.map((sup: any, i: any) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                    className="p-3"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div>
                                        <img
                                          className="rounded-circle avatar-xs"
                                          style={{
                                            width: "60px",
                                            height: "60px",
                                          }}
                                          src={avatar7}
                                          alt=""
                                        />
                                      </div>
                                      <div className="mx-2">
                                        <h5 className="font-size-14 mb-1 px-2">
                                          <Link
                                            to="#"
                                            className="text-dark"
                                          >{`${sup.data?.name?.firstName} ${sup.data?.name?.lastName}`}</Link>
                                        </h5>
                                        <p
                                          className="text-muted mb-0"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <i
                                            className={
                                              // Chat_Box_User_Status === "online"
                                              // ?
                                              "mdi mdi-circle text-success align-middle me-2"
                                              // : Chat_Box_User_Status === "intermediate"
                                              //   ? "mdi mdi-circle text-warning align-middle me-1"
                                              //   : "mdi mdi-circle align-middle me-1"
                                            }
                                          />
                                          {"Active now"}
                                        </p>
                                      </div>
                                    </div>
                                    {(UserData?.user?._id).toString() !==
                                      sup?.data?._id?.toString() && (
                                      <button
                                        className="btn btn-success"
                                        onClick={() => transfer(sup)}
                                      >
                                        Transfer
                                      </button>
                                    )}
                                  </div>
                                ))}

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setmodal_scroll(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </div>
                            </Modal>
                          </Col>
                        )}
                      </Row>
                    </div>
                    <div>
                      <div className="chat-conversation p-3">
                        {isLoading ? (
                          offset === 0 ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "50vh",
                              }}
                            >
                              {" "}
                              <Spinners />
                            </div>
                          ) : (
                            <div>Loading...</div>
                          )
                        ) : (
                          <SimpleBar
                            ref={scroollRef}
                            style={{ height: "470px" }}
                          >
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "50vh",
                                }}
                              >
                                {" "}
                                <Spinners />
                              </div>
                            ) : (
                              <ul
                                className="list-unstyled"
                                id="users-conversation"
                              >
                                <React.Fragment>
                                  {messagesData?.messages?.map(
                                    (userMsg, index: number) =>
                                      !userMsg.ended ? (
                                        userMsg.file === true ? (
                                          <div key={index}>
                                            <PhotoProvider>
                                              <PhotoView
                                                src={userMsg.file_path}
                                              >
                                                <img
                                                  className={
                                                    userMsg.support
                                                      ? "conversation-list"
                                                      : "conversation-list right"
                                                  }
                                                  style={
                                                    userMsg.support
                                                      ? {
                                                          width: "300px",
                                                          float: "right",
                                                        }
                                                      : { width: "300px" }
                                                  }
                                                  src={userMsg.file_path}
                                                  alt=""
                                                />
                                              </PhotoView>
                                            </PhotoProvider>
                                          </div>
                                        ) : (
                                          <li
                                            key={index}
                                            className={
                                              userMsg.support ? "right" : "left"
                                            }
                                          >
                                            <div
                                              className="conversation-list"
                                              dir={
                                                CurrentConvo?.language ===
                                                "arabic"
                                                  ? "rtl"
                                                  : "ltr"
                                              }
                                            >
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  href="#!"
                                                  tag="a"
                                                  className="dropdown-toggle"
                                                >
                                                  <i className="bx bx-dots-vertical-rounded" />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem
                                                    onClick={(e) =>
                                                      copyMsg(e.target)
                                                    }
                                                    href="#"
                                                  >
                                                    Copy
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                              {/* <div className="ctext-wrap">
                                              <div className="conversation-name">
                                                {userMsg.support
                                                  ? "You"
                                                  : messagesData.customer_name}
                                              </div>
                                              <p>{userMsg.body}</p>
                                              {
                                                <p className="chat-time mb-0">
                                                  <i className="bx bx-time-five align-middle me-1"></i>
                                                  {moment(
                                                    userMsg.createdOn
                                                  ).format("hh:mmA")}
                                                </p>
                                              }
                                            </div> */}
                                              <div
                                                className="ctext-wrap"
                                                dir={
                                                  CurrentConvo?.language ===
                                                  "arabic"
                                                    ? "rtl"
                                                    : "ltr"
                                                }
                                              >
                                                <div className="conversation-name">
                                                  {userMsg.support
                                                    ? userMsg.supportName
                                                      ? userMsg.supportName
                                                      : messagesData.support_name
                                                    : messagesData.customer_name}
                                                </div>
                                                {/* Render the message body with linkify */}
                                                <p
                                                  dir={
                                                    CurrentConvo?.language ===
                                                    "arabic"
                                                      ? "rtl"
                                                      : "ltr"
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html: linkify(
                                                      userMsg.body
                                                    ),
                                                  }}
                                                ></p>
                                                <div
                                                  className="d-flex justify-content-between"
                                                  style={{ gap: "17px" }}
                                                >
                                                  <span className="chat-time mb-0">
                                                    <i className="bx bx-calendar align-middle me-1"></i>
                                                    {moment(
                                                      userMsg.createdOn
                                                    ).format("Do MMMM YYYY")}
                                                  </span>
                                                  <span className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    {moment(
                                                      userMsg.createdOn
                                                    ).format("hh:mmA")}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      ) : (
                                        <li>
                                          <div className="chat-day-title">
                                            <span
                                              className="title"
                                              // style={{ color: "#232323" }}
                                            >
                                              {userMsg.endedBy}{" "}
                                              {userMsg.createdOn && (
                                                <Moment
                                                  date={userMsg.createdOn}
                                                  format="Do MMMM YYYY h:mm a"
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </li>
                                      )
                                  )}

                                  {!messagesData && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <p> No Chats Available To Display </p>
                                    </div>
                                  )}
                                </React.Fragment>
                              </ul>
                            )}
                          </SimpleBar>
                        )}
                      </div>
                      {selectedImage && (
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <img
                              src={selectedImage}
                              alt="select img"
                              style={{ width: "150px", height: "auto" }}
                            />
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              id="close_toggle"
                              className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                              onClick={() => setSelectedImage(null)}
                            >
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
              <div>
                <div
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <span
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    {hasSelected
                      ? `Selected ${selectedRowKeys.length} items`
                      : ""}
                  </span>
                </div>
                <Table
                  columns={columns}
                  dataSource={data}
                  // pagination={true}
                  scroll={{
                    x: 700,
                  }}
                  style={{ marginBottom: "50px" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChatHistory;
