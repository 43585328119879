import React, { useEffect, useState } from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartDynamicColor";
import { DateSchema } from 'yup';
import axios from 'axios';

const JobWidgetCharts = ({ dataColors, series }: any) => {
    var areacharteathereumColors = getChartColorsArray(dataColors);

    var options: any = {
        chart: {
            width: 130,
            height: 46,
            type: "area",
            sparkline: {
                enabled: true,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 1.5,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [50, 100, 100, 100],
            },
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName: any) {
                        return ''
                    }
                }
            },
            marker: {
                show: false
            }
        },
        colors: areacharteathereumColors,
    };
    return (
        <React.Fragment>
            {/* <ReactApexChart
                options={options}
                series={series}
                type="area"
                height="46"
                width="130"
                className="apex-charts"
            /> */}
        </React.Fragment>
    );
};

const StatisticsApplicationsChart = ({ statisticsApplications, dataColors, active }: any) => {

    const statisticsApplicationColors = ['#34c38f', '#FF0000',]
    const [weeklyDates, setWeeklyDates] = useState<any>([])
    const [ reportData, setReportData ] = useState<any>([])
    const [data, setdata] = useState(null)

    console.log(active, "active");

    const fetchChatStats = async () => {
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/api/get/dashboard/chat/stats`)
        if (res.success) {
            setdata(res.data)
        }
    }
    useEffect(() => {
        fetchChatStats()
    }, [])


    const getDates = () => {
        const dates = [];
        const today = new Date();
        if (active === 1) {
            for (let i = 0; i < 7; i++) {
                const pastDate = new Date(today);
                pastDate.setDate(pastDate.getDate() - i);

                // Format the date as MM/DD/YYYY
                const formattedDate = pastDate.toLocaleDateString('en-US');
                dates.push(formattedDate);
            }
        }

        if (active === 2) {
            for (let i = 0; i < 30; i++) {
                const pastDate = new Date(today);
                pastDate.setDate(pastDate.getDate() - i);

                // Format the date as MM/DD/YYYY
                const formattedDate = pastDate.toLocaleDateString('en-US');
                dates.push(formattedDate);
            }
        }

        if (active === 3) {
            const currentMonth = new Date().getMonth(); // 0 to 11
            const currentYear = new Date().getFullYear();
            for (let i = 11; i >= 0; i--) {
                let month = currentMonth - i;
                let year = currentYear;
                if (month < 0) {
                    month += 12;
                    year -= 1;
                }
                const date = new Date(year, month);
                dates.push(date.toLocaleString('en-US', { month: 'long', year: 'numeric' }));
            }
        }



        return dates;
    };

    console.log(getDates(), "getDates()")

    useEffect(() => {
        setWeeklyDates((prev) => {
            return getDates()
        })
    }, [active])

    console.log(weeklyDates, "weeklyDates");


    const series: any = [{
        name: 'Chats Completed',
        type: 'column',
        data: active === 1 ? data?.weeklyCompletedChats : active === 2 ? data?.monthlyCompletedChats : active === 3 ? data?.yearlyCompletedChats : [],
    }, {
        name: 'Missed Chats',
        type: 'column',
        data: active === 1 ? data?.weeklyMissedChats : active === 2 ? data?.monthlyMissedChats : active === 3 ? data?.yearlyMissedChats : [],
    },
    ];
    var options: any = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            offsetY: 10,
        },
        stroke: {
            width: [0, 2, 2, 2],
            curve: 'smooth'
        },
        plotOptions: {
            bar: {
                columnWidth: '25%'
            }
        },
        fill: {
            opacity: [1, 1, 0.1, 1],
            gradient: {
                inverseColors: false,
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100]
            }
        },
        labels: weeklyDates,
        colors: statisticsApplicationColors,
        markers: {
            size: 0
        },
        xaxis: {
            type: 'date'
        },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y: any) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0) + " points";
                    }
                    return y;

                }
            }
        }
    }
    if (data) {
        return (
            <React.Fragment>
                {
                    weeklyDates && <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                        className="apex-charts pb-3"
                    />
                }

            </React.Fragment>
        );
    }

};

const ReceivedTimeCharts = ({ dataColors }: any) => {
    var ApplicationReveicedTimeColors = getChartColorsArray(dataColors);

    const series = [{
        name: 'Received Application',
        data: [34, 44, 54, 21, 12, 43, 33, 80, 66]
    }];
    var options: any = {
        chart: {
            type: 'line',
            height: 378,
            toolbar: {
                show: false,
            },
        },

        stroke: {
            width: 3,
            curve: 'smooth'
        },
        labels: ['8 PM', '9 PM', '10 PM', '11 PM', '12 PM', '1 AM', '2 AM',
            '3 AM', '4 AM'
        ],
        dataLabels: {
            enabled: false
        },
        colors: ApplicationReveicedTimeColors,
        markers: {
            hover: {
                sizeOffset: 4
            }
        }
    };
    return (
        <React.Fragment>
            {/* <ReactApexChart
                options={options}
                series={series}
                type="line"
                height="378px"
                width="456px"
                className="apex-charts"
            /> */}
        </React.Fragment>
    );
};

export { JobWidgetCharts, StatisticsApplicationsChart, ReceivedTimeCharts };