import React, { useEffect, useContext } from "react";
import withRouter from "Components/Common/withRouter";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunk";
import MyContext from "MyContext/MyContext";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const Logout = () => {
  const { socket, acceptChat } = useContext(MyContext);
  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (isUserLogout) => isUserLogout
  );

  const { isUserLogout } = useSelector(selectProperties);

  const UserData = JSON.parse(localStorage.getItem("authUser"));
  const stopAcceptingChat = () => {
    console.log("In Stop accepting Chat Funtion");
    if (acceptChat) {
      const stopAcceptRes = socket.emit("stop_accepting_chats", {
        userId: UserData.user._id,
      });
      console.log("Stop accept Results", stopAcceptRes);
    }
  };

  useEffect(() => {
    dispatch(logoutUser());
    stopAcceptingChat();
  }, [dispatch]);
  if (isUserLogout) {
    return <Navigate to="/login" />;
  }

  return <></>;
};

export default withRouter(Logout);
