import React, { useEffect, useState, useRef, useContext } from "react";
import MyContext from "../../MyContext/MyContext";

//Breadcrumbs
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import axios from "axios";
import {
  Button,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import SimpleBar from "simplebar-react";
import { values } from "lodash";
interface QuickReply {
  id: string;
  body: string;
  createdAt: string;
}

function QuickReply() {
  const ref = useRef<any>();
  const UserData = JSON.parse(localStorage.getItem("authUser"));
  const [showActions, setShowActions] = useState<number>(null);
  const [IsEditModal, setIsEditModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [DeleteId, setDeleteId] = useState<any>("");
  const [QucikReplies, setQuickReplies] = useState<any>([]);
  const headers = {
    Authentication: `Bearer ${UserData?.token}`,
  };

  const { lang, setLang } = useContext(MyContext);
  const handleLanguage = (e) => {
    console.log(e.target.value);
    setLang(e.target.value);
  };

  const validationType: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    initialValues: {
      _id: "",
      message: "",
      language: "",
      name: "",
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required("Quick Message Is Required"),
      name: Yup.string().required("Name Is Required"),
    }),
    onSubmit: (values: any, { resetForm }) => {
      console.log("values", values);
      const data: any = {
        body: values.message,
        language: values.language,
        name: values.name,
      };
      if (IsEditModal) {
        const updateData: any = {
          _id: values._id,
          body: values.message,
          name: values.name,
        };
        EditQuickReply(updateData);
      } else {
        AddQuickReply(data);
      }

      resetForm();
      toggleModel();
    },
  });

  //modal Handlers
  const openAddModel = () => {
    setIsEditModal(false);
    setOpenModal(true);
  };
  const toggleModel = () => {
    setOpenModal(!openModal);
    validationType.setFieldValue("message", "");
  };
  const toggleDeleteModel = () => {
    setOpenDeleteModal(!openDeleteModal);
  };
  const openEditModel = (data: any) => {
    setIsEditModal(true);
    setOpenModal(true);
    validationType.setFieldValue("message", data.body);
    validationType.setFieldValue("name", data.name);
    validationType.setFieldValue("_id", data._id);
  };

  //API  CAlling

  // Add Quick Reply API
  const AddQuickReply = async (values: any) => {
    console.log("values added", values);
    try {
      const ReplyRes = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/add/quick-reply/${UserData?.user._id}`,
        values,
        { headers }
      );
      console.log("Result in AddQuickReply function ", ReplyRes);
      setQuickReplies((prevReplies) => [...prevReplies, ReplyRes.data]);
    } catch (error) {}
  };

  //Delete Quick Reply API
  const DeleteQuickReply = async () => {
    try {
      if (DeleteId) {
        console.log("id in Delete function ", DeleteId);
        const ReplyRes = await axios.delete(
          `${process.env.REACT_APP_APIKEY}/api/delete/quick-reply/${UserData?.user._id}`,
          {
            data: {
              // Your data to be sent in the request body
              id: DeleteId,
            },
            headers,
          }
        );
        console.log("Result in Delete function ", ReplyRes);
        toggleDeleteModel();
      }
    } catch (error) {
      console.log("unable to delete", error);
    }
  };

  // Edit Quick Reply API
  const EditQuickReply = async (values: any) => {
    try {
      const ReplyRes = await axios.put(
        `${process.env.REACT_APP_APIKEY}/api/edit/quick-reply/${UserData?.user._id}`,
        values,
        { headers }
      );
      console.log("Result in EditQuickReply function ", ReplyRes);
    } catch (error) {}
  };

  const getAllReplies = async () => {
    try {
      const ReplyRes = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/quick-reply/${UserData?.user._id}`,
        { headers }
      );
      setQuickReplies(ReplyRes.data);
      console.log("All Quick Replies in getAllReplies function ", ReplyRes);
    } catch (error) {
      console.log("Error Found", error);
    }
  };
  useEffect(() => {
    getAllReplies();
    console.log("In UseEffect");
  }, [IsEditModal, openModal, openDeleteModal]);

  return (
    <React.Fragment>
      <div className="  page-content">
        <Breadcrumbs title="Chat Support" breadcrumbItem="Quick Replies" />

        <div className=" w-100 position-relative my-3 d-flex justify-content-end align-items-center ">
          <button className=" btn btn-dark " onClick={openAddModel}>
            Add Quick Reply
            <i className="mdi mdi-lightning-bolt-outline me-1" />{" "}
          </button>
        </div>
        <SimpleBar className="" style={{ height: "510px" }} ref={ref}>
          <div className=" d-flex flex-column justify-content-center align-items-center container-fluid">
            {QucikReplies &&
              QucikReplies.map((rep: any, ind) => {
                return (
                  <div style={{ width: "100%" }}>
                    <h6 style={{ paddingLeft: "15px" }}>{rep?.name}</h6>
                    <div
                      key={ind}
                      className=" container-fluid d-flex justify-content-center  align-items-center message-text-div shadow "
                      style={{
                        minHeight: "70px",
                      }}
                      onMouseEnter={() => setShowActions(ind)}
                      onMouseLeave={() => {
                        setShowActions(null);
                      }}
                      dir={rep.language === "ar" ? "rtl" : "ltr"}
                    >
                      <p className="message-text-div  w-100 m-0">{rep?.body}</p>
                      {showActions === ind ? (
                        <>
                          <button
                            type="button"
                            className="btn edit-quick-reply-btn mx-1 "
                            onClick={() => openEditModel(rep)}
                          >
                            <i className="bx bx-edit   "></i>
                          </button>

                          <button
                            type="button"
                            className="btn delete-quick-reply-btn mx-1"
                            onClick={(e) => {
                              setDeleteId(rep._id);
                              toggleDeleteModel();
                            }}
                          >
                            <i className="bx bxs-trash"></i>
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </SimpleBar>
      </div>

      <Modal
        isOpen={openModal}
        toggle={() => {
          toggleModel();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleModel();
          }}
        >
          <div className="modal-title mt-0 h5">Add New Quick Reply</div>
        </ModalHeader>
        <div className="modal-body">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validationType.handleSubmit();
              return false;
            }}
          >
            <div>
              <FormGroup className="mb-3">
                <Label>Select Language</Label>
                <select
                  className="form-select"
                  name="language"
                  onChange={validationType.handleChange}
                >
                  <option selected={lang === "en" ? true : false} value="en">
                    English
                  </option>
                  <option selected={lang === "ar" ? true : false} value="ar">
                    عربی
                  </option>
                </select>
              </FormGroup>
            </div>
            <div className="mb-3">
              <Label className="form-label">Name</Label>

              <Input
                name="name"
                placeholder="Enter Quick Message Here"
                type="text"
                onChange={validationType.handleChange}
                onBlur={validationType.handleBlur}
                value={validationType.values.name || ""}
                invalid={
                  validationType.touched.name && validationType.errors.name
                    ? true
                    : false
                }
              />
              {validationType.touched.name && validationType.errors.name ? (
                <FormFeedback type="invalid">
                  {validationType.errors.name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3">
              <Label className="form-label">Quick Reply</Label>

              <Input
                name="message"
                placeholder="Enter Quick Message Here"
                type="textarea"
                onChange={validationType.handleChange}
                onBlur={validationType.handleBlur}
                value={validationType.values.message || ""}
                invalid={
                  validationType.touched.message &&
                  validationType.errors.message
                    ? true
                    : false
                }
              />
              {validationType.touched.message &&
              validationType.errors.message ? (
                <FormFeedback type="invalid">
                  {validationType.errors.message}
                </FormFeedback>
              ) : null}
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button type="submit" color="primary">
                {IsEditModal ? "Edit" : "Add"}
              </Button>{" "}
            </div>
          </Form>
        </div>
      </Modal>
      {/* Delete Modal */}
      <Modal
        isOpen={openDeleteModal}
        toggle={() => {
          toggleDeleteModel();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleDeleteModel();
          }}
        >
          <div className="modal-title mt-0 h5">Add New Quick Reply</div>
        </ModalHeader>
        <div className="modal-body">
          <p>"Are you Sure You want to delete ? "</p>
          <div className="d-flex flex-wrap gap-2">
            <Button color="danger" onClick={() => DeleteQuickReply()}>
              Yes
            </Button>{" "}
            <Button color="primary" onClick={() => setOpenDeleteModal(false)}>
              No
            </Button>{" "}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default QuickReply;
