import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { PhotoProvider, PhotoView } from "react-photo-view";
import moment from "moment";
import { Empty } from "antd";
import { debounce } from "lodash";
import MyContext from "MyContext/MyContext";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import StarRatings from "react-star-ratings";
import Moment from "react-moment";
// import { WithContext as ReactTags } from 'react-tag-input';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
} from "reactstrap";
import { Table } from "antd";

import classnames from "classnames";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// emoji
import EmojiPicker from "emoji-picker-react";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import avatar1 from "../../assets/images/users/avatar-1.jpg";

//redux
import Spinners from "Components/Common/Spinner";
import axios from "axios";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import Chat from "./Chat";
import { cl } from "@fullcalendar/core/internal-common";

require("flatpickr/dist/flatpickr.css");
const UnderReviewChat = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [support, setSupport] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [modal_scroll, setmodal_scroll] = useState(false);
  const { socket, CurrentConvo } = useContext(MyContext);
  const [availableSupport, setAvailableSupport] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);

  console.log(search, "search hello");

  const [dateRange, setDateRange] = useState({
    from: "",
    to: "",
    fullDate: "",
  });

  useEffect(() => {
    if (selectedTags.length) {
    }
  }, [selectedTags]);

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions, "selectedOptions");

    setSelectedTags(selectedOptions);
  };
  const UserData = JSON.parse(localStorage.getItem("authUser"));

  console.log(UserData, "UserData");

  //meta title
  document.title = "Chat | Skote - React Admin & Dashboard Template";

  const [isLoading, setLoading] = useState(false); //use loading instead of false

  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars

  const [chats, setChats] = useState<any>([]);
  const [messagesData, setMessagesData] = useState<any>([]);
  const [menu1, setMenu1] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string | number>("1");
  const [Chat_Box_Username, setChat_Box_Username] =
    useState<string>("Steven Franklin");
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] =
    useState<string>("online");
  const [curMessage, setcurMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isdisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);
  const [mainLoading, setMainLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const simpleBarRef = useRef();

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const transfer = (sup: any) => {
    const data = {
      conversationId: messagesData._id,
      newUserId: sup.data._id,
    };

    setmodal_scroll(false);

    console.log("transfer_chats", data);

    if (socket) {
      socket.emit("transfer_chats", data);
    }
  };

  function tog_scroll() {
    if (socket) {
      socket.emit("available_agents");
    }

    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  //Use For Chat Box
  const userChatOpen = (chats: any) => {
    setCurrentRoomId(chats?._id);
    // setMessagesData(chats);
    getFeedbackMessagesHistory(chats);
  };

  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const time: any = `${hours}: ${minutes}`;
  const addMessage = () => {
    if (curMessage !== "" || selectedImage !== null) {
      const newMessage = {
        id: Math.floor(Math.random() * 100),
        to_id: 2,
        msg: curMessage,
        isSameTime: true,
        images: selectedImage,
        time: time,
      };
      setcurMessage("");
      setDisable(false);
      setEmoji(false);
      setSelectedImage(null);
    }
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  // scroll simple bar
  const scroollRef = useRef<any>(null);
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight;
    }
  }, [messagesData]);

  // copy msg
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const copyMsg = (ele: any) => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML;
    navigator.clipboard.writeText(copyText);
    setCopyMsgAlert(true);
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false);
      }, 1000);
    }
  };

  const getFeedbackHistory = async (newOffset) => {
    let filters = {};
    if (selectedTags.length) {
      Object.assign(filters, { support_userId: selectedTags });
    }
    if (dateRange.from != "" && dateRange.to != "") {
      Object.assign(filters, {
        date: { from: dateRange.from, to: dateRange.to },
      });
    }
    if (offset === 0) setMainLoading(true);
    const res = await axios.get(
      `${
        process.env.REACT_APP_APIKEY
      }/api/fetch/all/feedback/conversations/${newOffset}/${JSON.stringify(
        filters
      )}`
    );
    console.log("feedbackHistoryResponse", res);
    if (res.success) {
      setLoading(false);
      if (res.data.length) {
        console.log("condition 1");

        if (newOffset === 0) {
          setCurrentRoomId(res.data[0]?._id);
          setMessagesData((prev) => res.data[0]);
        }

        setChats((prev: any) => {
          if (newOffset === 0) {
            return res.data;
          } else {
            return [...prev, ...res.data];
          }
        });

        if (offset === 0) getFeedbackMessagesHistory(res.data[0]);
      } else if (res.data.length) {
        console.log("condition 2");
        setChats([]);
        setMessagesData([]);
        setCurrentRoomId(1);
      } else if (!res.data.length && offset !== 0) {
        console.log("condition 3");
      } else if (!res.data.length) {
        console.log("condition 4");
        setChats([]);
        setMessagesData([]);
        setCurrentRoomId(1);
      }
      setMainLoading(false);
    } else {
      setLoading(false);
      console.log(res.message);
    }
  };

  console.log("here are cvhats", chats);

  const getFeedbackMessagesHistory = async (covo) => {
    setMessagesLoading(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/messages/${covo.conversationId._id}/${covo.createdAt}`
      );
      setMessagesData(res.data);
      setMessagesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|?]+)/gi;
    return text.replace(urlRegex, function (url) {
      return (
        '<a href="' +
        url +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }

  useEffect(() => {
    setOffset(0);
    getFeedbackHistory(0);
  }, [selectedTags, dateRange, search]);

  useEffect(() => {
    setLoading(true);
    console.log(offset, "offset");
    getFeedbackHistory(offset);
  }, [offset]);

  // useEffect(() => {
  //   getFeedbackMessagesHistory();
  // }, []);

  useEffect(() => {
    // Accessing the SimpleBar instance or its DOM node
    if (simpleBarRef.current) {
      const simpleBarInstance: any = simpleBarRef.current;
      const scrollElement = simpleBarInstance.getScrollElement(); // Get the scrollable element

      // Attach the scroll event listener
      scrollElement.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [simpleBarRef.current]);

  const debouncedHandleScroll = debounce(
    ({ scrollTop, clientHeight, scrollHeight }) => {
      console.log("handleScroll");

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        // Increased threshold
        setOffset((prevOffset) => prevOffset + 15);
      }
    },
    100
  );

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    debouncedHandleScroll({ scrollTop, clientHeight, scrollHeight });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%", // Set the width to 100%
    }),
    menu: (provided) => ({
      ...provided,
      width: "100%", // Set the width to 100%
      backgroundColor: "white", // Set the background color to white
    }),
  };

  const fetchAllSupport = async () => {
    console.log("support data ", "fetchAllSupport");
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/get/all/support`
    );
    if (res.success) {
      const data = [];
      res.data.map((sup) => {
        data.push({
          value: `${sup?._id}`,
          label: `${sup?.name?.firstName} ${sup?.name?.lastName}`,
        });
      });
      console.log("support data underreview", data);
      setSupport(data);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on("agents_available", (data: any) => {
        console.log("all agents", data);
        if (data) {
          setAvailableSupport(data?.agents);
        }
      });

      socket.off("available_agents");
    }
  }, [socket]);

  useEffect(() => {
    fetchAllSupport();
  }, []);

  const markAsIgnored = async () => {
    try {
      console.log("feedback ignore ---->", messagesData.feedbacks);

      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/feedback/ignore/${messagesData.feedbacks._id}`
      );
      if (res.success) {
        console.log(res.data, "feedback ignore", chats);

        let filter = chats;
        const data = filter.filter(
          (c) => c._id.toString() !== res.data._id.toString()
        );

        setChats(data);
        getFeedbackMessagesHistory(data[0]);
        // setMessagesData(filter[0]);
      }
    } catch (error) {
      console.error("Error ignoring feedback:", error.message);
    }
  };

  if (mainLoading) {
    console.log("mainLoading", mainLoading);

    return (
      <div
        className="page-content"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinners />
      </div>
    );
  }

  console.log("messagesData", messagesData);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Chat Support"
            breadcrumbItem="Under Review Chats"
          />
          <Row
            className="justify-content-between"
            style={{ marginBottom: "15px" }}
          >
            <Col lg={5}>
              <div className=" border-bottom mb-2">
                <div className="d-flex mb-2">
                  <div className="align-self-center" style={{ width: "100%" }}>
                    <label> Search By Agents</label>
                    <Select
                      isMulti
                      options={support}
                      value={selectedTags}
                      onChange={handleChange}
                      placeholder="Select Agents to Fetch Their Chats"
                      styles={customStyles} // Apply custom styles
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="align-self-center">
                <label> Search By Date Range</label>
                <Flatpickr
                  className="form-control"
                  value={dateRange.fullDate || ""}
                  placeholder="Please Select a Range To Fetch Chats"
                  options={{
                    mode: "range",
                    dateFormat: "d-m-Y",
                    defaultDate: "today",
                  }}
                  // onChange={(date, dateStr) => {
                  //   setDateRange({
                  //     from: date[0]
                  //       ? moment(date[0]).format("YYYY-MM-DD")
                  //       : "",
                  //     to: date[1]
                  //       ? moment(date[1]).format("YYYY-MM-DD")
                  //       : "",
                  //     fullDate: dateStr,
                  //   });
                  // }}
                  onClose={(date, dateStr) => {
                    setDateRange({
                      from: date[0] ? moment(date[0]).format("YYYY-MM-DD") : "",
                      to: date[1] ? moment(date[1]).format("YYYY-MM-DD") : "",
                      fullDate: dateStr,
                    });
                  }}
                  id="default-picker"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {chats.length > 0 ? (
                <div className="d-lg-flex">
                  <div className="chat-leftsidebar me-lg-4 ">
                    <div>
                      <div className="chat-leftsidebar-nav">
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <div>
                              <ul
                                className="list-unstyled chat-list position-relative"
                                id="recent-list"
                              >
                                <>
                                  <SimpleBar
                                    ref={simpleBarRef}
                                    style={{ height: "590px" }}
                                  >
                                    <div style={{ height: "590px" }}>
                                      {chats.map((chat: any, i) => {
                                        return (
                                          <li
                                            key={i}
                                            className={
                                              currentRoomId === chat?._id
                                                ? "active"
                                                : ""
                                            }
                                            style={{
                                              marginBottom: "5px",
                                            }}
                                          >
                                            <Link
                                              to="#"
                                              onClick={() => userChatOpen(chat)}
                                            >
                                              <div className="d-flex align-items-center">
                                                <div className="align-self-center me-3">
                                                  <i
                                                    className={
                                                      chat.status === "online"
                                                        ? "mdi mdi-circle text-success font-size-10"
                                                        : chat.status ===
                                                          "intermediate"
                                                        ? "mdi mdi-circle text-warning font-size-10"
                                                        : "mdi mdi-circle font-size-10"
                                                    }
                                                  />
                                                </div>
                                                <div
                                                  className="flex-grow-1 overflow-hidden"
                                                  style={{
                                                    width: "65%",
                                                    color:
                                                      !chat?.liked &&
                                                      !chat?.resolved
                                                        ? "#dc3545"
                                                        : chat?.liked ||
                                                          chat?.resolved
                                                        ? "rgb(252, 205, 10)"
                                                        : "",
                                                  }}
                                                >
                                                  <h5 className="text-truncate font-size-14 mb-1">
                                                    {
                                                      chat?.conversationId
                                                        ?.customer_name
                                                    }
                                                  </h5>
                                                  <p className="text-truncate mb-0">
                                                    {
                                                      chat?.conversationId
                                                        ?.customer_email
                                                    }
                                                  </p>
                                                </div>
                                                <div
                                                  className="font-size-10"
                                                  style={{
                                                    textAlign: "end",
                                                    width: "35%",
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      chat?.userId?.profileImage
                                                    }
                                                    alt=""
                                                    style={{ width: "25%" }}
                                                    className="mb-1"
                                                  />
                                                  <p className="mb-0">{`
                                                    ${chat?.userId?.name?.firstName}
                                                    ${chat?.userId?.name?.lastName}
                                                      `}</p>
                                                </div>
                                              </div>
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </div>
                                  </SimpleBar>
                                </>
                              </ul>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </div>
                  <div className="w-100 user-chat">
                    {messagesLoading && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                        }}
                      >
                        <Spinners />
                      </div>
                    )}
                    {!messagesLoading && (
                      <Card>
                        <div className="p-4 border-bottom ">
                          <Row>
                            <Col md={5} xs={10}>
                              <h5 className="font-size-15 mb-1">
                                {messagesData.customer_name}
                              </h5>
                              <h5 className="font-size-15 mb-1">
                                {messagesData.customer_email}
                              </h5>
                              <h5
                                className="font-size-15 mb-1"
                                style={{
                                  color:
                                    messagesData.feedbacks &&
                                    (messagesData.feedbacks.liked ||
                                      messagesData.feedbacks.resolved)
                                      ? "rgb(252, 205, 10)"
                                      : "#dc3545",
                                }}
                              >
                                {messagesData.feedbacks &&
                                (messagesData.feedbacks.liked ||
                                  messagesData.feedbacks.resolved)
                                  ? "Normal"
                                  : "Critical"}
                              </h5>
                            </Col>
                            <Col md={7} xs={2}>
                              <ul className="list-inline user-chat-nav text-end mb-0">
                                <li className="list-inline-item">
                                  <Dropdown
                                    isOpen={other_Menu}
                                    toggle={toggleOther}
                                  >
                                    <DropdownToggle
                                      className="btn nav-btn"
                                      tag="a"
                                    >
                                      <i className="bx bx-dots-horizontal-rounded" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem onClick={markAsIgnored}>
                                        Feedback Ignore
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          <div className="chat-conversation p-3">
                            <SimpleBar
                              ref={scroollRef}
                              style={{ height: "470px" }}
                            >
                              <ul
                                className="list-unstyled"
                                id="users-conversation"
                              >
                                <React.Fragment>
                                  {messagesData?.messages?.map(
                                    (userMsg, index: number) => {
                                      return !userMsg.ended ? (
                                        userMsg.file === true ? (
                                          <div key={index}>
                                            <PhotoProvider>
                                              <PhotoView
                                                src={userMsg.file_path}
                                              >
                                                <img
                                                  className={
                                                    userMsg.support
                                                      ? "conversation-list"
                                                      : "conversation-list right"
                                                  }
                                                  style={
                                                    userMsg.support
                                                      ? {
                                                          width: "300px",
                                                          float: "right",
                                                        }
                                                      : { width: "300px" }
                                                  }
                                                  src={userMsg.file_path}
                                                  alt=""
                                                />
                                              </PhotoView>
                                            </PhotoProvider>
                                          </div>
                                        ) : (
                                          <li
                                            key={index}
                                            className={
                                              userMsg.support ? "right" : "left"
                                            }
                                          >
                                            <div
                                              className="conversation-list"
                                              dir={
                                                CurrentConvo?.language ===
                                                "arabic"
                                                  ? "rtl"
                                                  : "ltr"
                                              }
                                            >
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  href="#!"
                                                  tag="a"
                                                  className="dropdown-toggle"
                                                >
                                                  <i className="bx bx-dots-vertical-rounded" />
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem
                                                    onClick={(e) =>
                                                      copyMsg(e.target)
                                                    }
                                                    href="#"
                                                  >
                                                    Copy
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                              <div
                                                className="ctext-wrap"
                                                dir={
                                                  CurrentConvo?.language ===
                                                  "arabic"
                                                    ? "rtl"
                                                    : "ltr"
                                                }
                                              >
                                                <div className="conversation-name">
                                                  {userMsg.support
                                                    ? userMsg.supportName
                                                      ? userMsg.supportName
                                                      : messagesData.support_name
                                                    : messagesData.customer_name}
                                                </div>
                                                <p
                                                  dir={
                                                    CurrentConvo?.language ===
                                                    "arabic"
                                                      ? "rtl"
                                                      : "ltr"
                                                  }
                                                  dangerouslySetInnerHTML={{
                                                    __html: linkify(
                                                      userMsg.body
                                                    ),
                                                  }}
                                                ></p>
                                                <div
                                                  className="d-flex justify-content-between"
                                                  style={{ gap: "17px" }}
                                                >
                                                  <span className="chat-time mb-0">
                                                    <i className="bx bx-calendar align-middle me-1"></i>
                                                    {moment(
                                                      messagesData.feedbacks
                                                        .createdAt
                                                    ).format("Do MMMM YYYY")}
                                                  </span>
                                                  <span className="chat-time mb-0">
                                                    <i className="bx bx-time-five align-middle me-1"></i>
                                                    {moment(
                                                      userMsg.createdOn
                                                    ).format("hh:mmA")}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      ) : (
                                        <li>
                                          <div className="chat-day-title">
                                            <span className="title">
                                              {userMsg.endedBy}{" "}
                                              {userMsg.createdOn && (
                                                <Moment
                                                  date={userMsg.createdOn}
                                                  format="Do MMMM YYYY h:mm a"
                                                />
                                              )}
                                            </span>
                                          </div>
                                        </li>
                                      );
                                    }
                                  )}
                                  {!messagesData && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%",
                                      }}
                                    >
                                      <p> No Chats Available To Display </p>
                                    </div>
                                  )}
                                  {messagesData?.feedbacks && (
                                    <form
                                      className="start-chat-form position-relative container-fluid d-flex flex-column  align-items-center  my-5  rounded p-3"
                                      style={{ maxWidth: "85%" }}
                                    >
                                      <p
                                        className="p-0 my-1 text-center"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        You Got Rating
                                      </p>
                                      <div
                                        className="d-flex justify-content-center"
                                        style={{ gap: "6px" }}
                                      >
                                        <i
                                          className="bx bxs-like"
                                          style={{
                                            color:
                                              messagesData?.feedbacks?.liked ===
                                              true
                                                ? "#fccd0a"
                                                : "",
                                            fontSize: "20px",
                                          }}
                                        ></i>
                                        <i
                                          className="bx bxs-dislike"
                                          style={{
                                            color:
                                              messagesData?.feedbacks?.liked ===
                                              false
                                                ? "#fccd0a"
                                                : "",
                                            fontSize: "20px",
                                          }}
                                        ></i>
                                      </div>
                                      <p
                                        className="text-left mt-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Was the case resolved during the chat?
                                      </p>
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            marginTop: -15,
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="resolved"
                                            id="resolved"
                                            disabled
                                            checked={
                                              messagesData?.feedbacks?.resolved
                                                ? true
                                                : false
                                            }
                                            style={{ width: "30px" }}
                                          />

                                          <label
                                            htmlFor="resolved"
                                            className="mt-2"
                                            style={{ color: "rgb(63,63,63)" }}
                                          >
                                            {" "}
                                            Yes{" "}
                                          </label>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            marginTop: -15,
                                          }}
                                        >
                                          <input
                                            type="radio"
                                            name="resolved"
                                            id="resolved"
                                            disabled
                                            checked={
                                              !messagesData?.feedbacks?.resolved
                                                ? true
                                                : false
                                            }
                                            style={{ width: "30px" }}
                                          />
                                          <label
                                            htmlFor="resolved"
                                            className="mt-2"
                                            style={{ color: "rgb(63,63,63)" }}
                                          >
                                            {" "}
                                            No{" "}
                                          </label>
                                        </div>
                                        {messagesData?.feedbacks?.comment && (
                                          <div
                                            style={{
                                              width: "100%",
                                              alignItems: "center",
                                              justifyContent: "flex-start",
                                            }}
                                          >
                                            <label
                                              className="p-0 my-1 text-center"
                                              style={{
                                                fontWeight: "bold",
                                                display: "block",
                                              }}
                                            >
                                              Comment
                                            </label>
                                            <p>
                                              {messagesData?.feedbacks?.comment}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </form>
                                  )}
                                </React.Fragment>
                              </ul>
                            </SimpleBar>
                          </div>
                          {selectedImage && (
                            <div className="replymessage-block mb-0 d-flex align-items-start">
                              <div className="flex-grow-1">
                                <img
                                  src={selectedImage}
                                  alt="select img"
                                  style={{ width: "150px", height: "auto" }}
                                />
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                  type="button"
                                  id="close_toggle"
                                  className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                  onClick={() => setSelectedImage(null)}
                                >
                                  <i className="bx bx-x align-middle"></i>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </Card>
                    )}
                  </div>
                </div>
              ) : (
                <Empty style={{ marginTop: "60px" }} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UnderReviewChat;
