import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Result } from "antd";
import dayjs from "dayjs";
import { Popover } from "antd";

import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import SimpleBar from "simplebar-react";
import { debounce } from "lodash";
import Breadcrumbs from "../../Components/Common/Breadcrumb";

//import Images
import verification from "../../assets/images/verification-img.png";

//redux
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";

const Booking = (props: any) => {
  document.title = "Bookings | PrimeX ";
  const UserData = JSON.parse(localStorage.getItem("authUser"));

  const [event, setEvent] = useState<any>({});
  const [scheduleDate, setScheduleDate] = useState<any>(null);

  const handleScheduleDate = (date, datestring) => {
    console.log(date, datestring, "date", "datestring");
    setScheduleDate(date);
  };

  // const categoryValidation: any = useFormik({
  //   enableReinitialize: true,

  //   initialValues: {
  //     title: (event && event.fullName) || "",
  //     start: (event && event.scheduleDate) || "",
  //     rejectDate: (event && event.rejectionDate) || "",
  //     createdOn: (event && event.createdOn) || "",
  //   },
  //   validationSchema: Yup.object({
  //     title: Yup.string().required("Please Enter Your Event Name"),
  //     start: Yup.date(),
  //     rejectDate: Yup.date(),
  //     createdOn: Yup.date(),
  //   }),
  //   onSubmit: async (values) => {
  //     if (isEdit) {
  //       const updateEvent = {
  //         _id: event.id,
  //         userId: UserData.user._id,
  //         email: UserData.user.email,
  //       };

  //       console.log("values", updateEvent);
  //       const res = await axios.post(
  //         `${process.env.REACT_APP_APIKEY}/api/admin/accept/event`,
  //         updateEvent
  //       );
  //       console.log(res, "success");

  //       if (res.success) {
  //         console.log("res.success");
  //       }
  //       categoryValidation.resetForm();
  //       fetchEvents();
  //     }
  //     toggle();
  //   },
  // });

  const [events, setEvents] = useState<any>([]);

  const fetchEvents = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/bookings`
    );
    console.log(res, "rescolor");
    if (res.success) {
      const sortedData = res.data.map((event) => {
        let classNames;
        const now = moment();
        const meetTimeMoment = moment(event.scheduleDate);
        const fifteenMinutesFromNow = moment(event.scheduleDate).add(
          15,
          "minutes"
        );
        if (event.status.accept) {
          classNames = "bg-secondary text-white";
        }
        if (event.status.accept && moment() > moment(event.scheduleDate)) {
          classNames = "bg-success text-white";
        }
        if (
          now.isSameOrAfter(meetTimeMoment) &&
          now.isBefore(fifteenMinutesFromNow)
        ) {
          classNames = "bg-warning text-white";
        }
        if (event.status.reject) {
          classNames = "bg-danger text-white";
        }

        return {
          id: event._id,
          title: event.fullName,
          start: event.status.reject ? event.rejectionDate : event.scheduleDate,
          className: classNames,
          accept: event.status.accept,
          reject: event.status.reject,
          email: event.email,
          createdOn: event.createdOn,
        };
      });

      console.log(sortedData, "sortedData");

      setEvents(sortedData);
    }
  };

  const renderEventContent = (eventInfo) => {
    const startTime = moment(eventInfo.event.start).format("h:mma");
    const createdOnFormatted = moment(
      eventInfo.event.extendedProps.createdOn
    ).format("DD MMMM YYYY hh:mm a");
    const content = (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <div>{`Email: ${eventInfo.event.extendedProps.email}`}</div>
          <div>{`CreatedOn: ${createdOnFormatted}`}</div>
        </div>
      </div>
    );
    return (
      <Popover content={content} trigger="hover">
        <div className="d-flex align-items-center">
          <div className="fc-daygrid-event-dot"></div>
          <div className="fc-event-time">{startTime}</div>
          <div className="fc-event-title">{eventInfo.event.title}</div>
        </div>
      </Popover>
    );
  };

  console.log(events, "events");

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modalcategory, setModalcategory] = useState<boolean>(false);
  const [modalBooking, setModalBooking] = useState<boolean>(false);
  const [bookings, setBookings] = useState([]);
  const [currentBooking, setCurrentBooking] = useState(null);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionDate, setRejectionDate] = useState(new Date());
  const [selectedReason, setSelectedReason] = useState("");
  const [showResult, setShowResult] = useState<boolean>(false);
  const [errorResult, setErrorResult] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [rejectionResult, setRejectionResult] = useState<boolean>(false);
  const [rejectionErrorResult, setRejectionErrorResult] =
    useState<boolean>(false);
  const [rejectionLoading, setRejectionLoading] = useState(false);
  const simpleBarRef = useRef();
  const [offset, setOffset] = useState<number>(0);
  const [deleteId, setDeleteId] = useState();
  const [selectedDay, setSelectedDay] = useState<any>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([
    {
      id: 1,
      title: "Done",
      type: "bg-success",
    },
    {
      id: 2,
      title: "Scheduled and Accepted",
      type: "bg-secondary",
    },
    {
      id: 3,
      title: "In Progress",
      type: "bg-warning",
    },
    {
      id: 4,
      title: "Rejected",
      type: "bg-danger",
    },
  ]);

  useEffect(() => {
    // Accessing the SimpleBar instance or its DOM node
    if (simpleBarRef.current) {
      const simpleBarInstance: any = simpleBarRef.current;
      const scrollElement = simpleBarInstance.getScrollElement(); // Get the scrollable element

      // Attach the scroll event listener
      scrollElement.addEventListener("scroll", handleScroll);

      // Cleanup function to remove the event listener
      return () => {
        scrollElement.removeEventListener("scroll", handleScroll);
      };
    }
  }, [simpleBarRef.current]);

  const debouncedHandleScroll = debounce(
    ({ scrollTop, clientHeight, scrollHeight }) => {
      console.log("handleScroll");

      if (scrollHeight - scrollTop <= clientHeight + 50) {
        // Increased threshold
        setOffset((prevOffset) => prevOffset + 15);
      }
    },
    100
  );

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    debouncedHandleScroll({ scrollTop, clientHeight, scrollHeight });
  };

  const toggle = () => {
    if (modalcategory) {
      setModalcategory(false);
      setEvent(null);
      setIsEdit(false);
    } else {
      setModalcategory(true);
    }
  };

  const bookingToggle = () => {
    if (modalBooking) {
      setModalBooking(false);
    } else {
      setModalBooking(true);
    }
  };

  // const handleEventClick = (arg: any) => {
  //   const event = arg.event;
  //   const eventClassNames = event.classNames;
  //   if (eventClassNames.includes("bg-secondary")) {
  //     setEvent({
  //       id: event.id,
  //       title: event.fullName,
  //       accept: event.status.accept,
  //       reject: event.status.reject,
  //       title_category: event.title_category,
  //       start: event.scheduleDate,
  //       rejectDate: event.rejectionDate,
  //       className: event.classNames,
  //       category: event.classNames[0],
  //       event_category: event.classNames[0],
  //     });
  //     setDeleteId(event.id);
  //     setIsEdit(true);
  //     setModalcategory(true);
  //     toggle();
  //   }
  // };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchPendingBookings();
  }, []);

  const fetchPendingBookings = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/pending-bookings`
      );
      setBookings(response);
    } catch (error) {
      console.error("Error fetching pending bookings:", error);
    }
  };

  const handleAccept = async (booking) => {
    setCurrentBooking(booking);
    setModalBooking(true);
    setShowResult(false);
    setErrorResult(false);
  };

  const reasons = [
    {
      label: "Meeting Request Declined Due to Maximum Allowance Reached",
      email: `Dear Client,\n\nWe hope this email finds you well.\n\nThank you for reaching out to PrimeX Broker Academy with your request. \n\nAfter careful consideration, we regret to inform you that we are unable to book you a meeting at this time.\n\nWe truly appreciate your interest in our services, and we understand the importance of your request. However, due to your allowance which is 1 meeting and you have used it, we are unable to proceed with your request.\n\nThank you for your understanding and continued support.\n\nBest Regards,\nPrimeX Broker (PTY) LTD`,
    },
    {
      label: "Unable to Accommodate Additional Meetings",
      email: `Dear Client,\n\nWe hope this email finds you well.\n\nThank you for reaching out to PrimeX Broker Academy with your request. \n\nAfter careful consideration, we regret to inform you that we are unable to book you a meeting at this time.\n\nWe truly appreciate your interest in our services, and we understand the importance of your request. However, due to our staff they are currently occupied with ongoing meetings, we are unable to proceed with your request.\n\nThank you for your understanding and continued support.\n\nBest Regards,\nPrimeX Broker (PTY) LTD`,
    },
    {
      label: "Meeting Request Unavailable Due to Staff Scheduling",
      email: `Dear Client,\n\nWe hope this email finds you well.\n\nThank you for reaching out to PrimeX Broker Academy with your request. \n\nAfter careful consideration, we regret to inform you that we are unable to book you a meeting at this time.\n\nWe truly appreciate your interest in our services, and we understand the importance of your request. However, due to your allowance which is 4 meetings and you have used them, we are unable to proceed with your request.\n\nThank you for your understanding and continued support.\n\nBest Regards,\nPrimeX Broker (PTY) LTD`,
    },
    {
      label: "Meeting Request Declined Due to Insufficient Account Balance",
      email: `Dear Client,\n\nWe hope this email finds you well.\n\nThank you for reaching out to PrimeX Broker Academy with your request. \n\nAfter careful consideration, we regret to inform you that we are unable to book you a meeting at this time.\n\nWe truly appreciate your interest in our services, and we understand the importance of your request. However, due to your low account balance, we are unable to proceed with your request.\n\nThank you for your understanding and continued support.\n\nBest Regards,\nPrimeX Broker (PTY) LTD`,
    },
  ];

  const handleReasonChange = (event) => {
    const reason = event.target.value;
    setSelectedReason(reason);
    setRejectionReason(reasons.find((r) => r.label === reason).email);
  };

  const handleReject = (booking) => {
    setCurrentBooking(booking);
    setRejectionModal(true);
    setRejectionResult(false);
    setRejectionErrorResult(false);
    setRejectionReason("");
    setSelectedReason("");
  };

  const submitRejection = async (e) => {
    e.preventDefault();
    setRejectionLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/reject-booking/${currentBooking._id}`,
        { rejectionReason, rejectionDate }
      );
      if (response?.success) {
        setRejectionLoading(false);
        setRejectionResult(true);
        setTimeout(() => {
          setRejectionModal(false);
        }, 3000);
        fetchPendingBookings();
        fetchEvents();
      } else {
        setRejectionLoading(false);
        setRejectionResult(false);
        setRejectionErrorResult(true);
      }
    } catch (error) {
      console.error("Error submitting rejection:", error);
      setRejectionLoading(false);
      setRejectionErrorResult(true);
    }
  };

  const confirmAccept = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/accept-booking/${currentBooking._id}/${scheduleDate}`
      );
      if (response?.success) {
        setLoading(false);
        setShowResult(true);
        setTimeout(() => {
          setModalBooking(false);
        }, 3000);
        fetchPendingBookings();
        fetchEvents();
      } else {
        setLoading(false);
        setShowResult(false);
        setErrorResult(true);
      }
    } catch (error) {
      console.error("Error accepting booking:", error);
      setLoading(false);
      setErrorResult(true);
    }
  };

  useEffect(() => {
    const textarea = document.getElementById("rejectionReason");
    if (textarea) {
      textarea.style.height = "40px";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [selectedReason]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Booking" breadcrumbItem="Full Calendar" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col lg={3}>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-2">
                        <br />
                        <p className="text-muted">
                          Listed colors respresent the current status
                        </p>
                        {categories &&
                          (categories || []).map((category: any) => (
                            <div
                              className={`${category.type}  fc-event text-white`}
                              key={"cat-" + category.id}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>

                      <Row className="justify-content-center mt-5">
                        {bookings.length > 0 ? (
                          <SimpleBar
                            ref={simpleBarRef}
                            style={{ height: "425px" }}
                          >
                            {bookings.map((booking) => (
                              <Card
                                style={{ backgroundColor: "#f6f6f6" }}
                                className="shadow"
                              >
                                <CardBody>
                                  <h5 className="card-title text-center">
                                    Pending
                                  </h5>
                                  <div className="mb-2">
                                    <Label
                                      className="form-label fw-bold"
                                      style={{ marginBottom: "1px" }}
                                    >
                                      Name
                                    </Label>
                                    <p className="mb-0 text-muted">
                                      {booking.fullName}
                                    </p>{" "}
                                  </div>
                                  <div className="mb-2">
                                    <Label
                                      className="form-label fw-bold"
                                      style={{ marginBottom: "1px" }}
                                    >
                                      Email
                                    </Label>
                                    <p className="mb-0 text-muted">
                                      {booking.email}
                                    </p>
                                  </div>
                                  <div className="mb-2">
                                    <Label
                                      className="form-label fw-bold"
                                      style={{ marginBottom: "1px" }}
                                    >
                                      Phone Number
                                    </Label>
                                    <p className="mb-0 text-muted">
                                      {booking.phoneNumber}
                                    </p>
                                  </div>
                                  <div className="mb-2">
                                    <Label
                                      className="form-label fw-bold"
                                      style={{ marginBottom: "1px" }}
                                    >
                                      Account Id
                                    </Label>
                                    <p className="mb-0 text-muted">
                                      {booking.accountId}
                                    </p>
                                  </div>
                                  <div className="mb-2">
                                    <Label
                                      className="form-label fw-bold"
                                      style={{ marginBottom: "1px" }}
                                    >
                                      Availability
                                    </Label>
                                    <p className="mb-0 text-muted">
                                      {booking.time}
                                    </p>
                                  </div>
                                  <Row className="mt-3">
                                    <Col className="text-center">
                                      <button
                                        className="btn btn-danger me-2"
                                        onClick={() => handleReject(booking)}
                                      >
                                        Reject
                                      </button>
                                      <button
                                        className="btn btn-primary"
                                        onClick={() => handleAccept(booking)}
                                      >
                                        Accept
                                      </button>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            ))}
                          </SimpleBar>
                        ) : (
                          <img
                            src={verification}
                            alt=""
                            className="img-fluid d-block"
                          />
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                  <Modal
                    isOpen={modalBooking}
                    toggle={() => setModalBooking(false)}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={bookingToggle} tag="h5">
                      Accept Booking
                    </ModalHeader>
                    {showResult ? (
                      <Result
                        status="success"
                        title="Form Submitted Successfully"
                      />
                    ) : errorResult ? (
                      <Result status="error" title="Submission Failed" />
                    ) : (
                      <ModalBody className="p-4">
                        {currentBooking && (
                          <Form>
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DemoContainer
                                      components={["DateTimePicker"]}
                                    >
                                      <DateTimePicker
                                        label="Start Date"
                                        onChange={handleScheduleDate}
                                        minDateTime={dayjs()}
                                      />
                                    </DemoContainer>
                                  </LocalizationProvider>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <FormGroup className="mb-3">
                                <Label for="name">Name</Label>
                                <Input
                                  type="text"
                                  id="name"
                                  readOnly
                                  value={currentBooking.fullName}
                                />
                              </FormGroup>
                              <FormGroup className="mb-3">
                                <Label for="email">Email</Label>
                                <Input
                                  type="email"
                                  id="email"
                                  readOnly
                                  value={currentBooking.email}
                                />
                              </FormGroup>
                              <FormGroup className="mb-3">
                                <Label for="phoneNumber">Phone Number</Label>
                                <Input
                                  type="text"
                                  id="phoneNumber"
                                  readOnly
                                  value={currentBooking.phoneNumber}
                                />
                              </FormGroup>
                              <FormGroup className="mb-3">
                                <Label for="accountId">Account ID</Label>
                                <Input
                                  type="text"
                                  id="accountId"
                                  readOnly
                                  value={currentBooking.accountId}
                                />
                              </FormGroup>
                            </Row>
                            <Row className="mt-2">
                              <Col className="col-6">
                                <button
                                  type="button"
                                  className="btn btn-light me-1"
                                  onClick={() => setModalBooking(false)}
                                >
                                  Close
                                </button>
                              </Col>

                              <Col className="col-6 text-end">
                                <button
                                  type="button"
                                  disabled={!loading ? false : true}
                                  className="btn btn-success"
                                  id="btn-save-booking"
                                  onClick={(e) => confirmAccept(e)}
                                >
                                  {loading ? (
                                    <>
                                      <Spinner size="sm" />
                                      <span className="ms-2">Confirm</span>
                                    </>
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </ModalBody>
                    )}
                  </Modal>
                  <Modal
                    isOpen={rejectionModal}
                    toggle={() => setRejectionModal(false)}
                    centered
                  >
                    <ModalHeader toggle={() => setRejectionModal(false)}>
                      Reject Booking
                    </ModalHeader>
                    {rejectionResult ? (
                      <Result
                        status="success"
                        title="Form Submitted Successfully"
                      />
                    ) : rejectionErrorResult ? (
                      <Result status="error" title="Submission Failed" />
                    ) : (
                      <ModalBody>
                        <Form onSubmit={submitRejection}>
                          <FormGroup>
                            <Label for="reason">Rejection Reason</Label>
                            <Input
                              type="select"
                              id="reason"
                              value={selectedReason}
                              onChange={handleReasonChange}
                            >
                              <option value="">Select a reason</option>
                              {reasons.map((option, index) => (
                                <option key={index} value={option.label}>
                                  {option.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                          <FormGroup>
                            <Label for="rejectionReason">Detailed Reason</Label>
                            <Input
                              type="textarea"
                              id="rejectionReason"
                              value={rejectionReason}
                              onChange={(e) =>
                                setRejectionReason(e.target.value)
                              }
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="rejectionDate">Rejection Date</Label>
                            <Input
                              type="date"
                              id="rejectionDate"
                              value={rejectionDate
                                .toISOString()
                                .substring(0, 10)}
                              onChange={(e) =>
                                setRejectionDate(new Date(e.target.value))
                              }
                              required
                              readOnly
                            />
                          </FormGroup>
                          <Button
                            type="submit"
                            disabled={!rejectionLoading ? false : true}
                            color="primary"
                          >
                            {rejectionLoading ? (
                              <>
                                <Spinner size="sm" />
                                <span className="ms-2">Submit</span>
                              </>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        </Form>
                      </ModalBody>
                    )}
                  </Modal>
                </Col>
                <Col className="col-lg-9">
                  {/* fullcalendar control */}
                  <FullCalendar
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      listPlugin,
                      interactionPlugin,
                    ]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    }}
                    events={events}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    // eventClick={handleEventClick}
                    eventContent={renderEventContent}
                  />

                  {/* New/Edit event modal */}
                  {/* <Modal
                    isOpen={modalcategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? "Accept Meeting" : "Add Event"}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          categoryValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Meeting Title
                              </Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Insert Event Name"
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.title || ""}
                                invalid={
                                  categoryValidation.touched.title &&
                                  categoryValidation.errors.title
                                    ? true
                                    : false
                                }
                                readOnly
                              />
                              {categoryValidation.touched.title &&
                              categoryValidation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="mb-3">
                              <Label className="form-label">Meeting Time</Label>
                              <div className="text-left px-2">
                                <Moment
                                  date={categoryValidation.values.start}
                                  format="Do MMMM YYYY h:mm a"
                                  style={{
                                    textAlign: "center",
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="mb-3">
                              <Label className="form-label">Created At</Label>
                              <div className="text-left px-2">
                                <Moment
                                  date={categoryValidation.values.createdOn}
                                  format="Do MMMM YYYY h:mm a"
                                  style={{
                                    textAlign: "center",
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col className="col-6">
                            <button
                              type="button"
                              className="btn btn-light me-1"
                              onClick={toggle}
                            >
                              Close
                            </button>
                          </Col>

                          <Col className="col-6 text-end">
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="btn-save-event"
                            >
                              Accept
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Booking;
