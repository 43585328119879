import { Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Reports from "../pages/reports";
import TeamReports from "../pages/reports/TeamReports";
import ActiveTime from "../pages/reports/ActiveTime";
import Calendar from "../pages/Calendar/index";
import GoogleCalendar from "../pages/meetings/GoogleCalender";
import LiveChat from "../pages/Chat/LiveChat";
import ChatHistory from "../pages/Chat/ChatHistory";
import MyChats from "../pages/Chat/MyChats";
import CurrentChats from "../pages/Chat/CurrentChats";
import QuickReply from "pages/QuickReply/QuickReply";
import Tickets from "../pages/Tickets/Tickets";
import ResolvedTickets from "../pages/Tickets/ResolvedTickets";
import Team from "pages/Team/Team";
import BlogList from "../pages/Blog/BlogList/index";
import BlogGrid from "../pages/Blog/BlogGrid/index";
import BlogDetails from "../pages/Blog/BlogDetails";
import AddBlog from "../pages/Blog/AddBlog";
import Lessons from "../pages/Education/lessons";
import Article from "../pages/Articles/Article";
import ArticleDetails from "pages/Articles/ArticleDetail";
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register";
import WebsiteFeedback from "pages/WebsiteFeedback/WebsiteFeedback";
import UnderReviewChat from "pages/Chat/UnderReviewChat";
import Meeting from "pages/meetings/meeting";
import WebinarCalender from "pages/Webinars/WebinarCalender";
import MarketNewsList from "pages/MarketNews/MarketNewsList/MarketNewsList";
import MarketNewsGridIndex from "pages/MarketNews/MarketNewsGrid/MarketNewsGridIndex";
import MarketNewsDetails from "pages/MarketNews/MarketNewsDetails";
import AddMarketNews from "pages/MarketNews/AddMarketNews";
import Booking from "pages/Booking/booking";
import JobList from "../pages/JobPages/JobList";
import JobDetails from "../pages/JobPages/JobDetails";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/live-chat", component: <LiveChat /> },
  { path: "/google-calender", component: <GoogleCalendar /> },
  { path: "/webinar", component: <WebinarCalender /> },
  { path: "/chat-history", component: <ChatHistory /> },
  { path: "/my-chats", component: <MyChats /> },
  { path: "/current-chats", component: <CurrentChats /> },
  { path: "/quick-reply", component: <QuickReply /> },
  { path: "/tickets", component: <Tickets /> },
  { path: "/resolved/tickets", component: <ResolvedTickets /> },

  { path: "/report", component: <Reports /> },
  { path: "/team-reports", component: <TeamReports /> },
  { path: "/active-time", component: <ActiveTime /> },

  { path: "/support-team", component: <Team /> },
  { path: "/website-feedbacks", component: <WebsiteFeedback /> },
  { path: "/review-chats", component: <UnderReviewChat /> },
  { path: "/meeting", component: <Meeting /> },
  { path: "/booking", component: <Booking /> },
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-details/:id", component: <BlogDetails /> },
  { path: "/add-blog", component: <AddBlog /> },
  { path: "/articles", component: <Article /> },
  { path: "/market-news-list", component: <MarketNewsList /> },
  { path: "/market-news-grid", component: <MarketNewsGridIndex /> },
  { path: "/market-news-details/:id", component: <MarketNewsDetails /> },
  { path: "/add-market-news", component: <AddMarketNews /> },
  { path: "/article-details/:id", component: <ArticleDetails /> },
  { path: "/educations", component: <Lessons /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/job-list", component: <JobList /> },
  { path: "/job-details/:id", component: <JobDetails /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
];
export { authProtectedRoutes, publicRoutes };
