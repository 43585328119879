import React from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
  } from "reactstrap";
const GoogleCalender = (props: any) => {

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <iframe src="https://calendar.google.com/calendar/embed?src=support%40primexbroker.com&ctz=Asia%2FBaghdad"
                        style={{ border: 0, width: "100%", height: "100vh" }}
                    //   height="600" frameborder="0" scrolling="no"
                    >

                    </iframe>
                </Container>
            </div>
        </React.Fragment>
    );
};


export default GoogleCalender;