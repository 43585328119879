// actions.ts
export const setNSocket = (socket: any) => {
  console.log("In SetNSocket")
    return {
      type: 'SET_SOCKET',
      payload: socket,
    };
  };
  
  // reducers.ts
  interface SocketState {
    socket: any;
  }
  
  const initialState: SocketState = {
    socket: null,
  };
  
  const socketReducer = (state: SocketState = initialState, action: any) => {
    switch (action.type) {
      case 'SET_SOCKET':
        return {
          ...state,
          socket: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default socketReducer;
  