import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { isEmpty, set } from "lodash";
import NoChat from "./NoChat";
import moment from "moment";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  UncontrolledPopover,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
} from "reactstrap";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Timer from "react-time-counter";
import MyContext from "MyContext/MyContext";
import classnames from "classnames";

//Import Scrollbar
import SimpleBar from "simplebar-react";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

// emoji
import EmojiPicker from "emoji-picker-react";
import NoChatImage from "../../assets/images/liveChat/Done-rafiki.png";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
//Import msg Sound Effect
// import notificationSound from "../../../public"
import {
  getChats as onGetChats,
  getGroups as onGetGroups,
  getContacts as onGetContacts,
  getMessages as onGetMessages,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage,
} from "../../slices/chats/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Spinners from "Components/Common/Spinner";
import axios from "axios";
import { color } from "echarts";

interface Message {
  id: number;
  to_id: number;
  msg: string;
  isSameTime: boolean;
  images: string;
  time: number | string;
}

interface messageType {
  id: number;
  roomId: number;
  sender: string;
  message: string;
  isToday: string;
  usermessages: Message[];
}

const LiveChat = () => {
  const {
    socket,
    setSocket,
    peoplesQueue,
    conversations,
    setConversations,
    acceptChat,
    setAcceptChat,
    notifySound,
    setNotifySound,
    CurrentConvo,
    setCurrentConvo,
    removeId,
    setRemoveId,
    accessChat,
    setAccessChat,
    shift,
  } = useContext(MyContext);
  console.log(shift, "shift");

  //play notification on message recived

  const UserData = JSON.parse(localStorage.getItem("authUser"));

  //meta title
  document.title = "PrimeX Admin Panel Live Chat ";
  //console.log("This Is the Socket In Live Chat", socket)
  const dispatch = useDispatch<any>();
  const selectProperties = createSelector(
    (state: any) => state.chats,
    (chats) => ({
      chats: chats.chats,
      groups: chats.groups,
      contacts: chats.contacts,
      messages: chats.messages,
      loading: chats.loading,
    })
  );

  const { chats, groups, contacts, messages, loading } =
    useSelector(selectProperties);

  const messagesEndRef = useRef(null);
  const messageBox: any = null;
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars

  const [isLoading, setLoading] = useState(false); //use loading instead of false
  const [OpenQuickMsg, setOpenQuickMsg] = useState(false);
  const [QuickMessages, setQuickMessages] = useState<any>([]);
  const [menu1, setMenu1] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string | number>("1");
  const [Chat_Box_Username, setChat_Box_Username] =
    useState<string>("Steven Franklin");
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] =
    useState<string>("online");
  const [curMessage, setcurMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isdisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);
  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const time: any = `${hours}: ${minutes}`;
  const [messageBoxText, setMessageBoxText] = useState<any>({});
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [sound, setSound] = useState(true);
  const [support, setSupport] = useState<any>([]);
  const [filteredQuickMessages, setfilteredQuickMessages] = useState([]);
  const [disable, setDsisable] = useState(false);
  const [search, setSearch] = useState<any>("");
  const [images, setImages] = useState<any>(null);
  const [IsEditModal, setIsEditModal] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editedMessage, setEditedMessage] = useState<any>({});
  const [editedMessageBody, setEditedMessageBody] = useState();
  const [activity, setActivity] = useState({});
  const [activityTime, setActivityTime] = useState(0);
  const [activityHours, setHours] = useState(0);
  const [activityMinutes, setMinutes] = useState(0);
  const [activitySeconds, setSeconds] = useState(0);

  function convertMillisecondsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000); // Convert milliseconds to seconds
    let minutes = Math.floor(seconds / 60); // Convert seconds to minutes
    let hours = Math.floor(minutes / 60); // Convert minutes to hours

    seconds = seconds % 60; // Remaining seconds
    minutes = minutes % 60;
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);
    // Remaining minutes

    // return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }

  const getActivityTime = async () => {
    if (shift?._id) {
      const res = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/user/activity/time/${shift?._id}`
      );
      if (res.success) {
        convertMillisecondsToTime(res.data);
        setActivityTime(res.data);
      }
    }
  };

  useEffect(() => {
    getActivityTime();
  }, [activity, shift, acceptChat]);

  useEffect(() => {}, [activity]);

  function tog_scroll() {
    socket.emit("available_agents");
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }

  useEffect(() => {
    if (socket) {
      socket.on("agents_available", (data: any) => {
        console.log("all agents", data);
        if (data) {
          setSupport(data?.agents);
        }
      });

      socket.off("available_agents");
      // socket.off("access_user_chat");
    }
  }, [socket]);

  const transfer = (sup: any) => {
    const data = {
      conversationId: CurrentConvo._id,
      newUserId: sup.data._id,
    };

    setmodal_scroll(false);

    console.log("transfer_chats", data);

    if (socket) {
      socket.emit("transfer_chats", data);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetGroups());
    dispatch(onGetContacts());
    dispatch(onGetMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };
  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  //Use For Chat Box
  const userChatOpen = (chats: any) => {
    setChat_Box_Username(chats?.customer_name);
    setCurrentRoomId(chats?._id);
    chats.unread = false;
    chats.seen = true;
    setCurrentConvo(conversations?.find((item) => item._id === chats?._id));
    setChat_Box_User_Status(chats.status);
    dispatch(onGetMessages(chats.roomId));
    socket.emit("msgSeenStatus", chats);
  };

  const refreshChat = () => {
    if (currentRoomId) {
      setCurrentConvo(
        conversations?.find((item) => item?._id === currentRoomId)
      );
    }
  };

  useEffect(() => {
    refreshChat();
  }, [conversations]);

  const getLastMessages = (chat: any) => {
    //console.log("Convo  Chat Id", id)
    if (chat) {
      if (!chat?.messages) {
        return `${CurrentConvo.customer_name} has joined Chat`;
      }
      const lastmsg = chat?.messages[chat.messages.length - 1]?.body;
      return lastmsg;
    }
  };

  const sendMessage = () => {
    if (images) {
      const file = images;
      let _id = "1";
      console.log(file);
      const formData = new FormData();
      formData.append("files[]", file);
      formData.append("action", "attachments");
      formData.append("_id", _id);

      axios
        .post(`${process.env.REACT_APP_APIKEY}/api/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((data) => {
          setImages(null);
          setSelectedImage(null);
          console.log(data.result.file_path, "file path");
          const messageToSent: any = {
            from: socket.id,
            to: CurrentConvo?.customer_socket_Id,
            conversation_id: CurrentConvo?._id,
            message: {
              msg_id: _id,
              body: messageBoxText[currentRoomId],
              support: true,
              file: true,
              file_path: data.result.file_path,
            },
          };
          socket.emit("send_message", messageToSent);
        })
        .catch(function (error) {
          return { error: { message: error } };
        });
      setMessageBoxText((prevTexts) => ({
        ...prevTexts,
        [currentRoomId]: "",
      }));
    } else {
      if (messageBoxText[currentRoomId] != "") {
        const messageToSent: any = {
          from: socket.id,
          to: CurrentConvo?.customer_socket_Id,
          conversation_id: CurrentConvo?._id,
          message: {
            body: messageBoxText[currentRoomId],
            support: true,
            supportName: `${UserData?.user?.name?.firstName} ${UserData?.user?.name?.lastName}`,
          },
        };
        console.log("message to be sent", messageToSent);

        socket.emit("send_message", messageToSent);
        setMessageBoxText((prevTexts) => ({
          ...prevTexts,
          [currentRoomId]: "",
        }));
      }
    }
  };

  // const onKeyPress = (e: any) => {
  //   const { key, value } = e;
  //   if (key === "Enter") {
  //     setcurMessage(value);
  //     setDisable(true)
  //     sendMessage();
  //   }
  // };

  const startActivityLog = async () => {
    const data = {
      shift: shift?._id,
      date: new Date(),
    };
    const res = await axios.post(
      `${process.env.REACT_APP_APIKEY}/api/user/activity/start`,
      data
    );
    if (res.success) {
      console.log(res.data, "shift Activity result");
      setActivity(res.data);
    }
  };

  const endActivityLog = async () => {
    const data = {
      shift: shift?._id,
      date: new Date(),
    };
    const res = await axios.post(
      `${process.env.REACT_APP_APIKEY}/api/user/activity/end`,
      data
    );
    if (res.success) {
      console.log(res.data, "shift Activity result");
      setActivity(res.data);
    }
  };

  const acceptingChat = () => {
    if (shift?._id) {
      setAcceptChat(true);
      const acceptRes = socket.emit("accept_chats", {
        userId: UserData.user._id,
      });
      startActivityLog();
      console.log("accept Results", acceptRes);
    }
  };

  const stopAcceptingChat = () => {
    console.log("In Stop accepting Chat Funtion");
    if (acceptChat) {
      const stopAcceptRes = socket.emit("stop_accepting_chats", {
        userId: UserData.user._id,
      });
      console.log("Stop accept Results", stopAcceptRes);
      endActivityLog();
      setActivityTime(0);
    }
  };

  const getAllReplies = async () => {
    const headers = {
      Authentication: `Bearer ${UserData?.token}`,
    };
    try {
      const ReplyRes = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/quick-reply/${UserData?.user._id}`,
        { headers }
      );
      setQuickMessages(ReplyRes.data);
      setfilteredQuickMessages(ReplyRes.data);
    } catch (error) {
      console.log("Error Found", error);
    }
  };
  const EndChat = (id: string) => {
    const EndChatBody: any = {
      support_socket: socket.id,
      customer_socket: CurrentConvo?.customer_socket_Id,
      conversationId: id,
      customer: false,
      userId: UserData.user._id,
    };
    setRemoveId(id);

    socket.emit("end_chat", EndChatBody);
  };

  console.log("all converstations", conversations);
  useEffect(() => {
    // Scroll to the bottom when messages change
    console.log("going to run auto scroll", CurrentConvo);

    scrollToBottomMessageDiv();
  }, [CurrentConvo]);

  useEffect(() => {
    getAllReplies();
    console.log("notification in Use Effect", notifySound);
  }, []);

  const [deleteMsg, setDeleteMsg] = useState<boolean>(false);
  const toggle_deleMsg = (id: number) => {
    setDeleteMsg(!deleteMsg);
    dispatch(onDeleteMessage(id));
  };
  // scroll simple bar
  const scroollRef = useRef<any>(null);
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight;
    }
  }, [messages]);

  // copy msg
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const copyMsg = (ele: any) => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML;
    navigator.clipboard.writeText(copyText);
    setCopyMsgAlert(true);
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false);
      }, 1000);
    }
  };

  const soundOn = () => {
    setSound(true);
    setNotifySound((prec) => {
      return true;
    });
  };
  const soundOff = () => {
    setSound(false);
    setNotifySound((prec) => {
      return false;
    });
  };

  const handleAccept = (e, data) => {
    e.preventDefault();
    setDsisable(true);

    const payload = {
      customer_socket_Id: data.customer_socket_Id,
      userId: UserData.user._id,
    };
    console.log(payload, "here is the adtra");

    if (socket) {
      socket.emit("accept_chat_from_queue", payload);
      setDsisable(false);
    }
  };

  //  img upload
  const handleImageChange = (event: any) => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    setImages(file);
    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
      setDisable(true);
    };
    reader.readAsDataURL(file);
  };

  const adjustInputHeight = (input) => {
    input.style.height = "45px";
    input.style.height = input.scrollHeight + "px";
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      // Insert newline for Shift + Enter
      event.preventDefault(); // Prevents default Enter key behavior
      setMessageBoxText((value) => value + "\n"); // Append newline character
      event.target.style.height = "auto"; // Adjust height if necessary
    } else if (event.key === "Enter") {
      event.preventDefault();
      setDisable(true);
      sendMessage();
      // setMessageBoxText("");
      event.target.style.height = "38px";
    }
  };
  const scrollToBottomMessageDiv = () => {
    // if (messageListRef.current) {
    //     console.log(messageListRef.current.scrollTop, "====", messageListRef.current.scrollHeight )
    //     messageListRef.current.scrollTop = messageListRef.current.scrollHeight ;
    //     console.log(messageListRef.current.scrollTop, "messageListRef.current.scrollTop");

    // }
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    // var element = document.getElementById("myScrollableDiv");
    // element.scrollTop = 100;
  };

  const handelSearch = () => {
    const filter = QuickMessages.filter((msg) =>
      msg.name.toLowerCase().includes(search.toLowerCase())
    );
    setfilteredQuickMessages(filter);
  };

  useEffect(() => {
    handelSearch();
  }, [search]);

  console.log("all agents >>", support);

  useEffect(() => {
    setNotifySound((prev) => {
      setSound((p) => {
        return prev;
      });
    });
  }, []);

  useEffect(() => {
    const textarea = document.getElementById("accessChat-textarea");
    if (textarea) {
      textarea.style.height = "40px";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [accessChat]);

  useEffect(() => {
    const textarea = document.getElementById("message_box_text");
    if (textarea) {
      textarea.style.height = "40px";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }, [currentRoomId, messageBoxText]);

  const handleTyping = () => {
    const sentAgentTyping: any = {
      from: socket.id,
      to: CurrentConvo?.customer_socket_Id,
      conversation_id: CurrentConvo?._id,
      name: {
        firstName: UserData.user.name.firstName,
        lastName: UserData.user.name.lastName,
      },
    };
    socket.emit("typing", sentAgentTyping);
  };

  const handleStopTyping = () => {
    socket.emit("stopTyping");
  };

  const openEditModel = (msg: any) => {
    setIsEditModal(true);
    setOpenModal(true);
    console.log(msg, "msg");

    setEditedMessageBody(msg.body);
    setEditedMessage({
      body: msg.messageWithLinks,
      id: msg._id,
    });
  };
  const toggleModel = () => {
    setOpenModal(!openModal);
  };

  const handleInputChange = (event) => {
    setEditedMessageBody(event.target.value);
  };

  const handleEditMessage = () => {
    try {
      if (editedMessage.id) {
        const editMessageSent: any = {
          editedMessage: editedMessageBody,
          id: editedMessage.id,
          conversationId: CurrentConvo._id,
        };
        socket.emit("send_edit_message", editMessageSent);
        toggleModel();
        console.log("editMessageSent", editMessageSent);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function convertUtcToLocal(utcDateString) {
    const date = new Date(utcDateString); // This parses the UTC date string to a Date object
    return date.toLocaleString(); // Converts to local time string
  }

  function getTimeDifference() {
    const currentDate = new Date();
    const startTimeUtc = moment(shift?.startTime);
    const startTime = new Date(startTimeUtc); // Explicitly convert to a Date object
    const difference = currentDate.getTime() - startTime.getTime(); // Difference in milliseconds

    const seconds = Math.floor((difference / 1000) % 60); // Convert milliseconds to seconds
    const minutes = Math.floor((difference / 1000 / 60) % 60); // Convert milliseconds to minutes
    const hours = Math.floor((difference / 1000 / 3600) % 24); // Convert milliseconds to hours

    console.log(seconds, minutes, hours);

    return { hours, minutes, seconds };
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat Support" breadcrumbItem="Live Chats" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    {shift && shift.status === "active" && (
                      <>
                        <div className="search-box chat-search-box py-4 w-100">
                          <div className="position-relative w-100">
                            <div>
                              <div
                                className="btn-group  btn-group-example mb-3 w-100"
                                role="group"
                              >
                                <button
                                  type="button"
                                  onClick={acceptingChat}
                                  className={`btn accept-default ${
                                    acceptChat ? "accept-btn" : ""
                                  }  w-xs`}
                                >
                                  Accept Chat
                                </button>
                                <button
                                  type="button"
                                  onClick={stopAcceptingChat}
                                  className={`btn not-accept-default ${
                                    acceptChat ? "" : "not-accept-btn"
                                  }  w-xs`}
                                >
                                  Don't Accept Chat
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ color: "#CF5A5A" }}>
                              {" "}
                              Time elapsed{" "}
                              <div style={{ color: "green", fontSize: "20px" }}>
                                <Timer
                                  hours={getTimeDifference().hours}
                                  minutes={getTimeDifference().minutes}
                                  seconds={getTimeDifference().seconds}
                                />
                              </div>
                            </div>
                            <div style={{ color: "#CF5A5A" }}>
                              {" "}
                              Active time{" "}
                              <div style={{ color: "green", fontSize: "20px" }}>
                                {
                                  <div>
                                    {activityTime && acceptChat && (
                                      <Timer
                                        hours={activityHours}
                                        minutes={activityMinutes}
                                        seconds={activitySeconds}
                                      />
                                    )}
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">
                              Current Chats
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            {/* <h5 className="font-size-14 mb-3">
                              Current Chats{" "}
                            </h5> */}
                            <ul
                              className="list-unstyled chat-list position-relative"
                              id="recent-list"
                            >
                              {conversations && (
                                <SimpleBar
                                  style={{
                                    height: "235px",
                                  }}
                                >
                                  {conversations?.map((chat: any, i: any) => (
                                    <li
                                      key={i}
                                      // style={{  }}
                                      className={
                                        chat?._id === CurrentConvo?._id
                                          ? "active"
                                          : ""
                                      }
                                      style={
                                        chat?.unread
                                          ? chat?._id !== CurrentConvo?._id
                                            ? {
                                                color: "red",
                                                marginBottom: "5px",
                                              }
                                            : { marginBottom: "5px" }
                                          : { marginBottom: "5px" }
                                      }
                                    >
                                      <a onClick={() => userChatOpen(chat)}>
                                        <div className="d-flex align-items-center">
                                          <div className="align-self-center me-3">
                                            <i
                                              className={
                                                "mdi mdi-circle font-size-10"
                                              }
                                            />
                                          </div>

                                          <div
                                            className="flex-grow-1 overflow-hidden"
                                            style={{ width: "65%" }}
                                            dir={
                                              chat?.language === "arabic"
                                                ? "rtl"
                                                : "ltr"
                                            }
                                          >
                                            <h5
                                              className="text-truncate font-size-14 mb-1"
                                              style={{
                                                color:
                                                  chat?._id ===
                                                  CurrentConvo?._id
                                                    ? "green"
                                                    : "",
                                              }}
                                            >
                                              {chat?.customer_name}
                                            </h5>
                                            <p className="text-truncate mb-0">
                                              {getLastMessages(chat)}
                                            </p>
                                          </div>
                                          <div
                                            className="font-size-10"
                                            style={{
                                              textAlign: "end",
                                              width: "35%",
                                            }}
                                          >
                                            {moment(
                                              chat?.messages[
                                                chat?.messages?.length - 1
                                              ]?.createdOn
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </a>
                                    </li>
                                  ))}
                                </SimpleBar>
                              )}
                            </ul>
                            {peoplesQueue.length > 0 && (
                              <>
                                {/* <h5 className="font-size-14 mb-3 mt-3">
                                        People in Queue{" "}
                                      </h5> */}
                                <Nav
                                  pills
                                  justified
                                  className="font-size-14 mb-3 mt-2"
                                >
                                  <NavItem>
                                    <NavLink
                                      className={classnames({
                                        active: activeTab === "1",
                                      })}
                                      style={{
                                        padding: "13px 16px",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <i className="bx bx-chat font-size-20 d-sm-none" />
                                      <span className="d-none d-sm-inline-block">
                                        People in Queue
                                      </span>
                                      <span className="badge rounded-pill bg-danger">
                                        {peoplesQueue.length}
                                      </span>
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                                <SimpleBar
                                  style={{
                                    height: "170px",
                                  }}
                                >
                                  <ul
                                    className="list-unstyled chat-list position-relative"
                                    id="recent-list"
                                  >
                                    {peoplesQueue.map((n, i) => (
                                      <li key={i}>
                                        <a>
                                          <div
                                            className="d-flex"
                                            style={{ alignItems: "center" }}
                                          >
                                            <div className="flex-grow-1 overflow-hidden">
                                              <h5
                                                className="text-truncate font-size-14 mb-1"
                                                style={{ color: "green" }}
                                              >
                                                {n?.customer_name}
                                              </h5>
                                              <p>{n?.language}</p>
                                            </div>
                                            <div className="font-size-11">
                                              <button
                                                className="btn btn-success"
                                                disabled={disable}
                                                onClick={(e) =>
                                                  handleAccept(e, n)
                                                }
                                              >
                                                Accept
                                              </button>
                                            </div>
                                          </div>
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </SimpleBar>
                              </>
                            )}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    {conversations?.length ? (
                      CurrentConvo && ( //should be conversations?.length insted of true
                        <>
                          <div
                            className="chat-box-animation p-4 border-bottom "
                            // style={{ backgroundColor: "#fccd0a" }}
                          >
                            <Row>
                              <Col md={4} xs={9}>
                                <h5
                                  className="font-size-15 mb-1"
                                  style={{
                                    textTransform: "capitalize",
                                    // color: "#232323",
                                  }}
                                >
                                  {CurrentConvo?.customer_name}
                                </h5>
                                <h5
                                  className="font-size-13 mb-1"
                                  style={{ color: "#556ee6" }}
                                >
                                  {CurrentConvo?.customer_email}
                                </h5>

                                <p className="font-size-12 text-muted mb-0">
                                  <i
                                    className={
                                      // Chat_Box_User_Status === "online"
                                      // ?
                                      "mdi mdi-circle text-success align-middle me-2"
                                      // : Chat_Box_User_Status === "intermediate"
                                      //   ? "mdi mdi-circle text-warning align-middle me-1"
                                      //   : "mdi mdi-circle align-middle me-1"
                                    }
                                  />
                                  {"Active now"}
                                </p>
                              </Col>
                              <Col md={8} xs={3}>
                                <ul className="list-inline user-chat-nav text-end mb-0">
                                  <li className="list-inline-item d-none d-sm-inline-block">
                                    <Dropdown
                                      className="me-1"
                                      isOpen={search_Menu}
                                      toggle={toggleSearch}
                                    >
                                      <DropdownToggle
                                        className="btn nav-btn"
                                        tag="a"
                                      >
                                        <i
                                          className="bx bx-search-alt-2"
                                          // style={{ color: "#232323" }}
                                        />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-md">
                                        <Form className="p-3">
                                          <FormGroup className="m-0">
                                            <InputGroup>
                                              <Input
                                                type="text"
                                                placeholder="Search ..."
                                                id="searchMessage"
                                                aria-label="Recipient's username"
                                                // onChange={handeleSearch}
                                              />
                                              <Button
                                                color="primary"
                                                type="submit"
                                              >
                                                <i
                                                  className="mdi mdi-magnify"
                                                  // style={{ color: "#232323" }}
                                                />
                                              </Button>
                                            </InputGroup>
                                          </FormGroup>
                                        </Form>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </li>
                                  <li className="list-inline-item">
                                    <Dropdown
                                      isOpen={other_Menu}
                                      toggle={toggleOther}
                                    >
                                      <DropdownToggle
                                        className="btn nav-btn"
                                        tag="a"
                                      >
                                        <i className="bx bx-dots-horizontal-rounded" />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem
                                          onClick={() =>
                                            EndChat(CurrentConvo._id)
                                          }
                                        >
                                          End Chat
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            tog_scroll();
                                          }}
                                        >
                                          Transfer Chat
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </li>
                                </ul>
                                <Modal
                                  isOpen={modal_scroll}
                                  toggle={() => {
                                    tog_scroll();
                                  }}
                                  scrollable={true}
                                >
                                  <ModalHeader
                                    toggle={() => {
                                      tog_scroll();
                                    }}
                                  >
                                    <div className="modal-title mt-0 h5">
                                      Transfer Chat
                                    </div>
                                  </ModalHeader>
                                  <div className="modal-body">
                                    {support.map((sup: any, i: any) => (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                        }}
                                        className="p-3"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div>
                                            <img
                                              className="rounded-circle avatar-xs"
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                              src={avatar7}
                                              alt=""
                                            />
                                          </div>
                                          <div className="mx-2">
                                            <h5 className="font-size-14 mb-1 px-2">
                                              <Link
                                                to="#"
                                                className="text-dark"
                                              >{`${sup.data?.name?.firstName} ${sup.data?.name?.lastName}`}</Link>
                                            </h5>
                                            <p
                                              className="text-muted mb-0"
                                              style={{ fontSize: "10px" }}
                                            >
                                              <i
                                                className={
                                                  // Chat_Box_User_Status === "online"
                                                  // ?
                                                  "mdi mdi-circle text-success align-middle me-2"
                                                  // : Chat_Box_User_Status === "intermediate"
                                                  //   ? "mdi mdi-circle text-warning align-middle me-1"
                                                  //   : "mdi mdi-circle align-middle me-1"
                                                }
                                              />
                                              {"Active now"}
                                            </p>
                                          </div>
                                        </div>
                                        {(UserData?.user?._id).toString() !==
                                          sup.data._id.toString() && (
                                          <button
                                            className="btn btn-success"
                                            onClick={() => transfer(sup)}
                                          >
                                            Transfer
                                          </button>
                                        )}
                                      </div>
                                    ))}

                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setmodal_scroll(false)}
                                      >
                                        Close
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                      >
                                        Save changes
                                      </button>
                                    </div>
                                  </div>
                                </Modal>
                              </Col>
                            </Row>
                          </div>

                          <div>
                            <div
                              className="chat-conversation p-3"
                              id="myScrollableDiv"
                            >
                              <SimpleBar style={{ height: "340px" }}>
                                <ul
                                  className="list-unstyled"
                                  id="users-conversation "
                                >
                                  <div>
                                    <li>
                                      <div className="chat-day-title">
                                        <span
                                          className="title"
                                          // style={{ color: "#232323" }}
                                        >
                                          {CurrentConvo?.customer_name}{" "}
                                          {" Joined The Chat"}
                                        </span>
                                      </div>
                                    </li>

                                    {/* {CurrentConvo &&
                                    CurrentConvo?.messages?.map((msg: any, index: number) => {
                                      return (
                                        <li
                                          key={index}
                                          className={
                                            !msg.file && msg?.support === true ? "right" : ""
                                          }

                                          ref={index === CurrentConvo?.messages.length - 1 ? messagesEndRef : null}
                                        >

                                          {
                                            msg.file === true ?
                                              // <img src={msg.file_path} className="my-1" style={msg.file && msg.support ? { maxWidth: "300px", float: "right" } : { maxWidth: "300px", }} />
                                              <PhotoProvider>
                                                <PhotoView src={msg.file_path}>
                                                  <img style={msg.file && msg.support ? { maxWidth: "300px", float: "right" } : { maxWidth: "300px", }} src={msg.file_path} alt="" />
                                                </PhotoView>
                                              </PhotoProvider>
                                              :
                                              <div className="conversation-list ">
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    href="#!"
                                                    tag="a" className="dropdown-toggle"
                                                  >
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem onClick={(e) => copyMsg(e.target)} href="#">
                                                      Copy
                                                    </DropdownItem>

                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <div className="ctext-wrap" dir={ CurrentConvo?.language === "arabic" ? "rtl"  : "ltr"  }>
                                                  <div className="conversation-name">
                                                    {msg?.support === true ? "You" : CurrentConvo?.customer_name}
                                                  </div>
                                                  <p style={{ whiteSpace: 'pre-wrap' }} >{msg?.body}</p>
                                                  {msg?.images && <img src={msg?.images} alt="" width="150px" />}
                                                  {msg?.createdOn !== 0 && <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1"></i> {moment(msg?.createdOn).format("hh:mm A")}</p>}
                                                </div>
                                              </div>
                                          }


                                        </li>
                                      )
                                    })
                                  } */}

                                    {CurrentConvo &&
                                      CurrentConvo?.messages?.map(
                                        (msg: any, index: number) => {
                                          // Function to convert URLs in text to clickable links
                                          const linkify = (text) => {
                                            const urlRegex =
                                              /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|?]+)/gi;
                                            return text?.replace(
                                              urlRegex,
                                              function (url) {
                                                return (
                                                  '<a  href="' +
                                                  url +
                                                  '" target="_blank" rel="noopener noreferrer">' +
                                                  url +
                                                  "</a>"
                                                );
                                              }
                                            );
                                          };

                                          // Use the function to linkify the message body
                                          const messageWithLinks = linkify(
                                            msg?.body
                                          );
                                          if (!msg.ended) {
                                            return (
                                              <li
                                                key={index}
                                                className={
                                                  !msg.file &&
                                                  msg?.support === true
                                                    ? "right"
                                                    : ""
                                                }
                                                ref={
                                                  index ===
                                                  CurrentConvo?.messages
                                                    .length -
                                                    1
                                                    ? messagesEndRef
                                                    : null
                                                }
                                              >
                                                {msg.file === true ? (
                                                  <PhotoProvider>
                                                    <PhotoView
                                                      src={msg.file_path}
                                                    >
                                                      <img
                                                        style={
                                                          msg.file &&
                                                          msg.support
                                                            ? {
                                                                maxWidth:
                                                                  "300px",
                                                                float: "right",
                                                              }
                                                            : {
                                                                maxWidth:
                                                                  "300px",
                                                                marginBottom:
                                                                  "14px",
                                                              }
                                                        }
                                                        src={msg.file_path}
                                                        alt=""
                                                      />
                                                    </PhotoView>
                                                  </PhotoProvider>
                                                ) : (
                                                  <div
                                                    className="conversation-list"
                                                    dir={
                                                      CurrentConvo?.language ===
                                                      "arabic"
                                                        ? "rtl"
                                                        : "ltr"
                                                    }
                                                  >
                                                    <UncontrolledDropdown>
                                                      <DropdownToggle
                                                        href="#!"
                                                        tag="a"
                                                        className="dropdown-toggle"
                                                      >
                                                        <i className="bx bx-dots-vertical-rounded" />
                                                      </DropdownToggle>
                                                      <DropdownMenu>
                                                        <DropdownItem
                                                          onClick={(e) =>
                                                            copyMsg(e.target)
                                                          }
                                                          href="#"
                                                        >
                                                          Copy
                                                        </DropdownItem>
                                                        {msg?.support ===
                                                        true ? (
                                                          <DropdownItem
                                                            onClick={() =>
                                                              openEditModel(msg)
                                                            }
                                                            href="#"
                                                          >
                                                            Edit
                                                          </DropdownItem>
                                                        ) : null}
                                                      </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    <div
                                                      className="ctext-wrap"
                                                      dir={
                                                        CurrentConvo?.language ===
                                                        "arabic"
                                                          ? "rtl"
                                                          : "ltr"
                                                      }
                                                    >
                                                      <div className="conversation-name">
                                                        {msg?.support === true
                                                          ? msg.supportName
                                                            ? msg.supportName
                                                            : "You"
                                                          : CurrentConvo?.customer_name}
                                                      </div>
                                                      <p
                                                        className="conversation-text"
                                                        dir={
                                                          CurrentConvo?.language ===
                                                          "arabic"
                                                            ? "rtl"
                                                            : "ltr"
                                                        }
                                                        style={{
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          marginBottom: "5px",
                                                          wordBreak:
                                                            "break-all",
                                                          overflowWrap:
                                                            "break-word",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            messageWithLinks,
                                                        }}
                                                      ></p>
                                                      {msg?.images && (
                                                        <img
                                                          src={msg?.images}
                                                          alt=""
                                                          width="150px"
                                                        />
                                                      )}
                                                      {msg?.createdOn !== 0 && (
                                                        <p className="chat-time mb-0">
                                                          <i className="bx bx-time-five align-middle me-1"></i>{" "}
                                                          {moment(
                                                            msg?.createdOn
                                                          ).format("hh:mm A")}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </li>
                                            );
                                          } else {
                                            return (
                                              <li>
                                                <div className="chat-day-title">
                                                  <span
                                                    className="title"
                                                    // style={{ color: "#232323" }}
                                                  >
                                                    {msg.endedBy}
                                                  </span>
                                                </div>
                                              </li>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </ul>
                              </SimpleBar>
                            </div>
                            {selectedImage && (
                              <div className="replymessage-block mb-0 d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <img
                                    src={selectedImage}
                                    alt="select img"
                                    style={{ width: "150px", height: "auto" }}
                                  />
                                </div>
                                <div className="flex-shrink-0">
                                  <button
                                    type="button"
                                    id="close_toggle"
                                    className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                    onClick={() => {
                                      setSelectedImage(null);
                                      setImages(null);
                                    }}
                                  >
                                    <i className="bx bx-x align-middle"></i>
                                  </button>
                                </div>
                              </div>
                            )}

                            {copyMsgAlert && (
                              <UncontrolledAlert color="warning" role="alert">
                                Message copied
                              </UncontrolledAlert>
                            )}

                            <div
                              className="p-3 chat-input-section"
                              dir={
                                CurrentConvo.language === "arabic"
                                  ? "rtl"
                                  : "ltr"
                              }
                            >
                              <div>
                                {accessChat &&
                                accessChat?.message?.body &&
                                accessChat.conversation_id ===
                                  CurrentConvo._id ? (
                                  <textarea
                                    id="accessChat-textarea"
                                    readOnly
                                    value={accessChat.message.body}
                                    onChange={(e) =>
                                      setAccessChat(e.target.value)
                                    }
                                    style={{
                                      width: "74%",
                                      padding: "10px 12px",
                                      borderRadius: "30px",
                                      marginBottom: "5px",
                                      backgroundColor: "#eff2f7",
                                      border: "2px solid #556ee6",
                                      color: "#232323",
                                      resize: "none",
                                      overflowY: "hidden",
                                      boxSizing: "border-box",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                              <Row>
                                <Col
                                  dir={
                                    CurrentConvo.language === "arabic"
                                      ? "rtl"
                                      : "ltr"
                                  }
                                >
                                  <div className="position-relative">
                                    <textarea
                                      rows={1}
                                      id="message_box_text"
                                      value={
                                        messageBoxText[currentRoomId] || ""
                                      }
                                      onKeyDown={(e) => {
                                        handleKeyPress(e);
                                        handleStopTyping();
                                      }}
                                      onChange={(e) => {
                                        const newText = e.target.value;
                                        setMessageBoxText((prevTexts) => ({
                                          ...prevTexts,
                                          [currentRoomId]: newText,
                                        }));
                                        handleTyping();
                                      }}
                                      className="chat-input chat-input-height live-chat-input"
                                      placeholder={
                                        CurrentConvo.language === "arabic"
                                          ? "أدخل رسالة..."
                                          : "Enter Message..."
                                      }
                                      style={
                                        CurrentConvo.language === "arabic"
                                          ? {
                                              paddingLeft: "120px",
                                              paddingRight: "15px",
                                            }
                                          : {
                                              paddingRight: "120px",
                                            }
                                      }
                                      dir={
                                        CurrentConvo.language === "arabic"
                                          ? "rtl"
                                          : "ltr"
                                      }
                                    />
                                    <div
                                      // className="chat-input-links"
                                      style={
                                        CurrentConvo.language === "arabic"
                                          ? {
                                              position: "absolute",
                                              left: "16px",
                                              top: "60%",
                                              transform: "translateY(-50%)",
                                            }
                                          : {
                                              position: "absolute",
                                              right: "16px",
                                              top: "60%",
                                              transform: "translateY(-50%)",
                                            }
                                      }
                                    >
                                      <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                          <Dropdown
                                            isOpen={OpenQuickMsg}
                                            toggle={() => {
                                              setOpenQuickMsg(!OpenQuickMsg);
                                            }}
                                          >
                                            <DropdownToggle
                                              className=" "
                                              tag="a"
                                            >
                                              <label
                                                style={{
                                                  color: "#556ee6",
                                                  fontSize: 20,
                                                }}
                                              >
                                                <i
                                                  className="mdi mdi-lightning-bolt-outline "
                                                  id="QuickMsg"
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target="QuickMsg"
                                                >
                                                  Quick Messages
                                                </UncontrolledTooltip>
                                              </label>
                                            </DropdownToggle>

                                            <DropdownMenu
                                              className="   "
                                              style={{
                                                height: "300px",
                                                width: "300px",
                                              }}
                                            >
                                              <SimpleBar
                                                className=""
                                                style={{
                                                  height: "300px",
                                                  width: "300px",
                                                }}
                                              >
                                                <div className="px-3">
                                                  <Input
                                                    type="text"
                                                    value={search}
                                                    placeholder={
                                                      CurrentConvo.language ===
                                                      "arabic"
                                                        ? "يبحث ..."
                                                        : "Search ..."
                                                    }
                                                    id="searchMessage"
                                                    aria-label="Recipient's username"
                                                    onChange={(e) =>
                                                      setSearch(e.target.value)
                                                    }
                                                    dir={
                                                      CurrentConvo.language ===
                                                      "arabic"
                                                        ? "rlt"
                                                        : "ltr"
                                                    }
                                                  />
                                                </div>

                                                {filteredQuickMessages &&
                                                  filteredQuickMessages?.map(
                                                    (qMsg, index) => {
                                                      return (
                                                        <div
                                                          dir={
                                                            qMsg.language ===
                                                            "ar"
                                                              ? "rtl"
                                                              : "ltr"
                                                          }
                                                        >
                                                          <DropdownItem
                                                            key={index}
                                                            style={
                                                              qMsg.language ===
                                                              "ar"
                                                                ? {
                                                                    textAlign:
                                                                      "right",
                                                                  }
                                                                : {}
                                                            }
                                                            // onClick={() =>
                                                            //   setMessageBoxText(
                                                            //     qMsg.body
                                                            //   )
                                                            // }
                                                            onClick={() => {
                                                              // Update the message text for the current room ID only
                                                              setMessageBoxText(
                                                                (
                                                                  prevTexts
                                                                ) => ({
                                                                  ...prevTexts,
                                                                  [currentRoomId]:
                                                                    qMsg.body,
                                                                })
                                                              );
                                                            }}
                                                            id={`Msg${index}`}
                                                          >
                                                            {qMsg.name}
                                                          </DropdownItem>
                                                          <UncontrolledTooltip
                                                            placement="right"
                                                            target={`Msg${index}`}
                                                          >
                                                            {qMsg.body}
                                                          </UncontrolledTooltip>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </SimpleBar>
                                            </DropdownMenu>
                                          </Dropdown>
                                        </li>
                                        <li className="list-inline-item">
                                          <label
                                            htmlFor="imageInput"
                                            style={{
                                              color: "#556ee6",
                                              fontSize: 20,
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-file-image-outline me-1"
                                              id="Imagetooltip"
                                            />
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="Imagetooltip"
                                            >
                                              Images
                                            </UncontrolledTooltip>
                                          </label>
                                          <input
                                            type="file"
                                            id="imageInput"
                                            className="d-none"
                                            onChange={handleImageChange}
                                          />
                                        </li>
                                        {/* <li className="list-inline-item">
                                        <Link to="#">
                                          <i className="mdi mdi-file-document-outline" id="Filetooltip" />
                                          <UncontrolledTooltip placement="top" target="Filetooltip">
                                            Add Files
                                          </UncontrolledTooltip>
                                        </Link>
                                      </li> */}
                                      </ul>
                                    </div>
                                  </div>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    type="button"
                                    color="primary"
                                    disabled={
                                      !messageBoxText[currentRoomId] && !images
                                    }
                                    onClick={() => sendMessage()}
                                    className="btn btn-primary btn-rounded chat-send w-md "
                                    dir={
                                      CurrentConvo.language === "arabic"
                                        ? "rtl"
                                        : "ltr"
                                    }
                                  >
                                    <span
                                      className="d-none d-sm-inline-block"
                                      style={
                                        CurrentConvo.language === "arabic"
                                          ? { marginLeft: "0.5rem" }
                                          : { marginRight: "0.5rem" }
                                      }
                                    >
                                      {CurrentConvo.language === "arabic"
                                        ? selectedImage
                                          ? "إرسال صورة"
                                          : "يرسل"
                                        : selectedImage
                                        ? "Send Image"
                                        : "Send"}
                                    </span>
                                    {CurrentConvo.language === "arabic" ? (
                                      <i className="mdi mdi-send mdi-rotate-180" />
                                    ) : (
                                      <i className="mdi mdi-send" />
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <NoChat />
                    )}
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={openModal}
        toggle={() => {
          toggleModel();
        }}
        centered
      >
        <ModalHeader
          toggle={() => {
            toggleModel();
          }}
        >
          <div className="modal-title mt-0 h5">Edit Message</div>
        </ModalHeader>
        <div className="modal-body">
          <Form>
            <div className="mb-3">
              <Label className="form-label">Message</Label>
              <Input
                name="name"
                placeholder="Enter Edit Message Here"
                type="text"
                value={editedMessageBody}
                onChange={handleInputChange}
              />
            </div>
            <div className="d-flex flex-wrap gap-2">
              <Button onClick={() => handleEditMessage()} color="primary">
                Edit
              </Button>{" "}
            </div>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LiveChat;
