import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";
import Overview from "./Overview";
import DetailsSection from "./DetailsSection";
import Spinners from "Components/Common/Spinner";

const JobDetails = () => {
  document.title = "Job Details | Skote - React Admin & Dashboard Template";

  const { id } = useParams();
  const [jobDetail, setJobDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchJobDetail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_APIKEY}/api/get/job/detail/${id}`
      );
      console.log(response.data, "detailresponse");
      if (response.success) {
        setIsLoading(false);
        setJobDetail(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch job details:", error);
    }
  };

  useEffect(() => {
    fetchJobDetail();
  }, []);

  console.log(jobDetail, "jobDetailjobDetail");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Jobs" breadcrumbItem="Job Details" />

          <Row>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                  width: "100%",
                }}
              >
                <Spinners />
              </div>
            ) : (
              <>
                <Overview jobDetail={jobDetail} />
                <DetailsSection jobDetail={jobDetail} />
              </>
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobDetails;
