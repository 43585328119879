import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
    Container,
    Card,
    CardBody,
    Col,
    Form,
    Input,
    Label,
    Row,
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    UncontrolledPopover,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Toast,
    ToastHeader,
    ToastBody,
    NavItem,
    NavLink,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import { useParams, useNavigate } from 'react-router-dom';
import { Editor as MyEditor } from 'draft-js';
import { EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";

import "draft-js/dist/Draft.css";
// import images
import img1 from "../../assets/images/small/img-2.jpg"
import avtar1 from "../../assets/images/users/avatar-2.jpg"
import Spinners from "Components/Common/Spinner"
import axios from 'axios'
import Moment from 'react-moment';
import Select from "react-select";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const ArticleDetails = () => {
    interface Article {
        // Define the properties of a blog object here
        _id: string
        titleE: string,
        titleAr: string,
        content: any,
        contentAr: any,
        isPublished: boolean,
        category: any
        // ... other properties ...
    }

    const [detail, setDetail] = useState<Article>()
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("1")
    const [loading, setLoading] = useState(true)
    const [editorState, setEditorState] = useState<EditorState>(() =>
        EditorState.createEmpty()
    );
    const [editorState2, setEditorState2] = useState<EditorState>(() =>
        EditorState.createEmpty()
    );
    const [content, setContent] = useState(null) as any
    const user = JSON.parse(localStorage.getItem("authUser"))

    const fetchdetails = async () => {
        setLoading(true)
        const res = await axios.get(`${process.env.REACT_APP_APIKEY}/api/fetch/one/atricles/${id}`)
        if (res.success) {
            setLoading(false)
            setDetail(res.data)

            if (res.data?.content) {
                const contentState = convertFromRaw(JSON.parse(res.data?.content));
                const editorState = EditorState.createWithContent(contentState);
                console.log(editorState, "<=====");
                setEditorState(editorState)
                setContent(editorState)

            }

            if (res.data?.contentAr) {
                const contentState2 = convertFromRaw(JSON.parse(res.data?.contentAr));
                const editorState2 = EditorState.createWithContent(contentState2);
                setEditorState2(editorState2)
            }

            // if (res.data.language === "ar") {
            //   setselectedGroup({ label: "Arabic", value: "ar" })
            // }
            // else {
            //   setselectedGroup({ label: "English", value: "en" })
            // }
            //   setselectedGroupCat({ label: res.data?.category?.titleEn, value: res.data?.category?._id })
            //   setTitleEn(res?.data?.titleEn)
            //   setTitleAr(res.data?.titleAr)

            //   setLoading(false)

        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchdetails()
    }, [])

    useEffect(() => {
        if (activeTab === "1") {
            setContent(editorState)
        }
        else {
            setContent(editorState2)
        }
    }, [activeTab])

    const publishArticle = async (article: any) => {
        const headers = {
            Authentication: `Bearer ${user?.token}`,
        };
        const data = {
            isPublished: !detail.isPublished
        }
        try {
            const res = await axios.put(`${process.env.REACT_APP_APIKEY}/api/edit/article/${detail._id}`, data, { headers })
            if (res.success) {
                setDetail(res.data)
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const ImageComponent = ({ src }) => <img src={src} alt="" style={{ maxWidth: '100%' }} />;

const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: MediaComponent,
      editable: false,
    };
  }

  return null;
};

const MediaComponent = (props) => {
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const type = entity.getType();
  const data = entity.getData();

  if (type === 'IMAGE') {
    return <ImageComponent src={data.src} />;
  }

  return null;
};



    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "content", alignItems: "center", height: "100%", width: "100%", backgroundColor: "red" }} >
                <Spinners setLoading={setLoading} />
            </div>
        )
    }
    //meta title
    document.title = "Blog Details | Skote - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className="page-content" >
                <Container fluid>
                    <Breadcrumbs title="Blog" breadcrumbItem="Article Details" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div>
                                        <div className="text-center">
                                            <div>
                                                <ul
                                                    className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            className={classnames({
                                                                active: activeTab === "1",
                                                            })}
                                                            onClick={() => {
                                                                setActiveTab("1")
                                                            }}
                                                        >
                                                            English
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            to="#"
                                                            className={classnames({
                                                                active: activeTab === "2",
                                                            })}
                                                            onClick={() => {
                                                                setActiveTab("2")
                                                            }}
                                                        >
                                                            Arabic
                                                        </NavLink>
                                                    </NavItem>

                                                </ul>
                                            </div>


                                        </div>
                                        {
                                            detail && <div style={{ display: "flex", justifyContent: "flex-end" }} className="mt-1">
                                                {detail.isPublished ? <button className="btn btn-primary"
                                                    onClick={publishArticle}
                                                > unpublish </button> : <button
                                                    onClick={publishArticle}
                                                    className="btn btn-success" > publish </button>}
                                            </div>

                                        }

                                        <div className="mt-4">
                                            <div className="text-muted font-size-14">
                                                {content && <MyEditor editorState={content} readOnly={true} blockRendererFn={mediaBlockRenderer} />}


                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ArticleDetails
