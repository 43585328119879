import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { Popover } from "antd";


import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Toast,
  ToastHeader,
  ToastBody,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";

//import Images
import verification from "../../assets/images/verification-img.png";

//redux
import axios from "axios";
import moment from "moment";
import Moment from "react-moment";

const Calender = (props: any) => {
  //meta title
  document.title = "Meetings | PrimeX ";
  const UserData = JSON.parse(localStorage.getItem("authUser"));

  const [event, setEvent] = useState<any>({});
  const [toastError, setToastError] = useState(false);
  const [toast, setToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null) as any[];
  const [successMessage, setSuccessMessage] = useState(null) as any[];

  // category validation
  // const categoryValidation: any = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     title: (event && event.title) || '',
  //     start: (event && event.start) || new Date(),
  //   },
  //   validationSchema: Yup.object({
  //     title: Yup.string().required("Please Enter Your Event Name"),
  //     category: Yup.date(),
  //   }),
  //   onSubmit: (values) => {
  //     if (isEdit) {
  //       // const updateEvent = {
  //       //   id: event.id,
  //       //   title: values.title,
  //       //   start: event.start,
  //       // };
  //       // update event
  //       // categoryValidation.resetForm();
  //     }
  //     toggle();
  //   },
  // });

  console.log(UserData.user.role.admin, "UserData.user");
  

  const categoryValidation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      start: (event && event.start) || "",
      createdOn: (event && event.createdOn) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      start: Yup.date(),
      createdOn: Yup.date(),
    }),
    onSubmit: async (values) => {
      if (isEdit) {
        const updateEvent = {
          _id: event.id,
          userId: UserData.user._id,
          email: UserData.user.email,
        };

        console.log("values", updateEvent);
        const res = await axios.post(
          `${process.env.REACT_APP_APIKEY}/api/admin/accept/event`,
          updateEvent
        );
        console.log(res, "success");

        if (res.success) {
          console.log("res.success");
        }
        // update event
        // dispatch(onUpdateEvent(updateEvent));
        categoryValidation.resetForm();
        fetchEvents();
      }
      toggle();
    },
  });

  // const { events }: any = useSelector(selectProperties);

  const [events, setEvents] = useState<any>([]);

  console.log(events, "hievents");
  const fetchEvents = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/admin/fetch/events`
    );
    console.log(res, "colorsresponse");
    if (res.success) {
      const sortedData = res.data.map((event) => {
        let classNames;

        const meetTimeMoment = moment(event.meetTime);
        const fifteenMinutesFromNow = moment(event.meetTime).add(15, "minutes");

        // Assuming event.meetTime is a Date object or a moment-parseable string

        const now = moment();
        if (!event.assigned && now.isBefore(event.meetTime)) {
          classNames = "bg-secondary text-white";
        }
        if (!event.assigned && now.isAfter(event.meetTime)) {
          classNames = "bg-danger text-white";
        }
        if (event.assigned && moment() > moment(event.meetTime)) {
          classNames = "bg-success text-white";
        }
        if (
          now.isSameOrAfter(meetTimeMoment) &&
          now.isBefore(fifteenMinutesFromNow)
        ) {
          classNames = "bg-warning text-white";
        }
        if (
          event.assigned &&
          !(
            now.isSameOrAfter(meetTimeMoment) &&
            now.isBefore(fifteenMinutesFromNow)
          ) &&
          now.isBefore(meetTimeMoment)
        ) {
          classNames = "bg-info text-white";
        }

        return {
          className: classNames,
          id: event._id,
          start: event.meetTime,
          title: event.title,
          assigned: event.assigned,
          createdOn: event.createdOn,
          email: event.email,
          agentName: event.assigned
            ? `${event.assigned.name.firstName} ${event.assigned.name.lastName}`
            : "",
        };
      });

      console.log(sortedData, "sortedData");

      setEvents(sortedData);
    }
  };


  const block = async (email) => {
    const res = await axios.post(`${process.env.REACT_APP_APIKEY}/api/email/block`, { email: email })
    if (res.success) {
      setToast(true);
      setSuccessMessage(res.message)
    }
    else {
      setToastError(true);
      setErrorMessage(res.message)
    }
  }

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => {
        setToast(false);
        setSuccessMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
    if (toastError) {
      const timer = setTimeout(() => {
        setToastError(false);
        setErrorMessage(null);
      }, 5000); // 10000 milliseconds (10 seconds)

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [toast, toastError]);

  const renderEventContent = (eventInfo) => {
    const startTime = moment(eventInfo.event.start).format("h:mma");
    const createdOnFormatted = moment(
      eventInfo.event.extendedProps.createdOn
    ).format("DD MMMM YYYY hh:mm a");
    const content = (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
        <div>
          <div>{`Email: ${eventInfo.event.extendedProps.email}`}</div>
          {eventInfo.event.extendedProps.agentName && (
            <div> {`Agent Name: ${eventInfo.event.extendedProps.agentName}`}</div>
          )}
          <div>{`CreatedOn: ${createdOnFormatted}`}</div>
        </div>
        {
          UserData?.user?.role?.admin && <input type="button" className="btn btn-danger mt-2" onClick={() => block(eventInfo.event.extendedProps.email,)} value={"Block this email"} />
        }
      </div>
    );
    return (
      <Popover content={content} trigger="hover">
        <div className="d-flex align-items-center">
          <div className="fc-daygrid-event-dot"></div>
          <div className="fc-event-time">{startTime}</div>
          <div className="fc-event-title">{eventInfo.event.title}</div>
        </div>
      </Popover>
    );
  };

  console.log();

  console.log(events, "events");

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [modalcategory, setModalcategory] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState();
  const [selectedDay, setSelectedDay] = useState<any>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([
    {
      id: 1,
      title: "Done",
      type: "bg-success",
    },
    {
      id: 2,
      title: "Scheduled and Accepted",
      type: "bg-info",
    },
    {
      id: 3,
      title: "In Progress",
      type: "bg-warning",
    },
    {
      id: 4,
      title: "Not Assigned",
      type: "bg-secondary",
    },
    {
      id: 5,
      title: "Overdue",
      type: "bg-danger",
    },
  ]);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modalcategory) {
      setModalcategory(false);
      setEvent(null);
      setIsEdit(false);
    } else {
      setModalcategory(true);
    }
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg: any) => {
    const event = arg.event;
    const eventClassNames = event.classNames;
    if (eventClassNames.includes("bg-secondary")) {
      setEvent({
        id: event.id,
        title: event.title,
        assigned: event.assigned,
        title_category: event.title_category,
        start: event.start,
        createdAt: event.createdAt,
        className: event.classNames,
        category: event.classNames[0],
        event_category: event.classNames[0],
      });
      setDeleteId(event.id);
      setIsEdit(true);
      setModalcategory(true);
      toggle();
    }
  };

  /**
   * On delete event
   */
  // const handleDeleteEvent = () => {
  //   if (deleteId) {
  //     dispatch(onDeleteEvent(deleteId));
  //   }
  //   setDeleteModal(false);
  // };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div
            className="position-fixed top-0 end-0 p-3"
            style={{ zIndex: "1005" }}
          >
            <Toast
              isOpen={toast}
              className="align-items-center text-white bg-success border-0 custom-toast"
              role="alert"

            // style={{ backgroundColor: "green" }}
            >
              <div className="d-flex">
                <ToastBody>{successMessage}</ToastBody>
                <button
                  onClick={() => setToast(false)}
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                ></button>
              </div>
            </Toast>

            <Toast
              isOpen={toastError}
              className="align-items-center text-white bg-danger border-0 custom-toast"
              role="alert"

            // style={{ backgroundColor: "green" }}
            >
              <div className="d-flex">
                <ToastBody>{errorMessage}</ToastBody>
                <button
                  onClick={() => setToastError(false)}
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                ></button>
              </div>
            </Toast>
          </div>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Calendar" breadcrumbItem="Full Calendar" />
          <Row>
            <Col className="col-12">
              <Row>
                <Col lg={3}>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-2">
                        <br />
                        <p className="text-muted">
                          Listed colors respresent the current status
                        </p>
                        {categories &&
                          (categories || []).map((category: any) => (
                            <div
                              className={`${category.type}  fc-event text-white`}
                              key={"cat-" + category.id}
                            >
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>

                      <Row className="justify-content-center mt-5">
                        <img
                          src={verification}
                          alt=""
                          className="img-fluid d-block"
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col className="col-lg-9">
                  {/* fullcalendar control */}
                  <FullCalendar
                    plugins={[
                      BootstrapTheme,
                      dayGridPlugin,
                      listPlugin,
                      interactionPlugin,
                    ]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                    }}
                    events={events}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    eventClick={handleEventClick}
                    eventContent={renderEventContent}
                  />

                  {/* New/Edit event modal */}
                  <Modal
                    isOpen={modalcategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? "Accept Meeting" : "Add Event"}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          categoryValidation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Meeting Title
                              </Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Insert Event Name"
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.title || ""}
                                invalid={
                                  categoryValidation.touched.title &&
                                    categoryValidation.errors.title
                                    ? true
                                    : false
                                }
                                readOnly
                              />
                              {categoryValidation.touched.title &&
                                categoryValidation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="mb-3">
                              <Label className="form-label">Meeting Time</Label>
                              <div className="text-left px-2">
                                <Moment
                                  date={categoryValidation.values.start}
                                  format="Do MMMM YYYY h:mm a"
                                  style={{
                                    textAlign: "center",
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div className="mb-3">
                              <Label className="form-label">Created At</Label>
                              <div className="text-left px-2">
                                <Moment
                                  date={categoryValidation.values.createdOn}
                                  format="Do MMMM YYYY h:mm a"
                                  style={{
                                    textAlign: "center",
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col className="col-6">
                            <button
                              type="button"
                              className="btn btn-light me-1"
                              onClick={toggle}
                            >
                              Close
                            </button>
                          </Col>

                          <Col className="col-6 text-end">
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="btn-save-event"
                            >
                              Accept
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Calender;
