import React from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Popover,
  PopoverHeader,
  Toast,
  ToastBody,
  PopoverBody,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { archiveData, popularPosts, tagsData } from "common/data";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { filter } from "lodash";
import { Language } from "@mui/icons-material";
interface categoriesDataType {
  _id: string;
  title: string;
  language: string;
}

interface archiveDataType {
  id: number;
  year: string;
  badgeCount: string;
}

interface popularPostsType {
  id: number;
  imageSrc: string;
  title: string;
  date: string;
}
interface tagsDataType {
  id: number;
  name: string;
}
const RightBar = () => {
  const [modal, setmodal] = useState<boolean>(false);
  const [modal2, setmodal2] = useState<boolean>(false);
  const [cat, setCat] = useState<categoriesDataType[]>([]);
  const [catNameEn, setCatNameEn] = useState<string>("");
  const [catNameAr, setCatNameAr] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [catNameEnEdit, setCatNameEnEdit] = useState<string>("");
  const [catNameArEdit, setCatNameArEdit] = useState<string>("");
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [popovertop, setpopovertop] = useState<boolean>(false);
  const [popoverdismiss, setpopoverdismiss] = useState<boolean>(false);
  const [toast, setToast] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [itemToDelete, setItemToDelete] = useState<categoriesDataType>(null);
  const [selectLanguage, setSelectLanguage] = useState([]) as any[];
  const [selectEditLanguage, setSelectEditLanguage] = useState({
    value: "",
    label: "",
  });
  const [textDirection, setTextDirection] = useState("ltr");
  const [popoverDismiss, setPopoverDismiss] = useState(false);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10000, // Ensure this is higher than other z-index values in your application
    }),
  };

  const optionlanguage = [
    { value: "en", label: "English" },
    { value: "ar", label: "Arabic" },
    { value: "zul", label: "Zulu" },
    { value: "af", label: "Afrikaans" },
  ];

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     zIndex: 9999,
  //   }),
  // };

  const handleLanguage = (selectedOption: any) => {
    setSelectLanguage(selectedOption);
    setTextDirection(selectedOption.value === "ar" ? "rtl" : "ltr");
    console.log("Selected language:", selectedOption);
  };

  const fetchCat = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.get(
      `${process.env.REACT_APP_APIKEY}/api/fetch/all/categories`,
      { headers }
    );
    if (res.success) {
      setCat(res.data);
    } else {
      console.log("Server Error");
    }
  };

  const handleEdit = (item: categoriesDataType) => {
    setCatNameEnEdit(item.title);
    console.log(item, "4444");
    setEditId(item._id);
    const languageOption = optionlanguage.find(
      (option) => option.value === item.language
    );
    setSelectEditLanguage(languageOption || { value: "", label: "" });
    setmodal2(true);
  };

  const setEdit = async () => {
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    const res = await axios.put(
      `${process.env.REACT_APP_APIKEY}/api/edit/category/${editId}`,
      { title: catNameEnEdit, language: selectEditLanguage.value },
      { headers }
    );
    console.log("inside");

    if (res.success) {
      setCatNameEn(" ");
      let filt = cat;
      filt = filt.filter((data, i) => res.data._id !== data._id);
      filt.push(res.data);
      setCat(filt);
      setmodal2(!modal2);
    }
  };

  const handleDelete = async () => {
    console.log(itemToDelete, "item");
    if (itemToDelete) {
      const headers = {
        Authentication: `Bearer ${user?.token}`,
      };
      const res = await axios.delete(
        `${process.env.REACT_APP_APIKEY}/api/delete/blog/category/${itemToDelete._id}`,
        { headers }
      );
      console.log("inside");
      if (res.success) {
        setItemToDelete(null);
        console.log(res, "res");
        setSuccessMessage(res.message);
        setToast(false);
        const filter = cat.filter((c) => c._id !== itemToDelete._id);
        setCat(filter);
        setPopoverDismiss(false);
        // filterBlogs()
      }
    }
  };

  const addCategory = async () => {
    console.log("here");
    const headers = {
      Authentication: `Bearer ${user?.token}`,
    };
    if (catNameEn != " ") {
      const res = await axios.post(
        `${process.env.REACT_APP_APIKEY}/api/create/category`,
        { title: catNameEn, language: selectLanguage.value },
        { headers }
      );
      console.log("inside");

      if (res.success) {
        setCatNameEn(" ");
        setCat((cat) => [...cat, res.data]);
        setmodal(!modal);
      }
    } else if (catNameEn != " ") {
    }
  };

  useEffect(() => {
    fetchCat();
  }, []);

  return (
    <React.Fragment>
      <Col xl={3} lg={4}>
        <Card>
          <CardBody className="p-4">
            <div className="search-box">
              <p className="text-muted">Search</p>
              <div className="position-relative">
                <Input
                  type="text"
                  className="rounded bg-light border-light"
                  placeholder="Search..."
                />
                <i className="mdi mdi-magnify search-icon"></i>
              </div>
            </div>

            <Toast
              isOpen={toast}
              className="align-items-center text-white bg-success border-0 custom-toast"
              role="alert"

              // style={{ backgroundColor: "green" }}
            >
              <div className="d-flex">
                <ToastBody>{successMessage}</ToastBody>
                <button
                  onClick={() => setToast(false)}
                  type="button"
                  className="btn-close btn-close-white me-2 m-auto"
                ></button>
              </div>
            </Toast>

            <hr className="my-4" />

            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="text-muted">Categories</p>
                <i
                  onClick={() => setmodal(!modal)}
                  className="fas fa-plus icon-hover"
                  style={{ fontSize: 18 }}
                ></i>
              </div>

              <Modal
                isOpen={modal}
                autoFocus={true}
                centered={true}
                toggle={() => {
                  setmodal(!modal);
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      setmodal(!modal);
                    }}
                  >
                    New Category{" "}
                  </ModalHeader>
                  <ModalBody>
                    <form>
                      <label htmlFor="all-language" className="col-form-label">
                        Select Language
                      </label>
                      <div>
                        <Select
                          styles={customStyles}
                          value={selectLanguage}
                          onChange={handleLanguage}
                          id="all-language"
                          options={optionlanguage}
                          className="select2-selection"
                        />
                      </div>
                      <div className="mb-3 mt-4">
                        <Input
                          type="email"
                          placeholder="Category Name"
                          onChange={(e) => setCatNameEn(e.target.value)}
                          value={catNameEn}
                          dir={textDirection}
                        />
                      </div>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setmodal(!modal);
                      }}
                    >
                      Close
                    </Button>
                    <Button type="button" color="primary" onClick={addCategory}>
                      {" "}
                      Save <i className="fas fa-save ms-1"></i>{" "}
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>

              <ul className="list-unstyled fw-medium">
                {(cat || []).map((item: categoriesDataType, index: number) => (
                  <li key={index}>
                    <Link to="#" className="text-muted py-2 d-block ">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <i className={`mdi mdi-chevron-right me-1`}></i>{" "}
                          <span>{item.title}</span>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                          <i
                            className="fas fa-edit icon-hover-edit mx-3"
                            onClick={() => handleEdit(item)}
                          ></i>
                          <i
                            id={`PopoverTop-${item._id}`}
                            onClick={() => {
                              setpopoverdismiss(!popoverdismiss);
                              setItemToDelete(item);
                            }}
                            className="fas fa-trash-alt icon-hover-del"
                          ></i>
                        </div>
                        <Popover
                          placement="bottom"
                          isOpen={
                            popoverDismiss && itemToDelete?._id === item._id
                          }
                          target={`PopoverTop-${item._id}`}
                          toggle={() => {
                            setPopoverDismiss(!popoverDismiss);
                          }}
                        >
                          <PopoverHeader>Are you sure?</PopoverHeader>
                          <PopoverBody>
                            <p>
                              This will delete the category and all the blogs
                              assciated to {itemToDelete?.title} and It'll be
                              permanent and connot be reverted.
                            </p>

                            <button
                              onClick={() => {
                                setPopoverDismiss(!popoverDismiss);
                              }}
                              className="btn btn-secondary"
                            >
                              cancel
                            </button>
                            <button
                              onClick={handleDelete}
                              className="btn btn-danger mx-2"
                            >
                              Delete
                            </button>
                          </PopoverBody>
                        </Popover>
                      </div>

                      {/* {
                          item.badge && (
                            <span className={`badge ${item.badge.color} rounded-pill float-end ms-1 font-size-12`}>
                              {item.badge.text}
                            </span>
                          )
                        } */}
                    </Link>
                  </li>
                ))}
              </ul>

              <Modal
                isOpen={modal2}
                autoFocus={true}
                centered={true}
                toggle={() => {
                  setmodal2(!modal2);
                }}
              >
                <div className="modal-content">
                  <ModalHeader
                    toggle={() => {
                      setmodal2(!modal2);
                    }}
                  >
                    Edit Category{" "}
                  </ModalHeader>
                  <ModalBody>
                    <form>
                      <label htmlFor="edit-language" className="col-form-label">
                        Select Language
                      </label>
                      <Select
                        styles={customStyles}
                        id="edit-language"
                        value={selectEditLanguage}
                        onChange={setSelectEditLanguage}
                        options={optionlanguage}
                      />
                      <div className="mb-3 mt-4">
                        <Input
                          type="email"
                          placeholder="Category Name"
                          onChange={(e) => setCatNameEnEdit(e.target.value)}
                          value={catNameEnEdit}
                        />
                      </div>
                    </form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        setmodal(!modal);
                      }}
                    >
                      Close
                    </Button>
                    <Button type="button" color="primary" onClick={setEdit}>
                      {" "}
                      Save <i className="fas fa-save ms-1"></i>{" "}
                    </Button>
                  </ModalFooter>
                </div>
              </Modal>
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted">Archive</p>

              <ul className="list-unstyled fw-medium">
                {(archiveData || []).map(
                  (item: archiveDataType, index: number) => (
                    <li key={index}>
                      <Link to="#" className="text-muted py-2 d-block">
                        <i className="mdi mdi-chevron-right me-1"></i>{" "}
                        {item.year}{" "}
                        <span className="badge badge-soft-success rounded-pill float-end ms-1 font-size-12">
                          {item.badgeCount}
                        </span>
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted mb-2">Popular Posts</p>

              <div className="list-group list-group-flush">
                {(popularPosts || []).map(
                  (item: popularPostsType, index: number) => (
                    <Link
                      to="#"
                      className="list-group-item text-muted py-3 px-2"
                      key={index}
                    >
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <img
                            src={item.imageSrc}
                            alt=""
                            className="avatar-md h-auto d-block rounded"
                          />
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-13 text-truncate">
                            {item.title}
                          </h5>
                          <p className="mb-0 text-truncate">{item.date}</p>
                        </div>
                      </div>
                    </Link>
                  )
                )}
              </div>
            </div>

            <hr className="my-4" />

            <div>
              <p className="text-muted mb-1">Tags</p>

              <ul className="list-inline widget-tag">
                {(tagsData || []).map((item: tagsDataType) => (
                  <li className="list-inline-item" key={item.id}>
                    <Link to="#" className="badge bg-light font-size-12 mt-2">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightBar;
